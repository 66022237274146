import React from "react";
import { http } from "../settings";
import { Link } from "react-router-dom";
import * as auth from "../store/actions/Auth";
import * as general from "../store/actions/General";
import { connect } from "react-redux";

const Follower = (props) => {
  const follower = props.follower;

  const isFollowing = () => {
    return props.authors_following.find((uf) => {
      return uf === follower.id;
    });
  };
  const followAuthor = () => {
    http
      .post(`/api/auth/follow?follow_id=${follower.id}`)
      .then((res) => {
        const following = isFollowing();
        let my_followings = [...props.authors_following];
        if (res.data.status) {
          if (following) {
            let this_index = my_followings.indexOf(follower.id);
            my_followings.splice(this_index, 1);
            console.log(my_followings);
            props.authorsFollowing(my_followings);
          } else {
            my_followings.push(follower.id);
            props.authorsFollowing(my_followings);
          }
        } else {
          props.setAlert({
            show: true,
            error: true,
            message: res.data.message,
          });
        }
      })
      .catch((err) => {
        console.log(err);
        props.setAlert({
          show: true,
          error: true,
          message: err.response.data.detail,
        });
      });
  };
  return (
    <div className="m-auto max-w-screen-2xl mx-5 lg:mx-auto">
      <div className="mx-2 md:mx-5 py-4">
        <div className="flex flex-col lg:flex-row lg:justify-between lg:items-center border-2 rounded-xl">
          <div
            className="flex flex-col md:flex-row lg:flex-col flex-1 mr-5 mx-2"
            style={{ alignItems: "center" }}
          >
            <a
              className="bg-gray-200 rounded-full h-32 w-32 p-0.5"
              href={`/writer/${follower.id}`}
              style={{ cursor: "pointer" }}
            >
              {follower.avatar && (
                <img
                  src={follower.avatar}
                  alt="Follower"
                  className="rounded-full object-fill h-full w-full"
                />
              )}
            </a>
            <a
              href={`/writer/${follower.id}`}
              style={{ cursor: "pointer" }}
              className="hover:text-blue-600 capitalize font-bold"
            >
              {follower.username}
            </a>
          </div>
          <div className="flex-1 flex px-1 md:px-10 lg:px-0 flex-col mr-5 py-2 md:py-10 lg:py-0">
            <button
              onClick={followAuthor}
              className="bg-info-blue text-white rounded-full px-4"
            >
              {isFollowing() ? "Following" : "Follow"}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6 inline pl-2"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M18 9v3m0 0v3m0-3h3m-3 0h-3m-2-5a4 4 0 11-8 0 4 4 0 018 0zM3 20a6 6 0 0112 0v1H3v-1z"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return { authors_following: state.auth.authors_following };
};

const mapDispatchToProps = (dispatch) => {
  return {
    authorsFollowing: (following) => dispatch(auth.authorsFollowing(following)),
    setAlert: (data) => dispatch(general.setAlert(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Follower);
