import React from 'react';
import whiteimage from '../../../assets/white.jpeg'

const SingleVideo = ({ video }) => {
    const youTubeIdFromLink = (url) => url.match(/(?:https?:\/\/)?(?:www\.|m\.)?youtu(?:be)?\.(?:com|be)(?:\/watch\/?\?v=|\/embed\/|\/)([^\s&\?\/\#]+)/)[1];

    return (
      video ?
            <div className={"my-2 mx-2 md:mx-5  rounded-lg flex flex-col shadow-lg  transition transform ease-in-out delay-150 motion-reduce:transition-none motion-reduce:transform-none"}>
                {/* <img src={video.image?video.image:whiteimage} className={'rounded-md h-48 w-full object-cover hover:bg-blue-700 hover:opacity-30'} alt={""} /> */}
                <iframe
                  className={'rounded-md h-48 w-full object-cover'}
                  src={`https://www.youtube.com/embed/${youTubeIdFromLink(video.link)}`}
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  title="Embedded youtube"
                />
                <div className={"flex py-1 flex-col px-2 pb-4"}>
                    <span  className={"text-black font-semibold text-sm hover:text-blue-600"}>
                        {video.title}
                    </span>
                    <span className={"text-gray-400 text-xs pt-2"}>
                        <span className={"capitalize"}>Quantumgrad</span>
                        <span className='font-bold px-1'>&bull;</span>
                        <span className={"capitalize"}>{ video.date_published ?? new Date(video.created_at).toDateString()}</span>
                    </span>
                </div>
            </div>
            :
            ""
    );
};

export default SingleVideo;
