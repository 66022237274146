import "../../index.css";
import { Link } from "react-router-dom";


const contribute = (props) => {

  return (
    <div className="mx-auto rounded-lg flex flex-col bg-light-blue-grad my-20 w-full">

      <div className="mt-20 text-center">
        <span className="font-bold text-3xl">Contribute to QuantumGrad!</span>
      </div>

      <div className="mt-10 px-5 flex justify-center">
        <span>We are a nonprofit volunteering startup where we are always looking for motivated individuals looking <br/>  to contribute to society, make a more significant impact, and make the world a better place to live.</span>
      </div>

      <div className="my-10 text-center">
        <Link to="/contribute" className="p-4 text-white bg-info-blue rounded-full">Learn More </Link>
      </div>
  </div>
  )
}

export default contribute;
