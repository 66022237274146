import React,{useState} from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import large_logo from "../../assets/logo_main.png"
import { http } from '../../settings'
import SocialMedia from './SocialMedia'
import * as general from "../../store/actions/General";


const Footer = (props) => {
    const [loading, setLoading] = useState(false)
    const [email, setEmail] = useState('')
    const subscribeEmail = (e) => {
        setLoading(true)
        e.preventDefault()
        let data = {
            email: email
        }
        http.post(`/api/mails/add-email/`, data).then(res => {
            props.setAlert({ show: true, error: !res.data.status, message: res.data.message });
            setEmail('')
            setLoading(false);
        })
    }
    return (
        <div className="footer-bg pt-20 pb-4">


            <div className="grid md:grid-cols-5  m-auto max-w-6xl mx-5 lg:mx-auto justify-start items-start pb-10">
                <div className="md:col-span-2 space-y-4">
                    <Link to={"/"}>
                        <img alt="large-logo" className="object-fill h-14 py-0.5" src={large_logo} />
                    </Link>
                    <h6 className="py-0.5 px-2 font-bold">Subscribe to our newsletter</h6>
                    <p className="px-3 py-2">
                        Quantum Grad is a global e-learning and content
                        creation platform solely for Quantum Computing.
                    </p>
                    <form className="space-x-2 flex px-2" onSubmit={subscribeEmail}>
                        <input type="email" className="rounded w-2/3" name="email" value={email} onChange={
                            (e)=>setEmail(e.target.value)
                        } />
                        <button className="bg-info-blue text-white px-4 rounded" disabled={loading}>
                            {loading ? 'submiting...' : 'submit'}
                        </button>
                    </form>

                </div>
                <div className="grid col-span-1 grid-cols-2 md:col-span-3 lg:grid-cols-3 py-4 lg:py-1">
                <div className="col-span-1 flex flex-col ">
                    <h6 className="font-black pb-4">Quick Links</h6>
                    <Link to="/" className="pb-1">Home</Link>
                    <Link to="/about-us" className="pb-1">Who we are</Link>
                    <Link to="/contribute" className="pb-1">Contact Us</Link>
                    <Link to="/terms-and-conditions" className="pb-1">Privacy Terms</Link>
                </div>
                <div className="col-span-1 flex flex-col ">
                    <h6 className="font-black pb-4">Explore</h6>
                    <Link to="/news" className="pb-1">News</Link>
                    <Link to="/publications" className="pb-1">Publications</Link>
                    <Link to="/articles" className="pb-1">Articles</Link>
                    <Link to="/books" className="pb-1">Books</Link>
                    <Link to="/jobs" className="pb-1">Jobs</Link>
                    <Link to="/events" className="pb-1">Events</Link>
                </div>
                <div className="col-span-1 flex flex-col ">
                    <h6 className="font-black pb-4">Reach us</h6>
                    <a href="https://www.facebook.com/Quantumgrad-104466068845774/" target="_blank" rel="noreferrer" className="pb-1">Facebook</a>
                    <a href="https://twitter.com/quantumgrad" target="_blank" rel="noreferrer" className="pb-1">Twitter</a>
                    <a href="https://www.instagram.com/quantumgrad_official/" target="_blank" rel="noreferrer" className="pb-1">Instagram</a>
                    <a href="mailto:svishwak@alumni.cmu.edu" className="pb-1">Email</a>
                </div>
                </div>
            </div>
            <div className="px-10 border-t-2">
                <div className=" max-w-6xl mx-5 lg:mx-auto flex flex-col md:flex-row justify-between items-center">
                    <SocialMedia />
                    <p className="text-center">&copy; Copyright 2022. All rights reserved</p>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = () => {
    return {
    }
};
// userSuccess

const mapDispatchToProps = dispatch => {
    return {
        setAlert: (data) => dispatch(general.setAlert(data))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Footer)
