import React from 'react';
import mediumDraftExporter from "medium-draft/lib/exporter";

import {
  AtomicBlockUtils,
  EditorState
} from 'draft-js'
import {
} from 'medium-draft';
class EmbedSideButton extends React.Component {

  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);
    this.addEmbedURL = this.addEmbedURL.bind(this);
  }

  onClick() {
    const url = window.prompt('Enter a URL', 'https://www.youtube.com/watch?v=PMNFaAUs2mo');
    this.props.close();
    if (!url) {
      return;
    }
    this.addEmbedURL(url);
  }

  addEmbedURL(url) {
    let editorState = this.props.getEditorState();
    const content = editorState.getCurrentContent();
    const contentWithEntity = content.createEntity('embed', 'IMMUTABLE', {url});
    const entityKey = contentWithEntity.getLastCreatedEntityKey();
    editorState = EditorState.push(editorState, contentWithEntity, 'create-entity');
    const new_state = AtomicBlockUtils.insertAtomicBlock(
        editorState,
        entityKey,
        'E'
    )
    console.log(mediumDraftExporter(new_state.getCurrentContent()))
    console.log(new_state)
    this.props.setEditorState(
     new_state
    );
  }

  render() {
    return (
      <button
        className="md-sb-button md-sb-img-button"
        type="button"
        title="Add an Embed"
        onClick={this.onClick}
      >
        <i className="fa fa-video-camera" />
      </button>
    );
  }

}


export default EmbedSideButton;
