import React, { useState } from "react";
import { connect } from "react-redux";
import contribute_image from "../assets/marketplace.png";
import { http } from "../settings";
import * as general from "../store/actions/General";

const Marketplace = (props) => {
  const [loading, setLoading] = useState(false);
  const marketplaceSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    const data = {
      organization_name: e.target.elements.name.value,
      email_address: e.target.elements.email.value,
      title: e.target.elements.title.value,
      category: e.target.elements.category.value,
      introduction: e.target.elements.introduction.value,
      link: e.target.elements.link.value,
    };

        setLoading(false)
        http.post(`/api/marketplace/add-marketplace/`, data).then(res => {
            props.setAlert({ error: !res.data.status, message: ` Thanks for submitting! will publish soon.`, show: true });
            e.target.elements.name.value = "";
            e.target.elements.email.value = "";
            e.target.elements.title.value = "";
            e.target.elements.category.value = "Publications";
            e.target.elements.introduction.value = "";
            e.target.elements.link.value = "";
        }).catch(err => {
            setLoading(false)
            props.setAlert({ error: true, message: "error occured submiting request", show: true });
        })
    }
    return (
        <div className="m-auto max-w-screen-2xl mx-5 lg:mx-auto">
            <div className="flex flex-col py-10">
                <h1 className="text-center font-bold text-5xl">Join our marketplace!</h1>
                <div className="grid md:grid-cols-5">
                    <div className="md:col-span-3 py-10">
                        <div>
                            <div>We are a nonprofit startup where we are trying to create a marketplace for Quantum Enthusiasts to facilitate the exchange of Quantum related events, papers, jobs, books and news between Event organizers / researchers and participants / students / readers. This would not only benefit the content creators, the event organizers, the people who publish news, information, articles on the platform. It will also be beneficial to the audience, the readers who consume content on the platform.
                                <div className="px-10">
                                    <div className="py-10">
                                        Different categories in which you would be able to submit the content is as follows:
                                    </div>
                                    <ol className=" list-inside list-disc">
                                        <li>
                                            <b>Events</b>: If you are the organizer of certain Quantum Computing related Events and wish to advertise your events on a singular platform you can do so by publishing those events on the platform here and gain access to our wider network of quantum computing enthusiasts and other participants.
                                        </li>
                                        <li>
                                            <b>Publications</b>: If you recently published a research paper related to Quantum Computing and wish to reach more people and newer audience who you think would like to know about it and read. Then please select the publication section and submit all your details there.
                                        </li>
                                        <li>
                                            <b>Jobs</b>: If you are actively looking for internship/full time candidates related to Quantum Computing, the job portal is the best place to advertise since this platform has a lot of Quantum Enthusiasts and aspiring job seekers. The demand for such jobs would definitely be huge on the platform and with the rise of Quantum computing as a field in the near future as well.
                                        </li>
                                        <li>
                                            <b>News</b>: Did you or your team want more people to know about your recent Quantum computing related breakthroughs, if yes then please submit all your details here.
                                        </li>
                                        <li>
                                            <b>Books</b>: Are you a first-time author or did you recently wrote a book and want more people to know about it, here is the best place to start sharing this book and get feedback regarding the same.
                                        </li>
                                        <li>
                                            <b>Conferences</b>: Are you Organizing a Quantum relelated conference or want to organize?, here is the best place to advertise since this platform has a lot of Quantum Enthusiasts.
                                        </li>
                                    </ol>
                                </div>
                            </div>
                            <div className="flex items-center justify-center">
                                <img src={contribute_image} />
                            </div>
                        </div>
                    </div>
                    <form onSubmit={marketplaceSubmit} className="border-gray-200 border shadow px-10 py-10 md:col-span-2 
                    justify-between items-center lg:mx-auto w-full mt-10 rounded">
                        <div className="py-4">
                            <label htmlFor="name" className="font-bold py-4">Organization name</label>
                            <input name="name" required id="name" type="text" className='rounded border-gray-300 w-full bg-gray-200' placeholder="" />
                        </div>
                        <div className="py-4">
                            <label htmlFor="email" className="font-bold py-4">Email Address</label>
                            <input name="email" required id="email" type="email" className='rounded border-gray-300 w-full bg-gray-200' placeholder="" />
                        </div>
                        <div className="py-4">
                            <label htmlFor="title" className="font-bold py-4">Title</label>
                            <input name="title" required id="title" type="text" className='rounded border-gray-300 w-full bg-gray-200' placeholder="" />
                        </div>

                        <div className="py-4">
                            <label htmlFor="category" className="font-bold py-4">Category</label>
                            <select name="category" required id="category" className='rounded border-gray-300 w-full bg-gray-200'>
                                <option>Publications</option>
                                <option>Event</option>
                                <option>Jobs</option>
                                <option>News</option>
                                <option>Books</option>
                                <option>Conferences</option>
                            </select>
                        </div>
                        <div className="py-4">
                            <label htmlFor="introduction" className="font-bold py-4">Brief Introduction</label>
                            <textarea maxLength = "500" name="introduction" required id="introduction" rows="4" className='rounded border-gray-300 w-full bg-gray-200'>
                            </textarea>
                        </div>
                        <div className="py-4">
                            <label htmlFor="link" className="font-bold py-4">Link</label>
                            <input id="link" type="url" name="link" required
                                className='rounded border-gray-300 w-full bg-gray-200' placeholder="" />

                        </div>
                        <button disabled={loading}
                            className="px-10 py-1 border-info-blue bg-info-blue text-white rounded-full">
                            {loading ? 'Submitting...' : 'submit'}</button>
                    </form>
                </div>

            </div>
        </div >
    )
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => {
  return {
    setAlert: (data) => dispatch(general.setAlert(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Marketplace);
