import React, { useState } from 'react';
import {
    useNavigate
} from "react-router-dom";
const Search = (props) => {
    let navigate = useNavigate()

    const [searchText, setSearchText] = useState("")
    const searchResults = (e) => {
        e.preventDefault()
        setSearchText("")
        navigate(`/search?search=${searchText}`)
        props.updateSearching()
    }

    return (
        <div className="relative z-10 flex m-auto h-full w-full justify-center items-center bg-white">
            <form onSubmit={searchResults} className={'h-full w-full flex flex-col justify-center m-auto max-w-6xl mx-5 lg:mx-auto '}>
                <input type="text"
                   value={searchText}
                   autoFocus={true}
                   onChange={(e) => setSearchText(e.target.value)}
                   onFocus={function (e) {
                     var val = e.target.value;
                     e.target.value = "";
                     e.target.value = val;
                   }}
                    className={'border-none bg-transparent active:outline-none ' +
                        'p-10 text-5xl font-bold outline-none focus:outline-none focus:shadow-none w-full focus:ring-0'} placeholder={'Search'} />
            </form>
            <button className={'absolute top-0 right-0 p-5'} onClick={props.updateSearching}>
                <svg className={'h-10 w-10 inline '} viewBox="0 0 24 24">
                    <path fill="currentColor" d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" />
                </svg>
            </button>
        </div>
    );
};

export default Search;