import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import SingleJob from './SingleJob'

export const Jobs = ({ jobs }) => {
    const [description, setDescription] = useState(`Looking for a Quantum Computing Full Time Role or Internships? Get the Latest Quantum Computing jobs 
    information here and head start towards your career`)

    return (
        <div className="grid grid-cols-1 lg:grid-cols-6 justify-center lg:space-x-10 items-center ">
            <div className="lg:col-span-2 lg:px-10 col-span-1 ">
                <h2 className="text-4xl lg:text-6xl font-black text-gray-800">Jobs</h2>
                <p className='lg py-10'>{description} </p>
                <Link to={'/jobs'} className="bg-info-blue py-1 px-5 rounded-full text-white hidden md:inline">
                    Browse All
                    <svg xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6 ml-3 inline " fill="none" viewBox="0 0 24 24"
                        stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                            d="M17 8l4 4m0 0l-4 4m4-4H3" />
                    </svg>
                </Link>
            </div>
            <div className="lg:col-span-4 col-span-1">
                <div className="hidden md:grid grid-cols-1 md:grid-cols-3 justify-center items-center md:space-x-66">
                    <div className='flex flex-col'>
                        <SingleJob job={jobs[0]} />
                    </div>
                    <div className='flex flex-col'>
                        <SingleJob job={jobs[1]} />
                        <SingleJob job={jobs[2]} />
                    </div>
                    <div className='flex flex-col'>
                        <SingleJob job={jobs[3]} />
                        <SingleJob job={jobs[4]} />
                        <div className='py-20 hidden lg:flex'></div>
                    </div>
                </div>
                <div className="grid grid-cols-1 sm:grid-cols-2 md:hidden justify-center items-center">
                    {
                        jobs.map(job => <SingleJob job={job} key={job.id} />)
                    }
                </div>
            </div>
            <div className="mb-4 flex md:hidden">
            <Link to={'/jobs'} className="bg-info-blue py-1 px-5 rounded-full text-white  ">
                    Browse All
                    <svg xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6 ml-3 inline " fill="none" viewBox="0 0 24 24"
                        stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                            d="M17 8l4 4m0 0l-4 4m4-4H3" />
                    </svg>
                </Link>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(Jobs)
