import * as actionType from "../actionTypes";
import { updateObject } from "../utility";

const initialState = {
    alert: { show: false, error: false, message: '' }
}

const setAlert = (state, action) => {
    return updateObject(state, {
        alert: action.data
    });
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionType.ALERT_MESSAGE:
            return setAlert(state, action);
        default:
            return state;
    }
};

export default reducer;