import React, { useState } from "react";
import { connect } from "react-redux";
import Authors from "./following/Authors";
import Posts from "./following/Posts";

export const Following = (props) => {
  const [active, setActive] = useState(1);
  const [articles, setArticles] = useState([]);
  return (
    <div className="m-auto max-w-screen-2xl mx-5 lg:mx-auto">
      <div className="border-b border-gray pt-10 space-x-20 mx-8 ">
        <button
          onClick={() => setActive(1)}
          className={`${
            active === 1 ? "border-b-2 border-indigo-600 font-bold" : ""
          }`}
        >
          Posts
        </button>
        <button
          onClick={() => setActive(2)}
          className={`${
            active === 2 ? "border-b-2 border-indigo-600 font-bold" : ""
          }`}
        >
          Authors
        </button>
      </div>
      {active === 1 ? <Posts /> : <Authors />}
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Following);
