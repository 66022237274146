import React, {useState} from 'react';
import {http} from "../../settings";
import {Link} from "react-router-dom";

const ForgotPassword = props => {
    const [error, setError] = useState()
    const [success, setSeccess] = useState()
    const [loading, setLoading] = useState(false)
    const handleReset=(e)=>{
        setSeccess("")
        setError("")
        setLoading(true)
        e.preventDefault()
        let email =e.target.elements.email.value
        http.get(`/api/auth/forgot-password?email=${email}`).then(res=>{
            setLoading(false)
            setSeccess(res.data.Message)
        }).catch(err => {
            setLoading(false)
            setError(err.response.data.detail)
        })
    }
    return (
        <>
            <Link to={"/auth/login"} className={"absolute top-5 right-5"}>
                <svg className={"h-6 w-6"} viewBox="0 0 24 24">
                    <path fill="currentColor" d="M20 6.91L17.09 4L12 9.09L6.91 4L4 6.91L9.09 12L4 17.09L6.91 20L12 14.91L17.09 20L20 17.09L14.91 12L20 6.91Z" />
                </svg>
            </Link>
            <div className={'px-2 pt-16 lg:pt-0 place-content-center  w-full  lg:w-1/2'}>

                <div className={'place-content-center'}>
                    <h6 className="text-center font-semibold">Forgot password?</h6>
                    <div className={'flex justify-between items-center'}>
                        <div className="md:px-24 sm:max-w-2xl w-full my-5 m-auto">
                            <form action={"POST"} onSubmit={handleReset}>
                                <div className="flex flex-col justify-between items-center space-y-5">
                                    <div className="flex flex-col justify-between items-center py-2 pb-5 w-full">
                                        {error?
                                            <span className="text-red-600 bg-red-200 py-2 px-5 rounded-sm w-full">{error}</span>
                                            :""
                                        }
                                    </div>
                                    <div className="flex flex-col justify-between items-center py-2 pb-5 w-full">
                                        {success?
                                            <span className="text-green-600 bg-green-200 py-2 px-5 rounded-sm w-full">{success}</span>
                                            :""
                                        }
                                    </div>
                                    <input type="email" name={"email"} className="px-8 rounded-3xl border-gray-300 bg-white w-full"
                                           placeholder="Recovery Email" required={true}/>

                                </div>
                                <div className="py-8 flex flex-col justify-center space-y-2">
                                <button disabled={loading} className={'text-center bg-info-blue rounded-3xl text-white py-2'}>
                                {
                                    loading ?
                                        <svg className={"animate-spin inline text-white h-5 w-5 mr-3"} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                                             xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                             width="26.349px" height="26.35px" viewBox="0 0 26.349 26.35"
                                             space="preserve" fill={"white"}>
                                            <g>
                                                <g>
                                                    <circle cx="13.792" cy="3.082" r="3.082"/>
                                                    <circle cx="13.792" cy="24.501" r="1.849"/>
                                                    <circle cx="6.219" cy="6.218" r="2.774"/>
                                                    <circle cx="21.365" cy="21.363" r="1.541"/>
                                                    <circle cx="3.082" cy="13.792" r="2.465"/>
                                                    <circle cx="24.501" cy="13.791" r="1.232"/>
                                                    <path d="M4.694,19.84c-0.843,0.843-0.843,2.207,0,3.05c0.842,0.843,2.208,0.843,3.05,0c0.843-0.843,0.843-2.207,0-3.05
			C6.902,18.996,5.537,18.988,4.694,19.84z"/>
                                                    <circle cx="21.364" cy="6.218" r="0.924"/>
                                                </g>
                                            </g>
                                            <g>
                                            </g>
                                            <g>
                                            </g>
                                            <g>
                                            </g>
                                            <g>
                                            </g>
                                            <g>
                                            </g>
                                            <g>
                                            </g>
                                            <g>
                                            </g>
                                            <g>
                                            </g>
                                            <g>
                                            </g>
                                            <g>
                                            </g>
                                            <g>
                                            </g>
                                            <g>
                                            </g>
                                            <g>
                                            </g>
                                            <g>
                                            </g>
                                            <g>
                                            </g>
                                        </svg> :
                                        ""
                                }
                                {
                                    loading ?
                                        <span className={"animate-pulse"}>loading...</span>
                                        :
                                        "Submit"
                                }
                            </button>
                                </div>

                            </form>

                        </div>
                    </div>

                </div>
            </div>
        </>

    );
};

export default ForgotPassword;