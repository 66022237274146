import React,{useEffect} from 'react';
import { connect } from 'react-redux';
import * as general from "../../store/actions/General";

const MessageAlert = ({ alert, setAlert }) => {
    useEffect(() => {
        if (alert.show) {
            setTimeout(() => {
                setAlert({ show: false, error: false, message: '' })
            }, 3000)
        }
        
    },[alert])
    return (
        <div className={`fixed inset-x-0 bottom-0 mx-5 my-10 z-1000 w-80 lg:w-96 ${alert.show ? '' : 'hidden'}`}>
            <div className={`${alert.error ? '' : 'hidden'} 
        bg-red-300  rounded-md opacity-80 relative
        text-red-700 flex justify-between items-center`}>
                <div className="px-2 py-2 border-r mr-1 border-red-500">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 " viewBox="0 0 20 20" fill="currentColor">
                        <path fillRule="evenodd" d="M10 1.944A11.954 11.954 0 012.166 5C2.056 5.649 2 6.319 2 7c0 5.225 3.34 9.67 8 11.317C14.66 16.67 18 12.225 18 7c0-.682-.057-1.35-.166-2.001A11.954 11.954 0 0110 1.944zM11 14a1 1 0 11-2 0 1 1 0 012 0zm0-7a1 1 0 10-2 0v3a1 1 0 102 0V7z" clipRule="evenodd" />
                    </svg>
                </div>

                <p className="flex-grow">{alert.message}</p>
                <button className="absolute right-0 top-0 p-1"
                    onClick={() => setAlert({ show: false, error: false, message: '' })}>
                    &#10005;
                </button>
            </div>
            <div className={`${!alert.error ? '' : 'hidden'} 
        bg-green-300 rounded-md opacity-80 relative text-green-700
        flex justify-between items-center`}>
                <div className="px-2 py-2 border-r mr-1 border-green-500">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                        <path fillRule="evenodd" d="M2.166 4.999A11.954 11.954 0 0010 1.944 11.954 11.954 0 0017.834 5c.11.65.166 1.32.166 2.001 0 5.225-3.34 9.67-8 11.317C5.34 16.67 2 12.225 2 7c0-.682.057-1.35.166-2.001zm11.541 3.708a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                    </svg>
                </div>

                <p className="flex-grow">{alert.message}</p>
                <button className="absolute right-0 top-0 p-1"
                    onClick={() => setAlert({ show: false, error: false, message: '' })}>
                    &#10005;
                </button>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        alert: state.general.alert,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        setAlert: (data) => dispatch(general.setAlert(data))
    }
};


export default connect(mapStateToProps, mapDispatchToProps)(MessageAlert);
