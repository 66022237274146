import React, { useLayoutEffect } from 'react'

const CustomRouter = (props) => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
    const unlisten = props.history.listen(() => {
      window.scrollTo(0, 0);
    });
    return () => {
      unlisten();
    }
  });

  return (
     <>{props.children}</>
  );
};

export default CustomRouter