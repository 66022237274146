import * as actionTypes from "../actionTypes";


export const setAlert = (data) => {
    return {
        type: actionTypes.ALERT_MESSAGE,
        data: data,
    };
};


