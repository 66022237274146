import React from 'react';

const JobList = ({job}) => {
    
    function getParsedDate(dateObject) {
        return new Date(dateObject).toDateString();
    }
    
    function isValidDate(dateObject) {
        const parsedDate = getParsedDate(dateObject);
        if (parsedDate !== 'Invalid Date') {
            return true;
        }
        return false;
    }
    
    return (<a href={job.link} target="_blank" rel="noreferrer" className={"flex flex-col transition transform ease-in-out" +
        "delay-150 motion-reduce:transition-none motion-reduce:transform-none mx-4 my-8"
    }>
        <img src={job.image} className={'rounded-md h-48 w-full object-cover hover:bg-blue-700 hover:opacity-30'} alt={""} />
        <div className={"flex py-1 flex-col px-2 pb-4 pt-2"}>
            <span className={"text-gray-400 text-xs "}>
                <span className='hover:text-blue-600 uppercase'>{job.city}</span>
                <span className='font-bold px-1'>&bull;</span>
                { isValidDate(job.date_posted) &&
                    (
                        <span>
                            <span>{ getParsedDate(job.date_posted) }</span>
                            <span className='font-bold px-1'>&bull;</span>
                        </span>
                    )
                }
                <span>{job.category && job.category.split("_").join(" ")}</span>
            </span>
            <span target="_blank" rel="noreferrer" className={"text-black font-semibold text-sm hover:text-blue-600 pt-2"}>
                {job.title}
            </span>
            <p className={"text-xs line-clamp-3 pt-2"}>
                {job.short_description}
            </p>
        </div>
    </a>);
};

export default JobList;

