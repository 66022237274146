import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './index.css';
import {applyMiddleware, combineReducers, compose, createStore} from 'redux'
import {Provider} from 'react-redux'
import thunk from 'redux-thunk';
import authReducer from "./store/reducers/Auth";
import generalReducer from "./store/reducers/General";


const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

function configureStore() {
    const rootReducer = combineReducers({
        auth: authReducer,
        general: generalReducer,
    });

    return createStore(
        rootReducer,
        composeEnhancers(applyMiddleware(thunk))
    );
}

const store = configureStore();

const app =

    (<Provider store={store}>
            <App/>
    </Provider>)


ReactDOM.render(app, document.getElementById('root'));