import React,{useState} from 'react';
import { connect } from 'react-redux';
import { http } from '../../settings'
import * as auth from "../../store/actions/Auth";
import * as general from "../../store/actions/General";


const EditProfile = (props) => {
    const [loading, setLoading] = useState(false);
    const updateProfile = (e) => {
        e.preventDefault();
        setLoading(true)
        let data ={
            "first_name": e.target.elements.first_name.value,
            "last_name": e.target.elements.last_name.value,
            "bio": e.target.elements.bio.value,
            'username': props.user.username,
            email:props.user.email
        }
        http.post(`/api/authedit/profile`,data).then(res => {
            let user = res.data.data;
            props.setAlert({ show: true, error: false, message: 'user profile updated' })
            props.userSuccess(user)
            setLoading(false)
        }).catch(err => {
            console.log(err)
            setLoading(true)
        })

    }
    return (
        <div
            className="fixed  inset-0 z-1000 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full"
            id="my-modal"
        >
            <form onSubmit={updateProfile} className="relative top-60 z-10 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
                <button className={'absolute top-0 right-0 p-5'} onClick={(e) => { e.preventDefault(); props.setEditOpne(false) }}>
                    <svg className={'h-6 w-6 inline '} viewBox="0 0 24 24">
                        <path fill="currentColor" d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" />
                    </svg>
                </button>
                <div className="p-5 flex flex-col">
                    <h5 className="font-bold text-lg text-center">Edit Details</h5>
                    <div className="w-full py-2">
                        <label htmlFor="firstName" className="mb-2">First Name</label>
                        <input id="firstName" name="first_name"
                            defaultValue={props.user.first_name}
                            className="w-full border border-gray-300 rounded px-2  py-1.5 bg-gray-200" />
                    </div>
                    <div className="w-full py-2">
                        <label htmlFor="lastname">Last Name</label>
                        <input id="lastname" name="last_name" defaultValue={props.user.last_name}
                            className="w-full border border-gray-300 rounded px-2 py-1.5 bg-gray-200" />
                    </div>
                    <div className="w-full py-2">
                        <label htmlFor="bio">Bio</label>
                        <textarea name="bio" defaultValue={props.user.bio} className="w-full border border-gray-300 rounded px-2 py-1.5 bg-gray-200" rows="3"></textarea>
                    </div>
                </div>
                <div className="flex justify-between items-center -auto max-w-screen-2xl mx-5">
                    <div></div>
                    <button disabled={loading} className={`rounded-full px-10 border-info-blue border text-white bg-info-blue
                     ${loading ? 'bg-gray-300' : ''}`}>{loading ? 'updating' : 'save'}</button>
                </div>
            </form>
        </div>);
};

const mapStateToProps = (state) => {
    return {
        token: state.auth.token,
        user: state.auth.user
    }
};
// userSuccess

const mapDispatchToProps = dispatch => {
    return {
        userSuccess: (data) => dispatch(auth.userSuccess(data)),
        setAlert: (data) => dispatch(general.setAlert(data))
    }
};


export default connect(mapStateToProps, mapDispatchToProps)(EditProfile);
