import "../../index.css";
import chat from "../../assets/chat.svg";
import bulb from "../../assets/bulb.svg";
import discord from "../../assets/discord.svg";
import networkconnect from "../../assets/networkconnect.svg";

const JoinCommunity = () => {
  return (
      <div className="md:flex mt-10 w-full">
        <div className="women-power-gradient px-20 pt-20">

          <div className="space-y-5 mt-20">
            <div className="mt-20 flex">
              <div className="">
                <div className="flex">
                  <img width="80" height="80" src={networkconnect}></img>
                </div>
                <div className="text-black-blue-tint -mt-10">
                  <span className="text-sm">Network and connect with Mentors! </span>
                </div>
              </div>
              <div className="">
                <img width="80" height="80" src={discord}></img>
                <div className="text-black-blue-tint -mt-10">
                  <span className="text-sm">Ask questions you have on our Community Discord! </span>
                </div>
              </div>

            </div>

            <div className="flex">
              <div className="">
                <div className="flex">
                  <img width="80" height="80" src={chat}></img>
                </div>
                <div className="text-black-blue-tint -mt-10">
                  <span className="text-sm">Get Quantum industry guidance! </span>
                </div>
              </div>
              <div className="">
                <img width="80" height="80" src={bulb}></img>
                <div className="text-black-blue-tint -mt-10">
                  <span className="text-sm">Discuss and solve problems together!</span>
                </div>
              </div>

            </div>
          </div>
        </div>
        <div className="w-full px-4  mt-5 flex md:my-auto">
          <div className="mx-auto">
            <div className="md:text-left text-center">
              <span className="text-5xl text-black-blue-tint">Join Our QuantumGrad <br/> Community</span>
            </div>
            <div className="mt-5">
              <span className="font-weight-400">We welcome every level of quantum learners to experts to learn <br/> and share knowledge to make quantum computing resources <br/> accessible to everyone. </span>
            </div>

            <div className="flex mt-5">
              <button
              className="JoinCommunity">
                Join Community
              </button>

              <button className="text-info-blue  my-auto ml-5"> QuantumGrad Discord</button>

              <button className="my-auto ml-5 text-info-blue cursor pointer">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3" />
                </svg>
              </button>


              <span></span>
            </div>
          </div>
        </div>
      </div>
      )
}

export default JoinCommunity;
