import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import whiteimage from '../../assets/white.jpeg'
import { Menu, Transition } from '@headlessui/react';


const ArticleList = (props) => {

    const { article } = props
    const [dropdown, setShoDrop] = useState(false);
    const isInSaved = () => {
        return props.articles_saved.find(sa => sa.article_id === article.id)
    }

    return (
        <div className={"col-span-1 flex flex-col mx-4 my-8"
        }>
            <Link to={`/article/${article.id}`}>
                <img src={article.image ? article.image : whiteimage} className={'rounded-md h-48 w-full object-cover hover:bg-blue-700 hover:opacity-30'} alt={""} />
            </Link>
            <div className={"flex py-1 flex-col px-2 pb-4 pt-2 relative"}>
                <span className={"text-gray-400 text-xs flex justify-between  items-center"}>
                    <div>
                        <Link to={`/writer/${article.created_by ? article.created_by.id : ""}`}
                            className='hover:text-blue-600 capitalize'
                        >{article.created_by.first_name ? article.created_by.first_name : article.created_by.username} {" "}
                            {article.created_by ? article.created_by.last_name : ""}
                        </Link>

                        <span className='font-bold px-1'>&bull;</span>
                        <span>{new Date(article.created_at).toDateString()}</span>
                        <span className='font-bold px-1'>&bull;</span>
                        <span>{article.read_time}</span>
                    </div>
                    <Menu as='div' className='relative'>
                        {({ open }) =>
                            <>
                                <Menu.Button className='font-bold text-info-blue' onClick={() => setShoDrop(!dropdown)}>
                                    <svg className="h-6 w-6 inline" viewBox="0 0 24 24">
                                        <path fill="currentColor" d="M16,12A2,2 0 0,1 18,10A2,2 0 0,1 20,12A2,2 0 0,1 18,14A2,2 0 0,1 16,12M10,12A2,2 0 0,1 12,10A2,2 0 0,1 14,12A2,2 0 0,1 12,14A2,2 0 0,1 10,12M4,12A2,2 0 0,1 6,10A2,2 0 0,1 8,12A2,2 0 0,1 6,14A2,2 0 0,1 4,12Z">
                                        </path>
                                    </svg>
                                </Menu.Button>
                                <Transition show={open}
                                            enter="transform transition duration-100 ease-in"
                                            enterFrom="opacity-0 scale-95"
                                            enterTo="opacity-0 scale-100"
                                            leave="transform transition duration-75 ease-out"
                                            leaveFrom="opacity-100 scale-100"
                                            leaveTo="opacity-0 scale-95"
                                >
                                    <Menu.Items static className={`absolute top-6 right-0 py-1 px-5 bg-gray-300 w-50 rounded-b rounded-r w-48 object-right`}>
                                    <button className='underline text-sm text-black'
                                        onClick={() => {
                                            if (isInSaved()) {
                                                props.setRemoveArticle(article.id);
                                                props.popupRemoveFromSaved(true)
                                            } else {
                                                props.saveArticle(article.id)
                                            }

                                        }}>
                                        {isInSaved() ? 'Remove from' : 'Add to'}  Saved
                                    </button>
                                </Menu.Items>
                                </Transition>
                                
                            </>
                        }
                    </Menu>
                    
                </span>
                

                <Link to={`/article/${article.id}`} className={"text-black font-semibold text-sm hover:text-blue-600 pt-2"}>
                    {article.title}
                </Link>
                <p className={"text-xs line-clamp-3 pt-2"}>
                    {article.short_intro}
                </p>
            </div>

        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        articles_saved: state.auth.articles_saved
    }
};
const mapDispatchToProps = dispatch => {
    return {
    }
};


export default connect(mapStateToProps, mapDispatchToProps)(ArticleList);