import { ImageSideButton, Block, addNewBlock } from "medium-draft";
import { http } from "../../settings";

class CustomImageSideButton extends ImageSideButton {
  /*
  We will only check for first file and also whether
  it is an image or not.
  */
  onChange(e) {
    const file = e.target.files[0];
    console.log(this.props.getEditorState());
    if (file.type.indexOf("image/") === 0) {
      // This is a post request to server endpoint with image as `image`
      const formData = new FormData();
      formData.append("fileobject", file);
      let config = {
        method: "post",
        url: "/api/file/upload?file_type=articles",
        data: formData,
      };
      http(config)
        .then((res) => {
          let data = res.data;
          console.log(data.image_url);
          if (data.image_url) {
            this.props.setEditorState(
              addNewBlock(this.props.getEditorState(), Block.IMAGE, {
                src: data.image_url,
              })
            );
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
    this.props.close();
  }
}
export default CustomImageSideButton;
