import React, { useEffect, useState } from "react";
import { useLocation} from "react-router-dom";
import * as queryString from "querystring";
import { http } from "../settings";
import ArticleList from "./sub/ArticleList";
import RemoveFromSaved from "./sub/article/RemoveFromSaved";
import * as auth from "../store/actions/Auth";
import * as general from "../store/actions/General";
import { connect } from "react-redux";
import NewsList from "./sub/NewsList";
import PublicationList from "./sub/PublicationList";
import EventList from "./sub/EventList";
import JobList from "./sub/JobList";
import BookList from "./sub/BookList";

const SearchResults = (props) => {
  const [pop_remove_saved, popupRemoveFromSaved] = useState(false);
  const [active, setActive] = useState(1);
  const [search_string, setSearchString] = useState("");
  const [articles, setArticle] = useState([]);
  const [books, setBooks] = useState([]);
  const [events, setEvents] = useState([]);
  const [jobs, setJobs] = useState([]);
  const [news, setNews] = useState([]);
  const [publications, setPublications] = useState([]);
  const [remove_article, setRemoveArticle] = useState(null);

  let { search } = useLocation();
  let params = queryString.parse(search);
  const fetchData = (q) => {
    http.get(`/api/homepage/search/${q}`).then((res) => {
      setBooks(res.data.data.books);
      setArticle(res.data.data.articles);
      setNews(res.data.data.news);
      setPublications(res.data.data.publications);
      setEvents(res.data.data.events);
      setJobs(res.data.data.jobs);
    });
  };

  const isLiked = () => {
    return props.articles_liked.find((sa) => sa.article_id === remove_article);
  };

  const saveArticle = (id) => {
    setRemoveArticle(id);
    popupRemoveFromSaved(false);
    http.post(`/api/articles/saved?article_id=${id}`).then((res) => {
      let liked_a = [...props.articles_saved];
      console.log(res.data);
      if (res.data.message.indexOf("removed") > 0) {
        let c_article = isLiked();
        let ca_ind = liked_a.indexOf(c_article);
        console.log(liked_a);
        liked_a.splice(ca_ind, 1);
        console.log(liked_a);
        props.updateSavedArticles(liked_a);
        props.setAlert({ show: true, error: false, message: res.data.message });
      } else {
        liked_a.push({ article_id: parseInt(id) });
        props.updateSavedArticles(liked_a);
        props.setAlert({ show: true, error: false, message: res.data.message });
      }
    });
  };

  useEffect(() => {
    let search = params["?search"];
    setSearchString(search);
    fetchData(search);
  }, []);

  return (
    <div className="m-auto max-w-screen-2xl mx-5 lg:mx-auto py-10">
      <h2 className="px-7 font-bold text-xl">
        Search Results displayed for
        <span className="text-orange-400"> {search_string}</span>
      </h2>
      <div className="border-b border-gray pt-10 space-x-5 lg:space-x-20 mx-8 ">
        <button
          onClick={() => setActive(1)}
          className={`${
            active === 1 ? "border-b-2 border-indigo-600 font-bold" : ""
          }`}
        >
          Articles
        </button>
        <button
          onClick={() => setActive(2)}
          className={`${
            active === 2 ? "border-b-2 border-indigo-600 font-bold" : ""
          }`}
        >
          News
        </button>
        <button
          onClick={() => setActive(3)}
          className={`${
            active === 3 ? "border-b-2 border-indigo-600 font-bold" : ""
          }`}
        >
          Publications
        </button>
        <button
          onClick={() => setActive(4)}
          className={`${
            active === 4 ? "border-b-2 border-indigo-600 font-bold" : ""
          }`}
        >
          Events
        </button>
        <button
          onClick={() => setActive(5)}
          className={`${
            active === 5 ? "border-b-2 border-indigo-600 font-bold" : ""
          }`}
        >
          Jobs
        </button>
        <button
          onClick={() => setActive(6)}
          className={`${
            active === 6 ? "border-b-2 border-indigo-600 font-bold" : ""
          }`}
        >
          Books
        </button>
      </div>
      {pop_remove_saved ? (
        <RemoveFromSaved
          remove_article={remove_article}
          popupRemoveFromSaved={popupRemoveFromSaved}
          saveArticle={saveArticle}
        />
      ) : (
        ""
      )}

      <div className="m-auto max-w-screen-2xl mx-5 lg:mx-auto ">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 xl:grid-cols-4 mx-5 py-10">
          {active === 1
            ? articles.length
              ? articles.map((article) => (
                  <ArticleList
                    saveArticle={saveArticle}
                    key={article.id}
                    article={article}
                    popupRemoveFromSaved={popupRemoveFromSaved}
                    setRemoveArticle={setRemoveArticle}
                  />
                ))
              : "No articles found"
            : ""}
          {active === 2
            ? news.length
              ? news.map((ne) => <NewsList news={ne} key={ne.id} />)
              : "No news found"
            : ""}
          {active === 3
            ? publications.length
              ? publications.map((publication) => (
                  <PublicationList
                    publication={publication}
                    key={publication.id}
                  />
                ))
              : "No publications found"
            : ""}
          {active === 4
            ? events.length
              ? events.map((event) => (
                  <EventList event={event} key={event.id} />
                ))
              : "No events found"
            : ""}
          {active === 5
            ? jobs.length
              ? jobs.map((job) => <JobList job={job} key={job.id} />)
              : "No jobs found"
            : ""}
          {active === 6
            ? books.length
              ? books.map((book) => <BookList key={book.id} book={book} />)
              : "No books found"
            : ""}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    articles_saved: state.auth.articles_saved,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateSavedArticles: (liked_articles) =>
      dispatch(auth.articlesSaved(liked_articles)),
    setAlert: (data) => dispatch(general.setAlert(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchResults);
