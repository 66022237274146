import * as actionType from "../actionTypes";
import {updateObject} from "../utility";

const initialState = {
    token: null,
    IsAuth: false,
    user: {},
    loading_user: false,
    error: null,
    loading: false,
    register_message: null,
    login_error: null,
    signup_error: null,
    login_message: null,
    login_loading: false,
    auth_loading: true,
    register_loading: false,
    logout_pop: false,
    articles_liked:[],
    articles_saved:[],
    authors_following: [],
    notifications: 0,
    email_subscribed:false
}

const authStart = (state, action) => {
    return updateObject(state, {
        login_error: null,
        login_loading: true,
        // auth_loading:true
    });
};

const startLogout = (state, action) => {
    console.log(action)
    return updateObject(state, {
        logout_pop: action.logout_pop,
        auth_loading:false,
    });
}

const authSuccess = (state, action) => {
    return updateObject(state, {
        token: action.token,
        error: null,
        login_loading: false,
        auth_loading:false
    });
};

const authFail = (state, action) => {
    return updateObject(state, {
        login_error: action.error,
        login_loading: false,
        auth_loading:false
    });
};

const signupStart = (state, action) => {
    return updateObject(state, {
        error: null,
        register_message: "",
        signup_error: "",
        register_loading: true
    });
};


const signupSuccess = (state, action) => {
    return updateObject(state, {
        error: null,
        register_message: action.message,
        register_loading: false
    });
};

const signupFail = (state, action) => {
    return updateObject(state, {
        signup_error: action.error,
        message: action.message,
        register_loading: false,
        auth_loading:false
    });
};


const authLogout = (state, action) => {
    console.log(action)
    return updateObject(state, {
        token: null,
        logout_pop:false,
        user: {},
        auth_loading:false
    });
};

const userStart = (state, action) => {
    return updateObject(state, {
        user_loading: true,
        user: {},
        // auth_loading:true
    });
}
const userSsuccess = (state, action) => {
    return updateObject(state, {
        user_loading: false,
        user: action.user,
        auth_loading:false
    });
}
const setArticlesLiked = (state, action) => {
    return updateObject(state, {
        articles_liked: action.articles_liked
    });
}
const setArticlesSaved = (state, action) => {
    return updateObject(state, {
        articles_saved: action.articles_saved
    });
}
const authorsFollowing = (state, action) => {
    return updateObject(state, {
        authors_following: action.authors_following,
    });
}
const setNotifications = (state, actions) =>{
    return updateObject(state, {
        notifications: actions.notifications
    })
}
const setEmailSub = (state, actions) =>{
    return updateObject(state, {
        email_subscribed: actions.email_subscribed
    })
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionType.AUTH_START:
            return authStart(state, action);
        case actionType.AUTH_SUCCESS:
            return authSuccess(state, action);
        case actionType.AUTH_FAIL:
            return authFail(state, action);
        case actionType.SIGNUP_START:
            return signupStart(state, action);
        case actionType.SIGNUP_SUCCESS:
            return signupSuccess(state, action);
        case actionType.SIGNUP_FAIL:
            return signupFail(state, action);
        case actionType.AUTH_LOGOUT:
            return authLogout(state, action);
        case actionType.USER_START:
            return userStart(state, action);
        case actionType.USER_SUCCESS:
            return userSsuccess(state, action);
        case actionType.LOGOUT_SET:
            return startLogout(state, action);
        case actionType.ARTICLES_LIKED_SUCCESS:
            return setArticlesLiked(state, action);
        case actionType.ARTICLES_SAVED_SUCCESS:
            return setArticlesSaved(state, action);
        case actionType.AUTHORS_FOLLOWING_SUCCESS:
            return authorsFollowing(state, action);
        case actionType.NOTIFICATIONS_SUCCESS:
            return setNotifications(state, action);
        case actionType.UPDATE_EMAIL_SUBSCRIBED:
            return setEmailSub(state, action);
        default:
            return state;
    }
};

export default reducer;