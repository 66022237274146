import React, { useState } from 'react';
import { connect } from 'react-redux';
import { http } from '../../settings';
import * as general from "../../store/actions/General";
import * as auth from "../../store/actions/Auth";

export const ChangePassword = (props) => {
    const [error, setError] = useState()
    const onSubmit = (e) => {
        e.preventDefault();
        let data = {
            current_password: e.target.elements.current_password.value,
            newPassword1: e.target.elements.newPassword1.value,
            newPassword2: e.target.elements.newPassword2.value,
        }
        http.post(`api/auth/change-password`, data).then(res => {
            props.setAlert({ show: true, error: !res.data.status, message: res.data.message });
            props.processLogout(true)
        }).catch(err => {
            console.log(err.response.data.detail)
            setError(err.response.data.detail.message)
        })
    }
    return (
        <div
            className="fixed  inset-0 z-1000 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full"
            id="my-modal"
        >

            <form onSubmit={onSubmit} className="relative top-60 z-10 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
                <button className={'absolute top-0 right-0 p-5'} onClick={(e) => { e.preventDefault(); props.setChangePssOpen(false) }}>
                    <svg className={'h-6 w-6 inline '} viewBox="0 0 24 24">
                        <path fill="currentColor" d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" />
                    </svg>
                </button>
                <div className="p-10 flex flex-col">
                    {error ?
                        <div className="flex flex-col justify-between items-center py-2 pb-5 w-full">

                            <span
                                className="text-red-600 bg-red-200 py-2 px-5 rounded-sm w-full">{error}</span>

                        </div>
                        : ""
                    }
                    <h5 className="font-bold text-lg text-center pb-2">Change password</h5>
                    <div className="w-full">
                        <label htmlFor="new_password1" className="mb-2">Current password</label>
                        <input required id="new_password1" name="current_password" type="password"
                            className="w-full border border-gray-300 rounded px-2  py-1.5 bg-gray-200" />
                    </div>
                    <div className="w-full">
                        <label htmlFor="new_password1" className="mb-2">New password</label>
                        <input required id="new_password1" type="password" name="newPassword1" className="w-full border border-gray-300 rounded px-2  py-1.5 bg-gray-200" />
                    </div>
                    <div className="w-full">
                        <label htmlFor="new_password2">Confirm Password</label>
                        <input required id="new_password2" type="password" name="newPassword2" className="w-full border border-gray-300 rounded px-2 py-1.5 bg-gray-200" />
                    </div>

                </div>
                <div className="flex justify-between items-center -auto max-w-screen-2xl mx-5">
                    <div></div>
                    <button className="rounded-full px-10 border-info-blue border text-white bg-info-blue">
                        save
                    </button>
                </div>
            </form>
        </div>);
};

const mapStateToProps = (state) => {
    return {
    }
};
// userSuccess

const mapDispatchToProps = dispatch => {
    return {
        setAlert: (data) => dispatch(general.setAlert(data)),
        processLogout: (proceed) => dispatch(auth.processLogout(proceed))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
