import React, { useEffect, useState } from 'react';
import avatar from "../../assets/avatar.jpg"
import { http } from '../../settings';
import Unfollow from './Unfollow';
import { connect } from 'react-redux';




const Authors = () => {
    const [show_unfollow, setUnfollow] = useState(false);
    const [unfollow_id, setUnfollowId] = useState(false);
    const [followers, setFollowers] = useState([])
    useEffect(() => {
        http.get(`/api/auth/followers`).then(res => {
            setFollowers(res.data.data.following)
        })
    }, [])
    const unFollow = () => {
        http.post(`/api/auth/follow?follow_id=${unfollow_id}`).then(res => {
            setUnfollow(false)
            console.log(res)
        })
    }
    return (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 mx-5 pb-5 pt-10 px-5">
            {show_unfollow ? <Unfollow unFollow={unFollow} setUnfollow={setUnfollow} /> : ""}
            {
                followers.length?
                followers.map((author) =>
                    <div key={author.id} className="flex  items-center pb-10">
                        <div className="bg-gray-200 rounded-full mr-5 h-32 w-32 p-0.5">
                            <img src={author.avatar ? author.avatar : avatar}
                                alt={author.username}
                                className='rounded-full object-fill h-full w-full' />
                        </div>
                        <div className="">
                            <h6 className="font-semibold text-lg">
                                {author.first_name} {author.last_name}
                            </h6>
                            <p>{author.email}</p>
                            <p className="py-3 text-sm">{author.posts} POSTS</p>
                            <button className="border-info-blue rounded-full text-sm border px-4"
                                onClick={() => {
                                    setUnfollowId(author.id)
                                    setUnfollow(true)
                                }}>Following</button>
                        </div>
                    </div>
                    ) :
                    <p
                    className={'lg:col-span-3 col-span-2 md:col-spa-3 xl:col-span-4 text-center'}>
                    <b>You have seen  all</b>
                </p>
            }


        </div>
    );
};


const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Authors);
