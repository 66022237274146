import React from 'react';
import {
    Link
} from "react-router-dom";
import SocialAuth from "./SocialAuth";
import * as auth from "../../store/actions/Auth";
import { connect } from "react-redux";
import {
    useLocation,
    useNavigate
} from "react-router-dom";
import large_logo from "../../assets/logo_main.png"

import * as queryString from "querystring";
const Login = props => {
    let { search } = useLocation()
    let params = queryString.parse(search)
    let navigate = useNavigate()
    const loginHandle = (e) => {
        e.preventDefault()
        let data = {
            username: e.target.elements.username.value,
            password: e.target.elements.password.value,
            grant_type: 'password',
        }
        let next_ = "/"
        if (params["?next"]) {
            next_ = params["?next"]
        } else if (params["next"]) {
            next_ = params["next"]
        }
        props.onLogin(data, navigate, next_)
    }
    return (

        <div className={'place-content-center px-2 pt-16 lg:pt-0  w-full  lg:w-1/2 '}>

            <div className={"lg:hidden flex justify-center items-center w-full"}>
                <Link to={'/'} className="font-bold text-3xl">
                    <img src={large_logo} alt="logo" className="h-24 py-5" />
                </Link>
            </div>
            <h6 className="text-center font-semibold text-xl">Sign In</h6>

            <div className={'flex justify-between items-center'}>
                <div className="py-5 m-auto">
                    {props.login_error ?
                        <div className="flex flex-col justify-between items-center py-2 pb-5 w-full">
                            <span className="text-red-600 bg-red-200 py-2 px-5 rounded-sm w-full">{props.login_error}</span>
                        </div>
                        : ""
                    }
                    <form onSubmit={loginHandle} method={"POST"}>
                        <div className="flex flex-col justify-between items-center space-y-5">
                            <input type="text" required={true} className="px-8 rounded-3xl border-gray-300 bg-white w-full" placeholder="username or email"
                                name={"username"} />
                            <input type="password" required={true} className="px-8 rounded-3xl border-gray-300
                         bg-white w-full" placeholder="password" name={"password"} />
                        </div>
                        <div className="py-4 flex flex-col justify-center space-y-5">
                            <Link className="text-right underline" to={'/auth/forgot-password'}>Forgot password</Link>
                            <button disabled={props.login_loading} className={'text-center bg-info-blue rounded-3xl text-white py-2'}>
                                {
                                    props.login_loading ?
                                        <svg className={"animate-spin inline text-white h-5 w-5 mr-3"} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                                            xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                            width="26.349px" height="26.35px" viewBox="0 0 26.349 26.35"
                                            space="preserve" fill={"white"}>
                                            <g>
                                                <g>
                                                    <circle cx="13.792" cy="3.082" r="3.082" />
                                                    <circle cx="13.792" cy="24.501" r="1.849" />
                                                    <circle cx="6.219" cy="6.218" r="2.774" />
                                                    <circle cx="21.365" cy="21.363" r="1.541" />
                                                    <circle cx="3.082" cy="13.792" r="2.465" />
                                                    <circle cx="24.501" cy="13.791" r="1.232" />
                                                    <path d="M4.694,19.84c-0.843,0.843-0.843,2.207,0,3.05c0.842,0.843,2.208,0.843,3.05,0c0.843-0.843,0.843-2.207,0-3.05
			C6.902,18.996,5.537,18.988,4.694,19.84z"/>
                                                    <circle cx="21.364" cy="6.218" r="0.924" />
                                                </g>
                                            </g>
                                            <g>
                                            </g>
                                            <g>
                                            </g>
                                            <g>
                                            </g>
                                            <g>
                                            </g>
                                            <g>
                                            </g>
                                            <g>
                                            </g>
                                            <g>
                                            </g>
                                            <g>
                                            </g>
                                            <g>
                                            </g>
                                            <g>
                                            </g>
                                            <g>
                                            </g>
                                            <g>
                                            </g>
                                            <g>
                                            </g>
                                            <g>
                                            </g>
                                            <g>
                                            </g>
                                        </svg> :
                                        ""
                                }
                                {
                                    props.login_loading ?
                                        <span className={"animate-pulse"}>loading...</span>
                                        :
                                        "Sign in"
                                }
                            </button>
                            <p className={'text-gray-500'}>
                                Don&#39;t have an account?
                                <Link to={'/auth/register'}
                                    className={'text-info-blue'}> Register</Link>
                            </p>
                            <div className="flex flex-row  justify-between py-10">
                                <div className="border-b-2 w-full flex-1 bg-skin-tertiary h-[3px] my-2.5 mx-2" />
                                <span className={''}>Or signin with</span>
                                <div className="border-b-2 w-full flex-1 bg-skin-tertiary h-[3px] my-2.5 mx-2" />
                            </div>
                            <SocialAuth />
                        </div>

                    </form>

                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        login_loading: state.auth.login_loading,
        login_error: state.auth.login_error,
    }
};
const mapDispatchToProps = dispatch => {
    return {
        onLogin: (data, navigate, path) => dispatch(auth.authLogin(data, navigate, path))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);