import React  from 'react';
import * as auth from "../../store/actions/Auth";
import { connect } from "react-redux";


import { useLinkedIn } from 'react-linkedin-login-oauth2';
// You can use provided image shipped by this package or using your own


const LinkedIn=(props) =>{
    const { linkedInLogin } = useLinkedIn({
        clientId: props.clientId,
        redirectUri: props.redirectUri, // for Next.js, you can use `${typeof window === 'object' && window.location.origin}/linkedin`
        scope: "r_emailaddress r_liteprofile w_member_social",
        onSuccess: (code) => {
            props.onSuccess(code);
        },
        onError: (error) => {
            props.onError(error);
        },
    });

    return (
        <button className={'px-5 py-1 border rounded-2xl border-info-blue'} onClick={(e)=>{e.preventDefault();linkedInLogin()}}>
            <svg className={"w-8 h-8 inline "} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48"
                 width="48px" height="48px">
                <path fill="#0288D1"
                      d="M42,37c0,2.762-2.238,5-5,5H11c-2.761,0-5-2.238-5-5V11c0-2.762,2.239-5,5-5h26c2.762,0,5,2.238,5,5V37z"/>
                <path fill="#FFF"
                      d="M12 19H17V36H12zM14.485 17h-.028C12.965 17 12 15.888 12 14.499 12 13.08 12.995 12 14.514 12c1.521 0 2.458 1.08 2.486 2.499C17 15.887 16.035 17 14.485 17zM36 36h-5v-9.099c0-2.198-1.225-3.698-3.192-3.698-1.501 0-2.313 1.012-2.707 1.99C24.957 25.543 25 26.511 25 27v9h-5V19h5v2.616C25.721 20.5 26.85 19 29.738 19c3.578 0 6.261 2.25 6.261 7.274L36 36 36 36z"/>
            </svg>
        </button>
    );
}

const mapStateToProps = (state) => {
    return {
        login_loading: state.auth.register_loading,
        login_error: state.auth.login_error,
    }
};
const mapDispatchToProps = dispatch => {
    return {
        finishLogin: (response) => dispatch(auth.finishLogin(response))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(LinkedIn)