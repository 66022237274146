import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import ArticleList from "./sub/ArticleList";
import { http } from "../settings";
import { Link } from "react-router-dom";
import RemoveFromSaved from "./sub/article/RemoveFromSaved";
import * as auth from "../store/actions/Auth";
import * as general from "../store/actions/General";
import InfiniteScroll from "react-infinite-scroll-component";
import { useLocation, useNavigate } from "react-router-dom";
import * as queryString from "querystring";

const Articles = (props) => {
  let { search } = useLocation();
  let params = queryString.parse(search);
  let navigate = useNavigate();
  const [articles, setArticle] = useState([]);
  const [loading, setLoading] = useState(false);

  const [pop_remove_saved, popupRemoveFromSaved] = useState(false);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(12);
  const [total, setTotal] = useState(1);
  const [remove_article, setRemoveArticle] = useState(null);

  const [fetching, setFetching] = useState(false);
  const [has_more, setHasMore] = useState(true);
  const [sort, setSort] = useState("descending");
  const fetchData = (initial, sort_by) => {
    if (!fetching) {
      if (!articles.length) {
        setLoading(true);
      }
      let url = `/api/articles/?page=${initial ? 1 : page + 1}&size=${size}`;
      if (sort) {
        url += `&sort=${sort_by ? sort_by : sort}`;
      }
      setFetching(true);
      http.get(url).then((res) => {
        setArticle([...articles, ...res.data.items]);
        setPage(res.data.page);
        setSize(res.data.size);
        setTotal(res.data.total);
        setFetching(false);
        setHasMore(res.data.page * res.data.size < res.data.total);
        setLoading(false);
      });
    }
  };

  useEffect(() => {
    let sort = params && params["?sort"] ? params["?sort"] : "descending";
    setSort(sort);
    fetchData(true, sort ? sort : "");
  }, []);
  const changeSort = (e) => {
    setArticle([...articles.splice(0, articles.length)]);
    setSort(e.target.value);
    navigate(`/articles?sort=${e.target.value}`);
    setPage(1);
    fetchData(true, e.target.value);
  };
  const isInSaved = () => {
    return props.articles_saved.find((sa) => {
      return sa.article_id === remove_article;
    });
  };

  const saveArticle = (id) => {
    setRemoveArticle(id);
    popupRemoveFromSaved(false);
    http.post(`/api/articles/saved?article_id=${id}`).then((res) => {
      let saved_a = [...props.articles_saved];
      if (res.data.status == false) {
        let c_article = isInSaved();
        let ca_ind = saved_a.indexOf(c_article);
        saved_a.splice(ca_ind, 1);
        props.updateSavedArticles(saved_a);
        props.setAlert({ show: true, error: false, message: res.data.message });
      } else {
        saved_a.push({ article_id: parseInt(id) });
        props.updateSavedArticles(saved_a);
        props.setAlert({ show: true, error: false, message: res.data.message });
      }
    });
  };

  return (
    <div>
      <div
        className={
          "bg-articlesBanner py-20 bg-no-repeat bg-center bg-cover flex flex-col justify-center items-center "
        }
      >
        <h3 className={"text-white font-bold text-3xl"}>Latest Articles</h3>
        <p className="text-white space-x-3">
          <Link to="/">Home</Link>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6 inline"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M9 5l7 7-7 7"
            />
          </svg>
          <Link to={""}>Articles</Link>
        </p>
      </div>
      {pop_remove_saved ? (
        <RemoveFromSaved
          remove_article={remove_article}
          popupRemoveFromSaved={popupRemoveFromSaved}
          saveArticle={saveArticle}
        />
      ) : (
        ""
      )}

      <div className="m-auto max-w-screen-2xl mx-5 lg:mx-auto ">
        <div className="flex justify-between items-center py-2 mx-5 px-5">
          <span></span>
          <select className="rounded" onChange={changeSort}>
            <option value={"descending"}>sort by</option>
            <option value="ascending">Date created &#8593;</option>
            <option value="descending">Date created &#8595;</option>
          </select>
        </div>
        {loading ? (
          <div className="text-center justify-center flex items-center p-20">
            <svg className="h-16 w-16 animate-spin" viewBox="0 0 24 24">
              <path
                fill="currentColor"
                d="M12 19C13.1 19 14 19.9 14 21S13.1 23 12 23 10 22.1 10 21 10.9 19 12 19M12 1C13.1 1 14 1.9 14 3S13.1 5 12 5 10 4.1 10 3 10.9 1 12 1M6 16C7.1 16 8 16.9 8 18S7.1 20 6 20 4 19.1 4 18 4.9 16 6 16M3 10C4.1 10 5 10.9 5 12S4.1 14 3 14 1 13.1 1 12 1.9 10 3 10M6 4C7.1 4 8 4.9 8 6S7.1 8 6 8 4 7.1 4 6 4.9 4 6 4M18 16C19.1 16 20 16.9 20 18S19.1 20 18 20 16 19.1 16 18 16.9 16 18 16M21 10C22.1 10 23 10.9 23 12S22.1 14 21 14 19 13.1 19 12 19.9 10 21 10M18 4C19.1 4 20 4.9 20 6S19.1 8 18 8 16 7.1 16 6 16.9 4 18 4Z"
              />
            </svg>
          </div>
        ) : (
          <InfiniteScroll
            className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 mx-5 py-10"
            dataLength={articles.length} //This is important field to render the next data
            next={fetchData}
            hasMore={has_more && !fetching}
            loader={
              <h4
                className={
                  "lg:col-span-3 col-span-2 md:col-spa-3 xl:col-span-4 text-center"
                }
              >
                Loading...
              </h4>
            }
            endMessage={
              <p
                style={{ textAlign: "center" }}
                className={
                  "lg:col-span-3 col-span-2 md:col-spa-3 xl:col-span-4 text-center"
                }
              >
                <b>You have seen all</b>
              </p>
            }
            refreshFunction={fetchData}
          >
            {articles.map((article, i) => (
              <ArticleList
                saveArticle={saveArticle}
                key={article.id}
                article={article}
                popupRemoveFromSaved={popupRemoveFromSaved}
                setRemoveArticle={setRemoveArticle}
              />
            ))}
          </InfiniteScroll>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    articles_saved: state.auth.articles_saved,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateSavedArticles: (liked_articles) =>
      dispatch(auth.articlesSaved(liked_articles)),
    setAlert: (data) => dispatch(general.setAlert(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Articles);
