import React, { useState } from "react";
import { connect } from "react-redux";
import contribute_image from "../assets/cont.png";
import { http } from "../settings";
import * as general from "../store/actions/General";

const Contribute = (props) => {
  const [loading, setLoading] = useState(false);
  const contributeSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    let data = {
      name: e.target.elements.name.value,
      email: e.target.elements.email.value,
      role: e.target.elements.role.value,
      github_link: e.target.elements.github_link.value,
      brief_introduction: e.target.elements.introduction.value,
    };
    setLoading(false);
    http
      .post(`/api/join`, data)
      .then((res) => {
        props.setAlert({
          error: !res.data.status,
          message: `Thanks for your interest! Will get back to you soon!`,
          show: true,
        });
        e.target.elements.name.value = "";
        e.target.elements.email.value = "";
        e.target.elements.role.value = "Content Creator";
        e.target.elements.github_link.value = "";
        e.target.elements.introduction.value = "";
      })
      .catch((err) => {
        setLoading(false);
        props.setAlert({
          error: true,
          message: "error occured submiting request",
          show: true,
        });
      });
  };
  return (
    <div className="m-auto max-w-screen-2xl mx-5 lg:mx-auto">
      <div className="flex flex-col py-10">
        <h1 className="text-center font-bold text-5xl">
          Want to Join the Team?
        </h1>
        <div className="grid md:grid-cols-5">
          <div className="md:col-span-3 py-10">
            <div>
              <div>
                We are a nonprofit volunteering startup where we are always
                looking for motivated individuals looking to contribute to
                society, make a more significant impact, and make the world a
                better place to live.
                <div className="px-10">
                  <div className="py-10">
                    Different Roles in which you can contribute:
                  </div>
                  <ol className=" list-inside list-disc">
                    <li>
                      <b>Content Creator</b>: Writing or Reviewing articles on
                      different topics will be the key responsibility.
                    </li>
                    <li>
                      <b>Web Developers</b>: We want to include some more
                      exciting features to Quantumgrad; Frontend and Backend
                      developers are welcome.
                    </li>
                    <li>
                      <b>App Developers</b>: We are working on a Quantumgrad
                      app; App developers are welcome.
                    </li>
                    <li>
                      <b>Tech leads</b>: We are looking for experienced folks
                      who can guide people in Content, Web and App development.
                    </li>
                    <li>
                      <b>Mentors</b>: We are looking for Quantum Computing
                      experts to mentor and make this platform more robust and
                      accessible to everyone.
                    </li>
                  </ol>
                </div>
              </div>
              <div className="flex items-center justify-center">
                <img src={contribute_image} />
              </div>
            </div>
          </div>
          <form
            onSubmit={contributeSubmit}
            className="border-gray-200 border shadow px-10 py-10 md:col-span-2 
                    justify-between items-center lg:mx-auto w-full mt-10 rounded"
          >
            <div className="py-4">
              <label htmlFor="name" className="font-bold py-4">
                Name
              </label>
              <input
                name="name"
                required
                id="name"
                type="text"
                className="rounded border-gray-300 w-full bg-gray-200"
                placeholder=""
              />
            </div>
            <div className="py-4">
              <label htmlFor="email" className="font-bold py-4">
                Email Address
              </label>
              <input
                name="email"
                required
                id="email"
                type="text"
                className="rounded border-gray-300 w-full bg-gray-200"
                placeholder=""
              />
            </div>
            <div className="py-4">
              <label htmlFor="Interested" className="font-bold py-4">
                Interested Role{" "}
              </label>
              <select
                name="role"
                required
                id="Interested"
                className="rounded border-gray-300 w-full bg-gray-200"
              >
                <option>Content Creator</option>
                <option>Web Developers</option>
                <option>App Developers</option>
                <option>Tech Leads</option>
                <option>Mentors</option>
              </select>
            </div>
            <div className="py-4">
              <label htmlFor="Introduction" className="font-bold py-4">
                Brief Introduction
              </label>
              <textarea
                name="introduction"
                required
                id="Introduction"
                rows="4"
                className="rounded border-gray-300 w-full bg-gray-200"
              ></textarea>
            </div>
            <div className="py-4">
              <label htmlFor="Github" className="font-bold py-4">
                Github Link (optional)
              </label>
              <input
                id="Github"
                type="url"
                name="github_link"
                className="rounded border-gray-300 w-full bg-gray-200"
                placeholder=""
              />
            </div>
            <button
              disabled={loading}
              className="px-10 py-1 border-info-blue bg-info-blue text-white rounded-full"
            >
              {loading ? "Sibmitting..." : "submit"}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => {
  return {
    setAlert: (data) => dispatch(general.setAlert(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Contribute);
