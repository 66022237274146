import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { http } from '../../settings';
import InfiniteScroll from 'react-infinite-scroll-component';


const Drafts = (props) => {
    const [open_delete_pop, set0penDeletePop] = useState(false);
    const [drafts, setDrafts] = useState([])
    const [deleteId, setDeleteId] = useState(0)
    const [page, setPage] = useState(1);
    const [size, setSize] = useState(12);
    const [total, setTotal] = useState(1);

    const [fetching, setFetching] = useState(false)
    const [has_more, setHasMore] = useState(false)

    const fetchData = (initial) => {
        if (!fetching) {
            const url = `/api/articles/drafts?page=${initial?1:page+1}&size=${size}`
            setFetching(true);
            http.get(url).then(res => {
                setDrafts([...drafts, ...res.data.items])
                setPage(res.data.page)
                setSize(res.data.size)
                setTotal(res.data.total)
                setHasMore(res.data.page * res.data.size < res.data.total)
                setFetching(false);
            })
        }
    }

    useEffect(() => {
        fetchData(true)
    }, [])
    const deleteDraf = () => {
        set0penDeletePop(false);
        http.delete(`/api/articles/article/${deleteId}`).then(res => {
            console.log(res.data)
        })
    }

    return (
        <div className="m-auto max-w-screen-2xl mx-5 lg:mx-auto">
            <div className="border-b border-gray pt-10 pb-4 space-x-20 mx-8 ">
                <h3 className="font-bold text-xl">Drafts</h3>
            </div>
            <div className="py-10">
                {open_delete_pop ?
                    <div
                        className="fixed  inset-0 z-1000 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full"
                        id="my-modal"
                    >
                        <div className="relative top-80 z-10 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
                            <p className="py-4 text-center">Are you sure you want to delete this?</p>
                            <div className="flex justify-between items-center -auto max-w-screen-2xl mx-5">
                                <button className="rounded-full px-10 border-info-blue border"
                                    onClick={() => set0penDeletePop(false)}>cancel</button>
                                <button className="rounded-full px-10 bg-info-blue text-white"
                                    onClick={() => deleteDraf()}>yes</button>
                            </div>
                        </div>
                    </div>
                    :
                    ""
                }
                <InfiniteScroll
                    key={has_more}
                    className="flex flex-col"
                    dataLength={drafts.length} //This is important field to render the next data
                    next={fetchData}
                    hasMore={has_more}
                    loader={<h4 className={'lg:col-span-3 col-span-2 md:col-spa-3 xl:col-span-4'}>Loading...</h4>}
                    endMessage={
                        <p className={'lg:col-span-3 col-span-2 md:col-spa-3 xl:col-span-4 text-center'}>
                            <b>You have seen  all</b>
                        </p>
                    }
                    refreshFunction={fetchData}
                >
                    {
                        drafts.map(draft =>
                            <>
                                <div className="flex flex-col px-10">
                                    <div>
                                        <h5 className="font-bold text-lg">
                                            {draft.title}
                                        </h5>

                                    </div>
                                    <p>
                                        {draft.short_intro}
                                    </p>
                                </div>
                                <div className="flex justify-between items-center px-10 border-b border-black py-5">
                                    <div></div>
                                    <div className="space-x-10">
                                        <Link to={`/edit-article/${draft.id}`} className="px-6 py-0.5 border rounded-full border-info-blue text-info-blue">
                                            Edit</Link>
                                        <button className="px-6 py-0.5 border rounded-full border-red-600 text-red-600"
                                            onClick={() => {
                                                setDeleteId(draft.id)
                                                set0penDeletePop(true)
                                            }}>Delete</button>
                                    </div>
                                </div>
                            </>
                        )
                    }
                </InfiniteScroll>


            </div>
        </div>);
};

export default Drafts;
