import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { http } from "../settings";

const EmailUnsubscribe = (props) => {
  const url_params = useParams();
  const [message, setMessage] = useState("");
  useEffect(() => {
    http
      .delete(`http://127.0.0.1:8000/api/mails/event/${url_params.email}`)
      .then((res) => {
        setMessage(res.data.message);
      })
      .catch((err) => {
        setMessage(err.response.data.detail);
      });
  }, [url_params.email]);
  return (
    <div className="flex max-w-screen-2xl mx-auto p-20">
      <div className="flex  w-full shadow">
        <div className="m-auto">
          <h3 className="text-4xl">hi!</h3>
          <h6 className="text-2xl">{message}</h6>
        </div>
      </div>
    </div>
  );
};

export default EmailUnsubscribe;
