import React, { useEffect, useState } from 'react';
import LogoutConfirm from '../auth/LogoutConfirm';
import Footer from '../sub/Footer';
import Header from "../sub/Header";
import Search from "../sub/Search";
import { connect } from 'react-redux';
import MessageAlert from './MessageAlert';


const MainLayout = ({ children }) => {
    const [searching, updateSearching] = useState(false)

    return (


        <div className={'relative h-screen'}>

            {
                searching ?
                    <Search updateSearching={() => updateSearching(!searching)} /> :
                    <>
                        <MessageAlert />
                        <Header updateSearching={() => updateSearching(!searching)} />
                        <LogoutConfirm />
                        {children}
                        <Footer />
                    </>
            }

        </div>
    );
};

const mapStateToProps = (state) => {
    return {

    }
};

const mapDispatchToProps = dispatch => {
    return {

    }
};


export default connect(mapStateToProps, mapDispatchToProps)(MainLayout);