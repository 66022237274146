import {
    BrowserRouter as Router,
    Routes,
    Navigate,
    Route,
} from "react-router-dom";
import React, { useEffect } from "react";
import Home from "./components/Home";
import MainLayout from "./components/layouts/MainLayout";
import WriteAricle from "./components/layouts/WriteAricle";
import DashboardLayout from "./components/layouts/DashboardLayout";
import Auth from "./components/layouts/Auth";
import Login from "./components/auth/Login";
import Register from "./components/auth/Register";
import ForgotPassword from "./components/auth/ForgotPassword";
import CreateArticle from "./components/CreateArticle";
import Articles from "./components/Articles";
import News from "./components/News";
import Publications from "./components/Publications";
import Events from "./components/Events";
import About from "./components/About";
import Jobs from "./components/Jobs";
import Contribute from "./components/Contribute";
import Marketplace from "./components/Marketplace";
import * as auth from "./store/actions/Auth";
import { connect } from "react-redux";
import VerifyAccount from "./components/auth/VerifyAccount";
import ResetPassword from "./components/auth/ResetPassword";
import WriterProfile from "./components/WriterProfile";
import ArticleDetails from "./components/ArticleDetails";
import { Following } from "./components/Following";
import Profile from "./components/dashboard/Profile";
import Dashboard from "./components/dashboard/Dashboard";
import Drafts from "./components/dashboard/Drafts";
import SavedArticles from "./components/SavedArticles";
import EditArticle from "./components/EditDraft";
import "flowbite";
import SearchResults from "./components/SearchResults";
import CustomRouter from "./CustomRouter";
import Books from "./components/Books";
import Privacy from "./components/Privacy";
import { history } from "./history";
import Notifications  from "./components/Notifications";
import { LinkedInCallback } from 'react-linkedin-login-oauth2';
import EmailUnsubscribe from "./components/EmailUnsubscribe";
import WomenPower from "./components/womenpower/WomenPower";
import BookChapter from "./components/BookChapter/Index";
import Conferences from "./components/Conferences";
import Videos from "./components/Videos";



const App = (props) => {
    useEffect(() => {
        // Update the document title using the browser API    document.title = `You clicked ${count} times`;
        props.checkAuth();
    });
    return (
        <Router>
            <Routes>
                    <Route
                        exact
                        path="/"
                        element={ 
                            <CustomRouter {...props} history={history}>
                                    {" "}
                                    <MainLayout {...props}>
                                        <Home {...props} />
                                    </MainLayout>
                            </CustomRouter>
                        }
                    />
                    <Route exact path="/linked-in" element={<LinkedInCallback/>} />
                    {/* <Route exact path="/verify-twitter" element={<FinishTwitter/>} /> */}
                    <Route
                        exact
                        path="/articles"
                        element={
                            <CustomRouter {...props} history={history}>
                                    {" "}
                                    <MainLayout {...props}>
                                        <Articles {...props} />
                                    </MainLayout>
                            </CustomRouter>
                        }
                />
                <Route
                        exact
                        path="/unsubscribe/:email"
                        element={
                            <CustomRouter {...props} history={history}>
                                    {" "}
                                    <MainLayout {...props}>
                                        <EmailUnsubscribe {...props} />
                                    </MainLayout>
                            </CustomRouter>
                        }
                    />
                    <Route
                        exact
                        path="/saved"
                        element={
                            <CustomRouter {...props} history={history}>
                                {" "}
                                <MainLayout {...props}>
                                    {props.token || props.auth_loading ? (
                                        <SavedArticles {...props} />
                                    ) : (
                                        <Navigate to="/auth/login?next=/saved" />
                                    )}
                                </MainLayout>
                            </CustomRouter>
                        }
                    />
                    <Route
                        exact
                        path="/article/:id"
                        element={
                            <CustomRouter {...props} history={history}>
                                {" "}
                                <MainLayout {...props}>
                                    <ArticleDetails {...props} />
                                </MainLayout>
                            </CustomRouter>
                        }
                    />
                    <Route
                        exact
                        path="/edit-article/:id"
                        element={
                            <WriteAricle {...props}>
                                {props.token || props.auth_loading ? (
                                    <EditArticle {...props} />
                                ) : (
                                    <Navigate to="/auth/login?next=/write" />
                                )}
                            </WriteAricle>
                        }
                    />
                    <Route
                        exact
                        path="/news"
                        element={
                            <CustomRouter {...props} history={history}>
                                {" "}
                                <MainLayout {...props}>
                                    <News {...props} />
                                </MainLayout>
                            </CustomRouter>
                        }
                    />
                    <Route
                        exact
                        path="/books"
                        element={
                            <CustomRouter {...props} history={history}>
                                {" "}
                                <MainLayout {...props}>
                                    <Books {...props} />
                                </MainLayout>
                            </CustomRouter>
                        }
                    />
                    <Route
                        exact
                        path="/publications"
                        element={
                            <CustomRouter {...props} history={history}>
                                {" "}
                                <MainLayout {...props}>
                                    <Publications {...props} />
                                </MainLayout>
                            </CustomRouter>
                        }
                    />
                    <Route
                        exact
                        path="/events"
                        element={
                            <CustomRouter {...props} history={history}>
                                {" "}
                                <MainLayout {...props}>
                                    <Events {...props} />
                                </MainLayout>
                            </CustomRouter>
                        }
                    />
                    <Route
                        exact
                        path="/conferences"
                        element={
                            <CustomRouter {...props} history={history}>
                                {" "}
                                <MainLayout {...props}>
                                    <Conferences {...props} />
                                </MainLayout>
                            </CustomRouter>
                        }
                    />
                    <Route
                        exact
                        path="/videos"
                        element={
                            <CustomRouter {...props} history={history}>
                                {" "}
                                <MainLayout {...props}>
                                    <Videos {...props} />
                                </MainLayout>
                            </CustomRouter>
                        }
                    />
                    <Route
                        exact
                        path="/jobs"
                        element={
                            <CustomRouter {...props} history={history}>
                                {" "}
                                <MainLayout {...props}>
                                    <Jobs {...props} />
                                </MainLayout>
                            </CustomRouter>
                        }
                    />
                    <Route
                        exact
                        path="/search"
                        element={
                            <CustomRouter {...props} history={history}>
                                {" "}
                                <MainLayout {...props}>
                                    <SearchResults {...props} />
                                </MainLayout>
                            </CustomRouter>
                        }
                    />
                    <Route
                        exact
                        path="/about-us"
                        element={
                            <CustomRouter {...props} history={history}>
                                {" "}
                                <MainLayout {...props}>
                                    <About {...props} />
                                </MainLayout>
                            </CustomRouter>
                        }
                    />
                    <Route
                        exact
                        path="/contribute"
                        element={
                            <CustomRouter {...props} history={history}>
                                {" "}
                                <MainLayout {...props}>
                                    <Contribute {...props} />
                                </MainLayout>
                            </CustomRouter>
                        }
                    />
                    <Route
                        exact
                        path="/marketplace"
                        element={
                            <CustomRouter {...props} history={history}>
                                {" "}
                                <MainLayout {...props}>
                                    <Marketplace {...props} />
                                </MainLayout>
                            </CustomRouter>
                        }
                    />
                    <Route
                        exact
                        path="/terms-and-conditions"
                        element={
                            <CustomRouter {...props} history={history}>
                                {" "}
                                <MainLayout {...props}>
                                    <Privacy {...props} />
                                </MainLayout>
                            </CustomRouter>
                        }
                    />

                    <Route
                        exact
                        path="/women-empowerment"
                        element={
                            <CustomRouter {...props} history={history}>
                                {" "}
                                <MainLayout {...props}>
                                    <WomenPower {...props} />
                                </MainLayout>
                            </CustomRouter>
                        }
                    />

                    <Route
                        exact
                        path="/book-chapter"
                        element={
                            <CustomRouter {...props} history={history}>
                                {" "}
                                <MainLayout {...props}>
                                    <BookChapter {...props} />
                                </MainLayout>
                            </CustomRouter>
                        }
                    />
                    <Route
                        exact
                        path="/write"
                        element={
                            <WriteAricle {...props}>
                                {props.token || props.auth_loading ? (
                                    <CreateArticle {...props} />
                                ) : (
                                    <Navigate to="/auth/login?next=/write" />
                                )}
                            </WriteAricle>
                        }
                    />
                    <Route
                        exact
                        path="/writer/:id"
                        element={
                            <CustomRouter {...props} history={history}>
                                {" "}
                                <MainLayout {...props}>
                                    <WriterProfile {...props} />
                                </MainLayout>
                            </CustomRouter>
                        }
                    />
                    <Route
                        exact
                        path="/following"
                        element={
                            <CustomRouter {...props} history={history}>
                                {" "}
                                <MainLayout {...props}>
                                    <Following {...props} />
                                </MainLayout>
                            </CustomRouter>
                        }
                    />
                    <Route
                        exact
                        path="/notifications"
                        element={
                            <CustomRouter {...props} history={history}>
                                {" "}
                                <MainLayout {...props}>
                                    <Notifications {...props} />
                                </MainLayout>
                            </CustomRouter>
                        }
                    />
                    <Route
                        exact
                        path="/dashboard"
                        element={
                            <DashboardLayout {...props}>
                                {props.token || props.auth_loading ? (
                                    <Dashboard {...props} />
                                ) : (
                                    <Navigate to="/auth/login?next=/dashboard" />
                                )}
                            </DashboardLayout>
                        }
                    />
                    <Route
                        exact
                        path="/dashboard/profile"
                        element={
                            <DashboardLayout {...props}>
                                {props.token || props.auth_loading ? (
                                    <Profile {...props} />
                                ) : (
                                    <Navigate to="/auth/login?next=/dashboard/profile" />
                                )}
                            </DashboardLayout>
                        }
                    />
                    <Route
                        exact
                        path="/dashboard/drafts"
                        element={
                            <DashboardLayout {...props}>
                                {props.token || props.auth_loading ? (
                                    <Drafts {...props} />
                                ) : (
                                    <Navigate to="/auth/login?next=/dashboard/drafts" />
                                )}
                            </DashboardLayout>
                        }
                    />
                    <Route
                        exact
                        path="/auth/login"
                        element={
                            <Auth {...props}>
                                <Login {...props} />
                            </Auth>
                        }
                    />
                    <Route
                        exact
                        path="/auth/register"
                        element={
                            <Auth {...props}>
                                <Register {...props} />
                            </Auth>
                        }
                    />
                    <Route
                        exact
                        path="/auth/verify-account/:token"
                        element={
                            <Auth {...props}>
                                <VerifyAccount {...props} />
                            </Auth>
                        }
                    />

                    <Route
                        exact
                        path="/auth/verify-reset-password/:token"
                        element={
                            <Auth {...props}>
                                <ResetPassword {...props} />
                            </Auth>
                        }
                    />
                    <Route
                        exact
                        path="/auth/forgot-password"
                        element={
                            <Auth {...props}>
                                <ForgotPassword {...props} />
                            </Auth>
                        }
                    />

            </Routes>
        </Router>
    );
};

const mapStateToProps = (state) => {
    return {
        login_error: state.auth.login_error,
        token: state.auth.token,
        auth_loading: state.auth.auth_loading,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        checkAuth: () => dispatch(auth.authCheckState()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
