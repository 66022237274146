import React from "react";
import { connect } from "react-redux";
import MediumEditor from "./MediumEditor";
import { useNavigate } from "react-router-dom";

const CreateArticle = () => {
  let navigate = useNavigate()
  return (
    <div className="flex max-w-6xl mx-5 lg:mx-auto lg:px-10 md:w-full py-24">
      <MediumEditor article={{ content: "" }}
        id={null} navigate={navigate} />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(CreateArticle);
