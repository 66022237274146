import React, { useState, useEffect } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { connect } from "react-redux";
import { http } from "../settings";
import { Link, useNavigate } from "react-router-dom";
import * as general from "../store/actions/General";
import * as auth from "../store/actions/Auth";

const Notifications = (props) => {
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [total, setTotal] = useState(100);
  const [notifications, setNotifications] = useState([]);
  const [has_more, setHasMore] = useState(true);
  let navigate = useNavigate();
  const fetchNotifications = (initial) => {
    http
      .get(
        `/api/notifications/user?page=${initial ? page : page + 1}&size=${size}`
      )
      .then((res) => {
        setNotifications([...notifications, ...res.data.items]);
        setSize(res.data.size);
        setPage(res.data.page);
        setTotal(res.data.total);
        setHasMore(res.data.page * res.data.size < res.data.total);
      });
  };
  useEffect(() => {
    fetchNotifications(true);
    return () => {
      props.userNotifications(0);
      http.post(`/api/notifications/mark-read/`).then((res) => {
        console.log(res.data);
      });
    };
  }, []);
  const markAsRed = (id, action, action_id) => {
    http.put(`/api/notifications/mark-read/${id}`).then((res) => {
      fetchNotifications();
    });
    if (
      action == "ARTICLE_LIKE" ||
      action == "COMMENT" ||
      action == "APPROVED"
    ) {
      navigate(`/article/${action_id}`);
    }
    if (action == "USER_FOLLOW") {
      navigate(`/writer/${action_id}`);
    }
  };
  const deleteNotification = (id) => {
    http.put(`/api/notifications/delete/${id}`).then((res) => {
      props.setAlert({
        show: true,
        error: res.data.status,
        message: res.data.message,
      });
      fetchNotifications();
    });
  };
  return (
    <div className="m-auto max-w-screen-2xl mx-5 lg:mx-auto">
      <div className="border-b border-gray pt-10 pb-4 space-x-20 mx-8 ">
        <h3 className="font-bold text-xl">Notifications</h3>
      </div>
      <div className="py-10">
        <InfiniteScroll
          key={has_more}
          className="flex flex-col "
          dataLength={notifications.length} //This is important field to render the next data
          next={fetchNotifications}
          hasMore={has_more}
          loader={<h4 className={"text-center"}>Loading...</h4>}
          endMessage={
            <p className={"text-center"}>
              <b>You have seen all</b>
            </p>
          }
          refreshFunction={fetchNotifications}
        >
          {notifications.map((ntf) => (
            <div
              key={ntf.id}
              className={`flex justify-between items-center p-5 tracking-wide border-b`}
            >
              <span className="space-x-2">
                {ntf.read ? (
                  <span className="font-bold px-2 text-info-blue hidden md:flex"></span>
                ) : (
                  <span className="font-bold px-1 text-info-blue">&bull;</span>
                )}
                {ntf.notification_action == "COMMENT" ? (
                  <span>
                    <span
                      onClick={() =>
                        markAsRed(ntf.id, "COMMENT", ntf.article_id)
                      }
                      className="cursor-pointer"
                    >
                      You have a new comment from{" "}
                    </span>
                    <Link
                      to={`/writer/${ntf.trigger_user.id}`}
                      className="text-black font-bold"
                    >
                      {ntf.trigger_user.username}
                    </Link>
                  </span>
                ) : ntf.notification_action === "ARTICLE_LIKE" ? (
                  <span>
                    <Link
                      to={`/writer/${
                        ntf.trigger_user ? ntf.trigger_user.id : ""
                      }`}
                      className="text-black font-bold"
                    >
                      {ntf.trigger_user ? ntf.trigger_user.username : ""}
                    </Link>
                    <span
                      onClick={() =>
                        markAsRed(ntf.id, "ARTICLE_LIKE", ntf.article_id)
                      }
                      className="cursor-pointer"
                    >
                      {" "}
                      has liked your post
                    </span>
                  </span>
                ) : ntf.notification_action === "APPROVED" ? (
                  <span>
                    <span
                      onClick={() =>
                        markAsRed(ntf.id, "APPROVED", ntf.article_id)
                      }
                      className="cursor-pointer"
                    >
                      {" "}
                      Your article has been approved
                    </span>
                  </span>
                ) : ntf.notification_action === "USER_FOLLOW" ? (
                  <span>
                    <Link
                      to={`/writer/${
                        ntf.trigger_user ? ntf.trigger_user.id : ""
                      }`}
                      className="text-black font-bold"
                    >
                      {ntf.trigger_user ? ntf.trigger_user.username : ""}{" "}
                    </Link>
                    <span
                      onClick={() =>
                        markAsRed(ntf.id, "USER_FOLLOW", ntf.trigger_user.id)
                      }
                      className="cursor-pointer"
                    >
                      {" "}
                      started following you
                    </span>
                  </span>
                ) : ntf.notification_action === "PASSWORD_RESET" ? (
                  <span
                    onClick={() => markAsRed(ntf.id, "PASSWORD_RESET")}
                    className="cursor-pointer"
                  >
                    Your password has been reset.
                  </span>
                ) : ntf.notification_action === "COMMENT_LIKE" ? (
                  <span>
                    <Link
                      to={`/writer/${ntf.trigger_user.id}`}
                      className="text-black font-bold"
                    >
                      {ntf.trigger_user.username}
                    </Link>
                    <span
                      onClick={() => markAsRed(ntf.id)}
                      className="cursor-pointer"
                    >
                      {" "}
                      liked your comment
                    </span>
                  </span>
                ) : (
                  ""
                )}
                <span className="text-gray-700">{ntf.text}</span>
                <span className="text-xs text-gray-400">
                  {new Date(ntf.created_at).toDateString()}
                </span>
              </span>
              <button
                className="text-red-600"
                onClick={() => deleteNotification(ntf.id)}
              >
                <svg
                  className="text-red-600 h-5"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                  />
                </svg>
              </button>
            </div>
          ))}
        </InfiniteScroll>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => {
  return {
    setAlert: (data) => dispatch(general.setAlert(data)),
    userNotifications: (ntf) => dispatch(auth.userNotifications(ntf)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
