import React, { useEffect, useState } from 'react';
import ArticleList from '../sub/ArticleList';
import InfiniteScroll from 'react-infinite-scroll-component';
import { http } from "../../settings";
import RemoveFromSaved from '../sub/article/RemoveFromSaved';
import * as auth from "../../store/actions/Auth";
import * as general from "../../store/actions/General";
import { connect } from "react-redux";

const Posts = (props) => {
    const [articles, setArticles] = useState([]);
    const [pop_remove_saved, popupRemoveFromSaved] = useState(false);

    const [page, setPage] = useState(1);
    const [size, setSize] = useState(12);
    const [total, setTotal] = useState(1);
    const [remove_article, setRemoveArticle] = useState(null);

    const [fetching, setFetching] = useState(false)
    const [has_more, setHasMore] = useState(false)

    const fetchData = (initial) => {
        if (!fetching) {
            const url = `/api/articles/followers/articles?page=${initial?1:page+1}&size=${size}`
            setFetching(true);
            http.get(url).then(res => {
                setArticles([...articles, ...res.data.data.items])
                setPage(res.data.data.page)
                setSize(res.data.data.size)
                setTotal(res.data.data.total)
                setHasMore(res.data.data.page * res.data.data.size < res.data.data.total)
                setFetching(false);
            })
        }
    }
    const isLiked = () => {
        return props.articles_liked.find(sa => sa.article_id === remove_article)
    }
    const saveArticle = (id) => {
        setRemoveArticle(id)
        popupRemoveFromSaved(false)
        http.post(`/api/articles/saved?article_id=${id}`).then(res => {
            let liked_a = [...props.articles_saved]
            console.log(res.data)
            if (res.data.message.indexOf('unsaved') > 0) {
                let c_article = isLiked()
                let ca_ind = liked_a.indexOf(c_article)
                liked_a.splice(ca_ind, 1);
                props.updateSavedArticles(liked_a)
                props.setAlert({ show: true, error: false, message: res.data.message })

            } else {
                liked_a.push({ article_id: parseInt(id) })
                props.updateSavedArticles(liked_a)
                props.setAlert({ show: true, error: false, message: res.data.message })
            }
        })
    }
    useEffect(() => {
        fetchData(true)
    }, [])
    return (
        <div>
            {pop_remove_saved ? <RemoveFromSaved remove_article={remove_article}
                popupRemoveFromSaved={popupRemoveFromSaved} saveArticle={saveArticle} /> : ""}
            <InfiniteScroll
                key={has_more}
                className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 xl:grid-cols-4 mx-5 py-10"
                dataLength={articles.length} //This is important field to render the next data
                next={fetchData}
                hasMore={has_more}
                loader={<h4 className={'lg:col-span-3 col-span-2 md:col-spa-3 xl:col-span-4'}>Loading...</h4>}
                endMessage={
                    <p
                        className={'lg:col-span-3 col-span-2 md:col-spa-3 xl:col-span-4 text-center'}>
                        <b>You have seen  all</b>
                    </p>
                }
                refreshFunction={fetchData}
            >
                {
                    articles.map(article =>
                        <ArticleList saveArticle={saveArticle}
                        key={article.id} article={article}
                        popupRemoveFromSaved={popupRemoveFromSaved}
                        setRemoveArticle={setRemoveArticle} />
                    )}
            </InfiniteScroll>

        </div>);
};

const mapStateToProps = (state) => {
    return {
        articles_saved: state.auth.articles_saved
    }
};

const mapDispatchToProps = dispatch => {
    return {
        updateSavedArticles: (liked_articles) =>
            dispatch(auth.articlesSaved(liked_articles)),
        setAlert: (data) => dispatch(general.setAlert(data))

    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Posts)
