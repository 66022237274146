import axios from "axios"


let DEBUG = false;
if (window.location.hostname === "127.0.0.1" || window.location.hostname === "localhost") {
    DEBUG = true
}
let HOST_URL = "https://api.quantumgrad.com";

if (DEBUG) {
  HOST_URL = "http://127.0.0.1:8000"
}

if (!DEBUG) {
  HOST_URL = window.location.hostname === "staging.quantumgrad.com" ? "https://stagingapi.quantumgrad.com" :  "https://api.quantumgrad.com";
}

console.log(DEBUG)
const http = axios.create({
    baseURL: HOST_URL
  });


const updateHeaders=()=>{
  const AUTH_TOKEN = localStorage.getItem("token");
  http.defaults.headers.common['Authorization'] = `Bearer ${AUTH_TOKEN}`;
}
updateHeaders()

export {http,HOST_URL, updateHeaders}