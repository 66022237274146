import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import whiteimage from '../../../assets/white.jpeg'

const SingleArticle = (props) => {
    const article = props.article
    return (
        article ?
            <div className="mx-2 my-2 rounded-lg flex flex-col shadow-lg  transition transform ease-in-out delay-150 motion-reduce:transition-none motion-reduce:transform-none">
                <Link to={`/article/${article.id}`}
                    className={""}>
                    <img src={article.image ? article.image : whiteimage}
                        className={'rounded-md h-48 w-full object-cover hover:bg-blue-700 hover:opacity-30'}
                        alt={""} />
                </Link>
                <div className={"flex py-1 flex-col px-2 pb-4"}>
                    <Link to={`/article/${article.id}`} className={"text-black font-semibold text-sm hover:text-blue-600"}>
                        {article.title}
                    </Link>
                    <span className={"text-gray-400 text-xs pt-2"}>
                        <Link to={`/writer/${article.created_by.id}`} className='hover:text-blue-600 uppercase'>
                            {article.created_by.firstname ? article.created_by.firstname : article.created_by.username} {article.created_by.lastname}
                        </Link>
                        <span className='font-bold px-1'>&bull;</span>
                        <span className={"uppercase"}>{new Date(article.created_at).toDateString()}</span>
                        <span className='font-bold px-1'>&bull;</span>
                        <span>{article.read_time}</span>
                    </span>
                </div>
            </div>

            :
            ""
    )
}

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(SingleArticle)
