import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import MediumEditor from "./MediumEditor";
import { useParams } from "react-router-dom";
import { http } from "../settings";

const EditArticle = (props) => {
  const url_params = useParams();
  const [article, setArticle] = useState({ content: "" });

  useEffect(() => {
    http.get(`/api/articles/article/${url_params.id}`).then((res) => {
      setArticle(res.data.data.article);
    });
  }, []);
  return (
    <div className="flex  max-w-6xl mx-5 lg:mx-auto px-10 w-full py-24">
      <MediumEditor
        article={article}
        id={article.id}
        success_message={"Edit is being saved"}
      />
    </div>
  );
};

export default EditArticle;
