import React, { Component } from "react";

import { Block, addNewBlock } from "medium-draft";

export class CodeSideButton extends Component {
  constructor(props) {
    super(props);
  }
  // start props
  onClick() {
    this.props.setEditorState(
      addNewBlock(this.props.getEditorState(), Block.CODE, {})
    );
  }

  render() {
    return (
      <button
        className="md-sb-button md-sb-code-button"
        title="Add Code block"
        type="button"
        onClick={this.onClick.bind(this)}
      >
        <i className="fa fa-code" />
      </button>
    );
  }
}

export default CodeSideButton;
