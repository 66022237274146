import videoImage from "../../assets/videoimage.svg";
import chat from "../../assets/chat.svg";
import bulb from "../../assets/bulb.svg";
import learningChat from "../../assets/learningChat.svg";
import bookname from  "../../assets/bookname.svg"
import Contribute from "../womenpower/Contribute";
import JoinCommunity from "../womenpower/joinCommunity";

const BookChapter = () => {
  return (
    <div className="m-auto max-w-screen-2xl lg:mx-auto">
      <div className="bg-light-blue-grad curve py-20">       
        <div className="w-full text-center mt-20">
          <span className="text-black-blue-tint font-bold text-5xl"> Quantum Book Chapter</span>
        </div>

        <div className="my-10 text-center">
          <span className="">
            Here we announce our first Women in Quantum Initiative called “Quantum Book <br/> Chapter” led by a group of Women at Quantumgrad.
          </span>
        </div>
      </div>

      <div className="mt-20 text-center">
        <span className="mt-5 text-black-blue-tint font-bold text-3xl"> Led by a group of Women at <br/> Quantumgrad. </span>
      </div>

      <div className="w-full mt-20">
        <img className="mx-auto" src={videoImage} />
      </div>

      <div className="mt-20 w-full text-center md:text-left container space-y-5 md:grid md:grid-flow-col pb-20 md:space-x-10">
        <div className="md:ml-20 my-auto ">
          <span className="text-black-blue-tint text-2xl font-bold">Is this Quantum <br/> Initiative  for me? </span>
        </div>

        <div className="">
          <img
          className="md:-ml-8 mx-auto" width="80" height="80" src={bulb}></img>

          <div className="-mt-10">
            <span className="text-info-blue text-lg font-bold"> Gain Basic Knowledge </span>
          </div>

          <div className="text-black-blue-tint">
            <span className="text-sm">Set the basic building of <br/> Quantum Computing!</span>
          </div>
        </div>

        <div className="">
          <img
          className="mx-auto md:mx-0" src={learningChat}></img>

          <div className="mt-3">
            <span className="text-info-blue text-lg font-bold"> 12 Weeks of Learning </span>
          </div>

          <div className="text-black-blue-tint">
            <span className="text-sm">Live books sessions with <br/> quiz and homework.</span>
          </div>
        </div>

        <div className="">
          <img
          className="md:-ml-8 mx-auto" width="80" height="80" src={chat} />

          <div className="-mt-10">
            <span className="text-info-blue text-lg font-bold"> For everyone! </span>
          </div>

          <div className="text-black-blue-tint">
            <span className="text-sm">For newbies and <br/> experienced people!</span>
          </div>
        </div>
      </div>

      {/* upcoming event */}
      <div className=" mx-4 md:ml-20 pb-20 md:flex ">
        <div className="mr-10">
          <div>
            <span className="text-info-blue font-bold text-lg "> Upcoming New Event Oct. 2022</span>
          </div>
          <div className="border bg-info-blue h-2 w-20 mt-2 rounded-full"></div>

          <div className="mt-5">
            <span className="text-black-blue-tint text-3xl font-bold">
              Our first Women in <br/> Quantum Initiative
            </span>
          </div>


          <div style={{'width': "610px"}}>
            <p className="">
            We all know how important “Quantum Computation and Quantum Information” by Isaac Chuang is, Michael Nielsen. It is considered as the Bible of Quantum Computing.
            </p>
            <p className="mt-4"> 
              To set the basic building of Quantum Computing for newbies and experienced people, we will be conducting 12 weeks longs live books sessions with quiz and homework.
              </p>
              <p className="mt-4">
              If this book was in your list from a long time, go ahead and signup to Quantumgrad to receive updates!
              </p>
          </div>

          <div className="flex space-x-6 mt-5">
            <button
            className="px-6 py-2 border border-info-blue text-info-blue rounded-full"
            >
              Registeration
            </button>

            <div className="space-x-2 flex">
              <svg className="my-auto" width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M17 0.375C7.81914 0.375 0.375 7.81914 0.375 17C0.375 26.1809 7.81914 33.625 17 33.625C26.1809 33.625 33.625 26.1809 33.625 17C33.625 7.81914 26.1809 0.375 17 0.375ZM22.3475 17.2561L14.2428 23.1527C14.1983 23.1847 14.146 23.2037 14.0914 23.2078C14.0368 23.2119 13.9822 23.2009 13.9335 23.176C13.8848 23.151 13.8439 23.1132 13.8153 23.0665C13.7867 23.0199 13.7716 22.9662 13.7715 22.9115V11.1256C13.7713 11.0708 13.7863 11.017 13.8148 10.9701C13.8434 10.9233 13.8843 10.8853 13.9331 10.8603C13.9819 10.8354 14.0367 10.8244 14.0913 10.8286C14.146 10.8329 14.1984 10.8522 14.2428 10.8844L22.3475 16.7773C22.3857 16.8044 22.417 16.8403 22.4385 16.8819C22.4601 16.9236 22.4713 16.9698 22.4713 17.0167C22.4713 17.0636 22.4601 17.1098 22.4385 17.1515C22.417 17.1931 22.3857 17.229 22.3475 17.2561Z" fill="#00008B"/>
              </svg>

              <button className="text-info-blue">
                Watch Video
              </button>
            </div>

          </div>
        </div>

        <div className="bg-light-blue-grad mt-10  rounded-lg w-full flex ">

          <div className="-ml-12 mt-10">
            <img className="" src={bookname} />
          </div>

          <div className="my-auto">
            <div>
              <span className="font-bold text-black-blue-tint">Quantum Computation  and <br/> Quantum Information 
              </span>
            </div>

            <div className="text-gray-500 mt-5">
              by Isaac Chuang
            </div>
          </div>

        </div>



      </div>

      <JoinCommunity />

      <div className="md:mx-20">
        <Contribute />
      </div>

    </div>
  )
}

export default BookChapter;
