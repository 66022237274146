import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { http } from '../../settings';
import InfiniteScroll from 'react-infinite-scroll-component';


const Dashboard = (props) => {
    const [articles, setArticles] = useState([]);
    const [delete_pop, setDelatePop] = useState(false);
    const [delete_id, setDeleteId] = useState(null);
    const [page, setPage] = useState(1);
    const [size, setSize] = useState(12);
    const [total, setTotal] = useState(1);
    const [remove_article, setRemoveArticle] = useState(null);

    const [fetching, setFetching] = useState(false)
    const [has_more, setHasMore] = useState(false)

    const fetchData = (initial) => {
        if (!fetching) {
            const url = `/api/articles/user/articles?page=${initial?initial:page+1}&size=${size}`
            setFetching(true);
            http.get(url).then(res => {
                setArticles([...articles, ...res.data.items])
                setPage(res.data.page)
                setSize(res.data.size)
                setTotal(res.data.total)
                if (res.data.page * res.data.size < res.data.total) {
                    setHasMore(true)
                } else {
                    setHasMore(false)
                }
                setFetching(false);
            })
        }
    }

    useEffect(() => {
        fetchData(1)
    }, [])
    const deleteArtcle = () => {
        setDelatePop(false);
        http.delete(`/api/articles/article/${delete_id}`).then(res => {
            console.log(res.data)
        })
    }
    return (
        <div className="m-auto max-w-screen-2xl mx-5 lg:mx-auto">
            <div className="border-b border-gray pt-10 pb-4 space-x-20 mx-8 ">
                <h3 className="font-bold text-xl">My Articles</h3>
            </div>
            {
                delete_pop === true ? <div
                    className="fixed  inset-0 z-1000 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full"
                    id="my-modal"
                >
                    <div className="relative top-80 z-10 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
                        <p className="py-4 text-center">Are you sure you want to delete this article? </p>
                        <div className="flex justify-between items-center -auto max-w-screen-2xl mx-5">
                            <button className="rounded-full px-10 border-info-blue border"
                                onClick={() => setDelatePop(false)}>cancel</button>
                            <button className="rounded-full px-10 bg-info-blue text-white"
                                onClick={() => deleteArtcle()}>yes</button>
                        </div>
                    </div>
                </div> : ""
            }
            <div className="hidden md:grid grid-cols-7 py-10 ">
                <div className="col-span-3"></div>
                <div className="col-span-1">Saves</div>
                <div className="col-span-1">Likes</div>
                <div className="col-span-1">Comments</div>
                <div className="col-span-1">Status</div>
            </div>
            <InfiniteScroll
                key={has_more}
                className=""
                dataLength={articles.length} //This is important field to render the next data
                next={fetchData}
                hasMore={has_more}
                loader={<h4 className={'lg:col-span-3 col-span-2 md:col-spa-3 xl:col-span-4'}>Loading...</h4>}
                endMessage={
                    <p style={{ textAlign: 'center' }}>
                        <b>You have seen  all</b>
                    </p>
                }
                refreshFunction={fetchData}
            >
                {
                    articles.map(article =>
                        <div key={article.id} className="grid grid-cols-3 md:grid-cols-7 py-8 border-b border-black justify-center items-center px-8">
                            <div className="col-span-3 font-bold w-10/12">
                                <span className='font-normal'>{new Date(article.created_at).toDateString()}</span><br />
                                {article.title}
                                <span className="text-sm font-normal px-5">{article.read_time} read</span>
                                <br />
                                <div className="flex justify-start items-center font-normal space-x-4 pt-2">
                                    <Link to={`/article/${article.id}`} className={`underline ${!article.is_approved ? "text-gray-500" : "text-info-blue"}`}>
                                        View Article</Link>
                                    <Link to={`/edit-article/${article.id}`} className={`underline ${!article.is_approved ? "text-gray-500" : "text-green-500"}`}>Edit Article</Link>
                                    <button onClick={() => {
                                        setDelatePop(true);
                                        setDeleteId(article.id);

                                    }} className={`underline ${!article.is_approved ? "text-gray-500"
                                        : "text-red-500"}`}>Delete Article</button>
                                </div>
                            </div>
                            <div className="md:col-span-1"><span className="inline md:hidden">Saved : </span>{article.saves}</div>
                            <div className="md:col-span-1"><span className="inline md:hidden">Likes : </span>{article.number_of_likes}</div>
                            <div className="md:col-span-1"><span className="inline md:hidden">Comments : </span>{article.number_of_comments}</div>
                            <div className="col-span-2 md:col-span-1">
                            <span className="inline md:hidden">Status : </span>
                                <span
                                    className={`${article.is_approved ?
                                        "md:bg-green-500" :
                                        "md:bg-orange-400"} 
                                     md:rounded-full md:text-white md:py-0.5 ${!article.is_published?'md:px-9':article.is_approved ? "md:px-6" : "md:px-7"}`}>
                                    {!article.is_published?'Draft':article.is_approved ? "Published" : "Pending"}
                                </span>
                            </div>
                        </div>
                    )
                }

            </InfiniteScroll>

        </div>);
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
