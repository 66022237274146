import React, { useState } from 'react';
import { connect } from 'react-redux';
import avatar from "../../assets/avatar.jpg"
import EditProfile from './EditProfile';
import ChangePassword from './ChangePassword';
import { http } from '../../settings';
import * as auth from "../../store/actions/Auth";
import * as general from "../../store/actions/General";

const Profile = (props) => {
    const [edit_open, setEditOpne] = useState(false);
    const [change_pss_open, setChangePssOpen] = useState(false);
    const uploadProfileImage = (e) => {
        const profImage = e.target.files[0]
        let data = new FormData();
        data.append('fileobject', profImage);
        let config = {
            method: 'post',
            url: '/api/file/uploadProfile?photo_type=profile',
            data: data
        };

        http(config)
            .then(res => {
                let user = res.data.data;
                props.userSuccess(user)
                props.setAlert({ show: true, error: false, message: 'profile image updated' })

            })
            .catch(error => {
                props.setAlert({ show: true, error: false, message: 'ops! Error updating profile image' })

            });
    }
    const uploadBannerImage = (e) => {
        const banner = e.target.files[0]
        let data = new FormData();
        data.append('fileobject', banner);
        let config = {
            method: 'post',
            url: '/api/file/uploadProfile?photo_type=cover',
            data: data
        };

        http(config)
            .then(res => {
                let user = res.data.data;
                props.userSuccess(user)
                props.setAlert({ show: true, error: false, message: 'cover image updated' })
            })
            .catch(error => {
                props.setAlert({ show: true, error: true, message: 'ops! Error updating cover image' })
            });
    }
    const subscribeEmail = (e) => {
        e.preventDefault()
        let data = {
            email: props.user.email
        }
        http.post(`/api/mails/add-email/`, data).then(res => {
            props.setAlert({ show: true, error: !res.data.status, message: res.data.message });
            if (!res.data.status) {
                props.emailSubscribed(false)
            } else {
                props.emailSubscribed(true)
            }
        })
    }
    return (
        <div className="m-auto max-w-screen-2xl mx-5 lg:mx-auto">
            <div className="border-b border-gray pt-10 pb-4 space-x-20 md:mx-8 ">
                <h3 className="font-bold text-xl">Profile</h3>
            </div>
            <div className="mx-2 md:mx-5 py-10">
                <div className='flex flex-col lg:flex-row lg:justify-between lg:items-center mx-2 md:mx-5 py-10 border-2 rounded-xl'>
                    <div className='flex flex-col md:flex-row lg:flex-col flex-1 mx-2 md:mx-5 md:justify-between md:items-center'>
                        <div className="bg-gray-200 rounded-full mr-5 h-32 w-32 p-0.5">
                            <img src={props.user.avatar ? props.user.avatar : avatar} className='rounded-full object-fill h-full w-full' />
                        </div>
                        <p className='text-sm py-5 hidden lg:inline'>photo</p>
                        <div className='flex flex-col py-2'>
                        <input type='file' className="hidden" id="prof-image"
                            onChange={uploadProfileImage} accept="image/*" />
                        <label htmlFor='prof-image'
                            className=' text-orange-400 font-semibold underline'>Upload profile photo</label>
                        <br />
                        <input type='file' className="hidden"
                            onChange={uploadBannerImage} id="cover-image" accept="image/*" />
                        <label htmlFor='cover-image'
                            className=' text-orange-400 font-semibold underline'>Upload cover image</label>
                        </div>
                    </div>
                    <div className="flex-1 flex px-1 md:px-10 lg:px-0 flex-col py-2 md:py-10 lg:py-0">
                        <h6 className="pb-5 ">Name</h6>
                        <h3 className="font-bold">{props.user.first_name} {props.user.last_name}</h3>
                        <small className="text-gray-600 text-sm">This is the name that will appear when a user clicks on your profile</small>
                        <h6 className="pb-5 pt-10">Email Address</h6>
                        <h3 className="font-bold">{props.user.email}</h3>
                    </div>
                    <div className="flex-1 px-1 py-2 md:px-10">
                        <h6 className="pb-5 ">Bio</h6>
                        <h3 className="font-bold">
                            {props.user.bio}
                        </h3>
                        <h6 className="pb-5 pt-10">username</h6>
                        <h3 className="font-bold">{props.user.username}</h3>
                    </div>
                </div>
                <div className="flex flex-col lg:flex-row lg:justify-between lg:items-center">
                    <div className="px-2 py-2">
                        <input defaultChecked={props.email_subscribed ? props.email_subscribed : false}
                            type="checkbox" id="newsLetter" onClick={subscribeEmail} />
                        <label className="p-2 font-bold" htmlFor="newsLetter">Receive Newsletters from publications. </label>
                    </div>
                    <div className="flex space-x-1 md:space-x-10 my-10">
                        <button className="rounded-full px-2 md:px-4 border border-info-blue " onClick={() => setChangePssOpen(true)}>Change Password</button>
                        <button className="rounded-full px-2 md:px-4 border border-info-blue" onClick={() => setEditOpne(true)}>Edit Details</button>
                    </div>
                </div>
            </div>
            {edit_open ? <EditProfile setEditOpne={setEditOpne} /> : ""}
            {change_pss_open ? <ChangePassword setChangePssOpen={setChangePssOpen} /> : ""}
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        token: state.auth.token,
        user: state.auth.user,
        email_subscribed: state.auth.email_subscribed
    }
};

const mapDispatchToProps = dispatch => {
    return {
        userSuccess: (data) => dispatch(auth.userSuccess(data)),
        emailSubscribed: (data) => dispatch(auth.emailSubscribed(data)),
        setAlert: (data) => dispatch(general.setAlert(data))

    }
};


export default connect(mapStateToProps, mapDispatchToProps)(Profile);
