import React,  { useState } from 'react'

const Actions = (props) => {
    const { action, showAction } = props
    const [ report_pop, setReportPop ] = useState(false)
    return (
        <>
            {
                report_pop === true ? <div
                    className="fixed  inset-0 z-1000 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full"
                    id="my-modal"
                >
                    <div className="relative top-80 z-1000 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
                        <p className="py-4 text-center">Are you sure you want to report this post?</p>
                        <div className="flex justify-between items-center -auto max-w-screen-2xl mx-5">
                            <button className="rounded-full px-10 border-info-blue border"
                                onClick={() => setReportPop(false)}>cancel</button>
                            <button className="rounded-full px-10 bg-info-blue text-white"
                                onClick={() => {
                                    setReportPop(false)
                                    props.reportArticle()
                                }}>yes</button>
                        </div>
                    </div>
                </div> : ""}
            
            <div className={`${showAction ? '' : 'hidden'} absolute rounded-l-lg md:rounded-r-lg  rounded-b-lg bg-gray-500 text-sm space-y-1 top-5 right-1 mt-4 lg:inset-x-6 lg:-right-10 p-2 w-40`}>
                <button onClick={()=>setReportPop(true)}>Report this post</button>
            </div>

        </>

    )
}

export default Actions
