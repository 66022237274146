import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { http } from "../settings";
import JobList from "./sub/JobList";
import InfiniteScroll from "react-infinite-scroll-component";
import { useLocation, useNavigate } from "react-router-dom";
import * as queryString from "querystring";

const Jobs = (props) => {
  let { search } = useLocation();
  let params = queryString.parse(search);
  let navigate = useNavigate();
  const [jobs, setJobs] = useState([]);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(12);
  const [total, setTotal] = useState(1);
  const [fetching, setFetching] = useState(false);
  const [has_more, setHasMore] = useState(false);
  const [sort, setSort] = useState("descending");
  const [category, sortCategory] = useState("");
  const [loading, setLoading] = useState(false);

  const fetchData = (initial, sort_by, category_sort) => {
    if (!fetching) {
      if (!jobs.length) {
        setLoading(true);
      }
      let url = `/api/Jobs/?page=${initial ? 1 : page + 1}&size=${size}`;
      if (sort) {
        url += `&sort=${sort_by ? sort_by : sort}`;
      }
      if (category_sort) {
        url = url + `&category=${category_sort ? category_sort : category}`;
      }
      setFetching(true);
      setFetching(true);
      http.get(url).then((res) => {
        setJobs([...jobs, ...res.data.items]);
        setPage(res.data.page);
        setSize(res.data.size);
        setTotal(res.data.total);
        setFetching(false);
        setHasMore(res.data.page * res.data.size < res.data.total);
        setLoading(false);
      });
    }
  };

  useEffect(() => {
    let sort = params && params["?sort"] ? params["?sort"] : "descending";
    let category = params && params["?category"] ? params["?category"] : "";
    setSort(sort);
    fetchData(true, sort, category);
  }, []);
  const changeSort = (e) => {
    let category = e.target.value.split(":")[1];
    let sort = e.target.value.split(":")[0];
    fetchData(true, sort, category);
    setSort(sort);
    sortCategory(category);
    setJobs([...jobs.splice(0, jobs.length)]);
    setPage(1);
    fetchData(true, e.target.value.split(":")[0], e.target.value.split(":")[1]);
    navigate(`/jobs?sort=${sort}&category=${category ? category : ""}`);
  };

  return (
    <div>
      <div
        className={
          "bg-articlesBanner py-20 bg-no-repeat bg-center bg-cover flex flex-col justify-center items-center "
        }
      >
        <h3 className={"text-white font-bold text-3xl"}>Latest Jobs</h3>
        <p className="text-white space-x-3">
          <Link to="/">Home</Link>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6 inline"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M9 5l7 7-7 7"
            />
          </svg>
          <Link to={""}>Jobs</Link>
        </p>
      </div>
      <div className="m-auto max-w-screen-2xl mx-5 lg:mx-auto ">
        <div className="flex justify-between items-center py-2 mx-5 px-5">
          <span></span>
          <select className="rounded" onChange={changeSort}>
            <option value={"descending"}>sort by</option>
            <option value={"ascending"}>All jobs date posted &#8593;</option>
            <option value={"descending"}>All jobs date posted &#8595;</option>
            <option value={"ascending:FULL_TIME"}>
              Full time date posted &#8593;
            </option>
            <option value={"descending:FULL_TIME"}>
              Full time date posted &#8595;
            </option>
            <option value={"ascending:INTERN"}>
              Internship date posted &#8593;
            </option>
            <option value={"descending:INTERN"}>
              Internship date posted &#8595;
            </option>
          </select>
        </div>
        {loading ? (
          <div className="text-center justify-center flex items-center p-20">
            <svg className="h-16 w-16 animate-spin" viewBox="0 0 24 24">
              <path
                fill="currentColor"
                d="M12 19C13.1 19 14 19.9 14 21S13.1 23 12 23 10 22.1 10 21 10.9 19 12 19M12 1C13.1 1 14 1.9 14 3S13.1 5 12 5 10 4.1 10 3 10.9 1 12 1M6 16C7.1 16 8 16.9 8 18S7.1 20 6 20 4 19.1 4 18 4.9 16 6 16M3 10C4.1 10 5 10.9 5 12S4.1 14 3 14 1 13.1 1 12 1.9 10 3 10M6 4C7.1 4 8 4.9 8 6S7.1 8 6 8 4 7.1 4 6 4.9 4 6 4M18 16C19.1 16 20 16.9 20 18S19.1 20 18 20 16 19.1 16 18 16.9 16 18 16M21 10C22.1 10 23 10.9 23 12S22.1 14 21 14 19 13.1 19 12 19.9 10 21 10M18 4C19.1 4 20 4.9 20 6S19.1 8 18 8 16 7.1 16 6 16.9 4 18 4Z"
              />
            </svg>
          </div>
        ) : (
          <InfiniteScroll
            className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 xl:grid-cols-4 mx-5 py-10"
            dataLength={jobs.length} //This is important field to render the next data
            next={fetchData}
            hasMore={has_more}
            loader={
              <h4
                className={
                  "lg:col-span-3 col-span-2 md:col-spa-3 xl:col-span-4 text-center"
                }
              >
                Loading...
              </h4>
            }
            endMessage={
              <p
                style={{ textAlign: "center" }}
                className={
                  "lg:col-span-3 col-span-2 md:col-spa-3 xl:col-span-4 text-center"
                }
              >
                <b>You have seen all</b>
              </p>
            }
            refreshFunction={fetchData}
          >
            {jobs.map((job, i) => (
              <JobList job={job} key={job.id} />
            ))}
          </InfiniteScroll>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Jobs);
