import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import whoWeAre from '../assets/who-we_are.png'
import { http } from '../settings'
import SocialMedia from './sub/SocialMedia'
const About = (props) => {
    const [active_users, setActiveUsers] = useState(0)
    const [published_articles, setPublishedArticles] = useState(0)
    const [monthly_visitors, setMonthlyVisitors] = useState(0)
    useEffect(() => {
        http.get(`/api/auth/facts`).then(res => {
            setActiveUsers(res.data.data.active_users)
            setPublishedArticles(res.data.data.published_articles)
            setMonthlyVisitors(res.data.data.monthly_visitors ?
                res.data.data.monthly_visitors :
                res.data.data.active_users)
        })
    })
    return (
        <div className="m-auto max-w-screen-2xl mx-5 lg:mx-auto px-8 py-10">
            <div className='flex flex-col w-full'>
                <h1 className="font-black text-4xl text-center">About QuantumGrad </h1>
                <div className="grid  grid-cols-1 md:grid-cols-3 py-20 justify-between items-cente w-full">
                    <div className="prose col-span-2 ">
                        <p>
                            As we know, Knowledge shared = knowledge<sup>3</sup>.
                            There is a growing interest in the quantum computing field, and many people either want to learn quantum computing or want to help other folks learn Quantum computing. In this era where there is a ton of scattered information and resources about Quantum computing, we made a one-stop solution platform solely for Quantum computing where a quantum enthusiast can find all the information about quantum computing like articles, news, internships, full-time jobs, events, publications etc.

                        </p>
                        <p>

                            Here, we invited all the quantum learners and quantum experts to learn and share Knowledge among Quantum enthusiasts to make quantum computing accessible to everyone. Quantumgrad is for everyone students, professors, researchers etc.
                        </p>
                        <p>

                            Quantum Grad was founded in February of 2022.
                        </p>
                    </div>
                    <div className="item-left w-full">
                        <img src={whoWeAre} className="w-full min-w-full object-fill" />
                    </div>
                </div>
                <div className="flex flex-col justify-center items-center">
                    <h4 className="font-bold text-lg text-center">Follow us on:</h4>
                    <SocialMedia />
                </div>
                <div className="flex flex-col py-16 p-10 w-full m-auto lg:max-w-5xl about_count text-white rounded-2xl">
                    <div className="text-2xl font-semibold underline text-center pb-10">Facts</div>
                    <div className="flex flex-col md:flex-row justify-center items-center  text-xl space-y-6 md:space-y-0">
                        <div className="flex flex-col justify-center items-center flex-1 text ">
                            <b>Active Users</b>
                            <b className="text-3xl">{active_users}</b>

                        </div>
                        <div className="flex flex-col justify-center items-center flex-1">
                            <b>Monthly Visitors</b>
                            <b className="text-3xl">{monthly_visitors}</b>
                        </div>
                        <div className="flex flex-col justify-center items-center flex-1">
                            <b>Published Articles</b>
                            <b className="text-3xl">{published_articles}</b>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(About)
