import React from 'react';
import { Link } from "react-router-dom";
import large_logo from "../../assets/logo_main.png"
const Auth = ({ children }) => {
    return (
        <div className="h-screen">
            <div className="bg-light-purple flex m-auto h-full w-full justify-center items-center md:pt-40 lg:pt-0">
                <div className="bg-light-purple h-full w-full flex justify-center items-center">
                    <div className="sm:max-w-6xl
             my-10 m-auto w-full flex justify-between items-center
             bg-white rounded-lg shadow-lg relative">
                        <div
                            className="hidden lg:flex flex-col justify-between items-start bg-purple-900 flex-1 py-20 bg-opacity-30 rounded-l-lg">
                            <div className={"flex  flex-col justify-between items-center w-full pb-8"}>
                                <div className={"flex justify-center items-center w-full"}>
                                    <Link to={'/'} className="font-bold text-3xl">
                                        <img src={large_logo} alt="logo" className="h-24 py-5" />
                                    </Link>
                                </div>
                                <div className={"w-full flex justify-center items-center"}>
                                    <p className={"font-black text-center w-1/2"}>
                                        Discover everything about Quantum
                                        Computing Here!</p>
                                </div>


                            </div>

                            <div className={"flex justify-center items-center w-full flex-col space-y-5 pt-8 "}>

                                <img src={'/assets/auth-lustration.png'} alt="auth" />

                            </div>
                        </div>
                        {children}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Auth;