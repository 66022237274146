import React, { useState, useEffect } from "react";
import ArticleList from "./sub/ArticleList";
import { http } from "../settings";
import avatar from "../assets/avatar.jpg";
import RemoveFromSaved from "./sub/article/RemoveFromSaved";
import FollowerProfile from "./FollowerProfile";
import * as auth from "../store/actions/Auth";
import * as general from "../store/actions/General";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";

import { LinkedinShareButton, TwitterShareButton } from "react-share";

const WriterProfile = (props) => {
  const url_params = useParams();

  const [followers, setFollowers] = useState([]);
  const [follower_count, setFollowerCount] = useState(0);
  const [follower_list_open, setFollowerListModalOpen] = useState(false);
  const [author, setAuthor] = useState({});
  const [articles, setArticle] = useState([]);
  const [pop_remove_saved, popupRemoveFromSaved] = useState(false);
  const [remove_article, setRemoveArticle] = useState(null);

  useEffect(() => {
    let id = url_params.id;
    http.get(`/api/auth/authors/${id}`).then((res) => {
      setAuthor(res.data.user);
      setArticle(res.data.articles);
    });
    http.get(`/api/auth/author/${id}/followers`).then((res) => {
      if (res.data.data && res.data.data.length > 0) {
        setFollowerCount(res.data.data.length);
        setFollowers(res.data.data);
      }
    });
  }, []);
  const isLiked = () => {
    return props.articles_liked.find((sa) => sa.article_id === remove_article);
  };
  const saveArticle = (id) => {
    setRemoveArticle(id);
    popupRemoveFromSaved(false);
    http.post(`/api/articles/saved?article_id=${id}`).then((res) => {
      let liked_a = [...props.articles_saved];
      console.log(res.data);
      if (res.data.message.indexOf("unsaved") > 0) {
        let c_article = isLiked();
        let ca_ind = liked_a.indexOf(c_article);
        console.log(liked_a);
        liked_a.splice(ca_ind, 1);
        console.log(liked_a);
        props.updateSavedArticles(liked_a);
        props.setAlert({ show: true, error: false, message: res.data.message });
      } else {
        liked_a.push({ article_id: parseInt(id) });
        props.updateSavedArticles(liked_a);
        props.setAlert({ show: true, error: false, message: res.data.message });
      }
    });
  };
  const isFollowing = () => {
    return props.authors_following.find((uf) => {
      return uf === author.id;
    });
  };
  const followAuthor = () => {
    http
      .post(`/api/auth/follow?follow_id=${author.id}`)
      .then((res) => {
        const following = isFollowing();
        let my_followings = [...props.authors_following];
        if (res.data.status) {
          if (following) {
            let this_index = my_followings.indexOf(author.id);
            my_followings.splice(this_index, 1);
            console.log(my_followings);
            props.authorsFollowing(my_followings);
          } else {
            my_followings.push(author.id);
            props.authorsFollowing(my_followings);
          }
          http.get(`/api/auth/author/${author.id}/followers`).then((res) => {
            if (res.data.data && res.data.data.length > 0) {
              setFollowerCount(res.data.data.length);
              setFollowers(res.data.data);
            }
            if (res.data.data && res.data.data.length === 0) {
              setFollowerCount(0);
              setFollowers([]);
            }
          });
        } else {
          props.setAlert({
            show: true,
            error: true,
            message: res.data.message,
          });
        }
      })
      .catch((err) => {
        console.log(err);
        props.setAlert({
          show: true,
          error: true,
          message: err.response.data.detail,
        });
      });
  };
  const bgStyle = (cover_photo) => {
    if (cover_photo) {
      return {
        backgroundImage: `url(${author.cover_photo ? author.cover_photo : ""})`,
      };
    } else {
      return {};
    }
  };

  return (
    <div>
      <div
        className={`${
          author.cover_photo ? "" : "bg-writerBanner"
        } px-10 py-32 bg-no-repeat bg-center bg-cover`}
        style={bgStyle(author.cover_photo)}
      >
        <div className="flex justify-center items-center flex-col">
          <div className={"p-5"}>
            <img
              src={author.avatar ? author.avatar : avatar}
              className={"rounded-full h-24"}
              alt={"user"}
            />
          </div>
          <div
            className={
              "flex pt-3 text-white justift-between items-center space-x-4"
            }
          >
            <h3 className={" font-bold text-3xl text-center"}>
              {author.first_name} {author.last_name}
            </h3>
            <span>{articles.length} posts</span>
          </div>
          <div className={" flex text-white justift-between items pb-2"}>
            <span>{author.email}</span>
          </div>
          <div className={" flex justift-center items-center space-x-4"}>
            <button
              onClick={followAuthor}
              className="bg-info-blue text-white rounded-full px-4"
            >
              {isFollowing() ? "Following" : "Follow"}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6 inline pl-2"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M18 9v3m0 0v3m0-3h3m-3 0h-3m-2-5a4 4 0 11-8 0 4 4 0 018 0zM3 20a6 6 0 0112 0v1H3v-1z"
                />
              </svg>
            </button>
            <button
              className="bg-info-blue text-white rounded-full px-4"
              onClick={() => setFollowerListModalOpen(true)}
            >
              Followers: {follower_count}
            </button>
            <TwitterShareButton
              title={`See ${author.first_name} ${author.last_name}'s profile`}
              url={window.location.href}
            >
              <span className="flex justify-between text-white items-center rounded-full p-1 border">
                <svg className="h-6 w-6 inline" viewBox="0 0 24 24">
                  <path
                    fill="currentColor"
                    d="M22.46,6C21.69,6.35 20.86,6.58 20,6.69C20.88,6.16 21.56,5.32 21.88,4.31C21.05,4.81 20.13,5.16 19.16,5.36C18.37,4.5 17.26,4 16,4C13.65,4 11.73,5.92 11.73,8.29C11.73,8.63 11.77,8.96 11.84,9.27C8.28,9.09 5.11,7.38 3,4.79C2.63,5.42 2.42,6.16 2.42,6.94C2.42,8.43 3.17,9.75 4.33,10.5C3.62,10.5 2.96,10.3 2.38,10C2.38,10 2.38,10 2.38,10.03C2.38,12.11 3.86,13.85 5.82,14.24C5.46,14.34 5.08,14.39 4.69,14.39C4.42,14.39 4.15,14.36 3.89,14.31C4.43,16 6,17.26 7.89,17.29C6.43,18.45 4.58,19.13 2.56,19.13C2.22,19.13 1.88,19.11 1.54,19.07C3.44,20.29 5.7,21 8.12,21C16,21 20.33,14.46 20.33,8.79C20.33,8.6 20.33,8.42 20.32,8.23C21.16,7.63 21.88,6.87 22.46,6Z"
                  />
                </svg>
              </span>
            </TwitterShareButton>
            <LinkedinShareButton
              title={`See ${author.first_name} ${author.last_name}'s profile`}
              url={window.location.href}
              className="flex justify-between items-center text-info-blue  rounded-full p-2 border border-info-blue"
            >
              <span className="flex justify-between text-white items-center rounded-full p-1 border">
                <svg className="h-6 w-6 inline" viewBox="0 0 24 24">
                  <path
                    fill="currentColor"
                    d="M19 3A2 2 0 0 1 21 5V19A2 2 0 0 1 19 21H5A2 2 0 0 1 3 19V5A2 2 0 0 1 5 3H19M18.5 18.5V13.2A3.26 3.26 0 0 0 15.24 9.94C14.39 9.94 13.4 10.46 12.92 11.24V10.13H10.13V18.5H12.92V13.57C12.92 12.8 13.54 12.17 14.31 12.17A1.4 1.4 0 0 1 15.71 13.57V18.5H18.5M6.88 8.56A1.68 1.68 0 0 0 8.56 6.88C8.56 5.95 7.81 5.19 6.88 5.19A1.69 1.69 0 0 0 5.19 6.88C5.19 7.81 5.95 8.56 6.88 8.56M8.27 18.5V10.13H5.5V18.5H8.27Z"
                  />
                </svg>
              </span>
            </LinkedinShareButton>
          </div>
          <div></div>
          <p className={"text-white xl:w-1/4 lg:1/3 md:1/2 text-center"}>
            {author.bio}
          </p>
        </div>
      </div>
      <div className={"m-auto max-w-screen-2xl mx-5 lg:mx-auto"}>
        <div className="pt-16">
          <h3 className={"font-bold text-2xl"}>Most Popular Articles</h3>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4 py-10">
          {pop_remove_saved ? (
            <RemoveFromSaved
              remove_article={remove_article}
              popupRemoveFromSaved={popupRemoveFromSaved}
              saveArticle={saveArticle}
            />
          ) : (
            ""
          )}

          {articles.map((article) => (
            <ArticleList
              saveArticle={saveArticle}
              key={article.id}
              article={{ ...article, ...{ created_by: author } }}
              popupRemoveFromSaved={popupRemoveFromSaved}
              setRemoveArticle={setRemoveArticle}
            />
          ))}
        </div>
      </div>
      {follower_list_open ? (
        <FollowerProfile
          authors_following={props.authors_following}
          followers={followers}
          setFollowerListModalOpen={setFollowerListModalOpen}
        />
      ) : (
        ""
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    articles_saved: state.auth.articles_saved,
    authors_following: state.auth.authors_following,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateSavedArticles: (liked_articles) =>
      dispatch(auth.articlesSaved(liked_articles)),
    authorsFollowing: (following) => dispatch(auth.authorsFollowing(following)),
    setAlert: (data) => dispatch(general.setAlert(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(WriterProfile);
