import React, {useEffect, useState} from 'react';
import {http} from "../../settings";

import {
    Link,
    useParams
} from "react-router-dom";

const VerifyAccount = (props) => {
    const [success, setSuccess] = useState("")
    const [error, setError] = useState("")
    const url_params = useParams()
    useEffect(() => {
        setSuccess("")
        setError("")
        let token = url_params.token
        http.get(`/api/auth/verify-account?activationToken=${token}`).then(res => {
            setSuccess(res.data.message)
        }).catch(err => {
            console.log(err.response.data.detail.message)
            setError(err.response.data.detail.message)
        })
    }, [url_params.token])
    return (
        <div className={'place-content-center px-2 pt-16 lg:pt-0 w-full  lg:w-1/2'}>
            <div
                className={'place-content-center text-center flex flex-col justify-center flex-col items-center w-full'}>
                <div className="py-8 md:px-24 sm:max-w-2xl w-full my-5 m-auto flex flex-col justify-center  items-center">
                    <svg className={"w-16 h-16"} viewBox="0 0 69 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M32.0799 33.31L23.6299 24.75L26.4699 21.95L31.3999 26.95L41.6599 10.87L45.0199 13.02L32.0799 33.31Z"
                            fill="#00008B"/>
                        <path
                            d="M34.33 44.18C29.9606 44.182 25.6887 42.8881 22.0547 40.462C18.4207 38.0359 15.5878 34.5867 13.9144 30.5504C12.2409 26.5142 11.802 22.0723 12.6532 17.7866C13.5044 13.5009 15.6075 9.56389 18.6965 6.47355C21.7854 3.38321 25.7215 1.27835 30.0068 0.425188C34.2921 -0.427971 38.7342 0.00890064 42.7712 1.68055C46.8082 3.35219 50.2587 6.18352 52.6865 9.81641C55.1142 13.4493 56.41 17.7206 56.41 22.09C56.4047 27.9453 54.077 33.5594 49.9377 37.7006C45.7983 41.8419 40.1853 44.1721 34.33 44.18ZM34.33 3C30.5539 2.99802 26.8621 4.11595 23.7214 6.21238C20.5808 8.30882 18.1324 11.2896 16.686 14.7776C15.2396 18.2657 14.8601 22.1044 15.5956 25.8081C16.331 29.5119 18.1484 32.9143 20.8178 35.5851C23.4872 38.2559 26.8887 40.0751 30.592 40.8125C34.2954 41.5499 38.1343 41.1724 41.6231 39.7278C45.1119 38.2832 48.094 35.8364 50.1921 32.6969C52.2901 29.5573 53.41 25.8661 53.41 22.09C53.4047 17.0304 51.3931 12.1793 47.8164 8.60071C44.2396 5.02207 39.3896 3.00794 34.33 3Z"
                            fill="#00008B"/>
                        <path d="M6.56 21.59H0V22.59H6.56V21.59Z" fill="#00008B"/>
                        <path d="M4.2823 7.67627L3.5752 8.38338L8.21382 13.022L8.92092 12.3149L4.2823 7.67627Z"
                              fill="#00008B"/>
                        <path d="M8.22114 30.9436L3.58252 35.5822L4.28963 36.2893L8.92825 31.6507L8.22114 30.9436Z"
                              fill="#00008B"/>
                        <path d="M68.6498 21.59H62.0898V22.59H68.6498V21.59Z" fill="#00008B"/>
                        <path d="M64.3676 7.67223L59.729 12.3109L60.4361 13.018L65.0747 8.37934L64.3676 7.67223Z"
                              fill="#00008B"/>
                        <path d="M60.4337 30.9295L59.7266 31.6366L64.3652 36.2753L65.0723 35.5682L60.4337 30.9295Z"
                              fill="#00008B"/>
                    </svg>

                    <div className={'flex justify-between items-center'}>
                        <div className="py-8 md:px-24 sm:max-w-2xl w-full my-5 m-auto">
                            {success ? <h5 className="font-bold text-green-400">{success}</h5> : ""}
                            {error ? <h5 className="font-bold text-red-400">{error}</h5> : ""}
                        </div>

                    </div>
                    <div className="py-8 flex flex-col justify-center space-y-2 w-full">
                        <Link to={"/auth/login"}
                              className={'text-center bg-info-blue rounded-3xl text-white py-2.5 w-full'}>Sign In</Link>
                    </div>

                </div>
            </div>
        </div>

    );
};

export default VerifyAccount;