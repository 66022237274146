import React, { useState } from 'react';
import { Link } from "react-router-dom";
import SocialAuth from "./SocialAuth";
import * as auth from '../../store/actions/Auth'
import { connect } from "react-redux";
import PasswordStrengthBar from 'react-password-strength-bar';
import large_logo from "../../assets/logo_main.png"

const Register = props => {
    const [error, setError] = useState("")
    const [password, setPassword] = useState("")
    const registerHandle = (e) => {
        setError("")
        e.preventDefault()
        let password1 = e.target.elements.password1.value
        let password2 = e.target.elements.password2.value
        if (password1 === password2) {
            let data = {
                username: e.target.elements.username.value,
                email: e.target.elements.email.value,
                password: password1,
            }
            props.onSignup(data)
        } else {
            setError("the two password fields did not match")
        }
    }
    return (
        <div className={'place-content-center px-2 pt-16 lg:pt-0  w-full  lg:w-1/2'}>
            <div className={"lg:hidden flex justify-center items-center w-full"}>
                <Link to={'/'} className="font-bold text-3xl">
                    <img src={large_logo} alt="logo" className="h-24 py-5" />
                </Link>
            </div>
            <h6 className="text-center font-semibold text-xl">Create an Account</h6>
            <div className={'flex justify-between items-center'}>
                <div className="py-5 m-auto">
                    <form onSubmit={registerHandle} method={"POST"}>
                        {error ?
                            <div className="flex flex-col justify-between items-start py-2 w-full">

                                <span className="text-red-500 bg-red-200 py-2 px-5 rounded-md w-full">{error}</span>

                            </div>
                            : ""
                        } {props.signup_error ?
                            <div className="flex flex-col justify-between items-start py-2 w-full">

                                <span
                                    className="text-red-500 bg-red-200 py-2 px-5 rounded-md w-full">{props.signup_error}</span>

                            </div>
                            : ""
                        }
                        {props.register_message ?
                            <div className="flex flex-col justify-between items-start py-2 w-full">

                                <span
                                    className="text-green-500 bg-green-200 py-2 px-5 rounded-md w-full">{props.register_message}</span>

                            </div>
                            : ""
                        }
                        <div className="flex flex-col justify-between items-center space-y-5">
                            <input type="text" required={true}
                                className="px-8 rounded-3xl border-gray-300 bg-white w-full"
                                placeholder="Username" name={"username"} />
                            <input type="email" required={true}
                                className="px-8 rounded-3xl border-gray-300 bg-white w-full"
                                placeholder="Email" name={"email"} />
                            <input type="password" required={true} onChange={(e) => setPassword(e.target.value)} className="px-8 rounded-3xl border-gray-300
                         bg-white w-full" placeholder="Password" name={"password1"} />
                            <input type="password" required={true} className="px-8 rounded-3xl border-gray-300
                         bg-white w-full" placeholder="Confirm Password" name={"password2"} />
                            {
                                password.length ?
                                    <PasswordStrengthBar password={password} />
                                    :
                                    ""
                            }
                        </div>
                        <div className="py-8 flex flex-col justify-center space-y-2">
                            <button disabled={props.register_loading}
                                className={'text-center bg-info-blue rounded-3xl text-white py-2.5'}>
                                {
                                    props.register_loading ?
                                        <svg className={"animate-spin inline text-white h-5 w-5 mr-3"} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                                            xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                            width="26.349px" height="26.35px" viewBox="0 0 26.349 26.35"
                                            space="preserve" fill={"white"}>
                                            <g>
                                                <g>
                                                    <circle cx="13.792" cy="3.082" r="3.082" />
                                                    <circle cx="13.792" cy="24.501" r="1.849" />
                                                    <circle cx="6.219" cy="6.218" r="2.774" />
                                                    <circle cx="21.365" cy="21.363" r="1.541" />
                                                    <circle cx="3.082" cy="13.792" r="2.465" />
                                                    <circle cx="24.501" cy="13.791" r="1.232" />
                                                    <path d="M4.694,19.84c-0.843,0.843-0.843,2.207,0,3.05c0.842,0.843,2.208,0.843,3.05,0c0.843-0.843,0.843-2.207,0-3.05
			C6.902,18.996,5.537,18.988,4.694,19.84z"/>
                                                    <circle cx="21.364" cy="6.218" r="0.924" />
                                                </g>
                                            </g>
                                            <g>
                                            </g>
                                            <g>
                                            </g>
                                            <g>
                                            </g>
                                            <g>
                                            </g>
                                            <g>
                                            </g>
                                            <g>
                                            </g>
                                            <g>
                                            </g>
                                            <g>
                                            </g>
                                            <g>
                                            </g>
                                            <g>
                                            </g>
                                            <g>
                                            </g>
                                            <g>
                                            </g>
                                            <g>
                                            </g>
                                            <g>
                                            </g>
                                            <g>
                                            </g>
                                        </svg> :
                                        ""
                                }
                                {
                                    props.register_loading ?
                                        <span className={"animate-pulse"}>loading...</span>
                                        :
                                        "Register"
                                }

                            </button>
                            <p className={'text-gray-500'}>Already have an account?
                                <Link to={'/auth/login'}
                                    className={'text-info-blue'}> Sign in</Link>
                            </p>
                            <div className="flex flex-row  justify-between py-10">
                                <div className="border-b-2 w-full flex-1 bg-skin-tertiary h-[3px] my-2.5 mx-2" />
                                <span className={''}>Or Register With</span>
                                <div className="border-b-2 w-full flex-1 bg-skin-tertiary h-[3px] my-2.5 mx-2" />
                            </div>
                            <SocialAuth />
                        </div>

                    </form>

                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        register_loading: state.auth.register_loading,
        signup_error: state.auth.signup_error,
        register_message: state.auth.register_message
    }
};
const mapDispatchToProps = dispatch => {
    return {
        onSignup: (data) => dispatch(auth.authSignup(data))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Register);