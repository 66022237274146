import React from 'react'
import whiteimage from '../../assets/white.jpeg'


const NewsList = ({ news }) => {
    return (
        <a href={news.link} target="_blank" rel="noreferrer" className={"flex flex-col transition transform ease-in-out" +
            "delay-150 motion-reduce:transition-none motion-reduce:transform-none mx-4 my-8"
        }>
            <img src={news.image?news.image:whiteimage} className={'rounded-md h-48 w-full object-cover hover:bg-blue-700 hover:opacity-30'} alt={""} />
            <div className={"flex py-1 flex-col px-2 pb-4 pt-2"}>
                <span className={"text-gray-400 text-xs "}>
                    <span className='hover:text-blue-600'>{news.source}</span>
                    <span className='font-bold px-1'>&bull;</span>
                    <span>{new Date(news.date_posted).toDateString()}</span>
                    <span className='font-bold px-1'>&bull;</span>
                    <span>{new Date(news.date_posted).toLocaleTimeString()}</span>
                </span>
                <span rel="noreferrer"  className={"text-black font-semibold text-sm hover:text-blue-600 pt-2"}>
                    {news.title}
                </span>
                <p className={"text-xs line-clamp-3 pt-2"}>
                    {news.short_intro}
                </p>
            </div>
        </a>
    )
}
export default NewsList