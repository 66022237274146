import React from 'react'
import whiteimage from '../../assets/white.jpeg'

const BookList = (props) => {
  const { book } = props
  return (
    <div className={"flex flex-col transition transform ease-in-out" +
      "delay-150 motion-reduce:transition-none motion-reduce:transform-none mx-4 my-8"
    }>
      <a target="_blank" rel="noreferrer" href={book.link}>
        <img src={book.image?book.image:whiteimage} className={'rounded-md h-48 w-full object-cover hover:bg-blue-700 hover:opacity-30'} alt={""} />
      </a>
      <div className={"flex py-1 flex-col px-2 pb-4 pt-2 relative"}>
        <span className={"text-gray-400 text-xs flex justify-between  items-center"}>
          <div className="uppercase">
            <span  className='hover:text-blue-600'>
              {book.author}</span>
            <span className='font-bold px-1'>&bull;</span>
            <span>{new Date(book.created_at).toDateString()}</span>
          </div>

        </span>

        <span rel="noreferrer" href={book.link}
          className={"text-black font-semibold text-sm hover:text-blue-600 pt-2"}>
          {book.title}
        </span>
        <p className={"text-xs line-clamp-3 pt-2"}>
          {book.short_description}
        </p>
      </div>

    </div>
  )
}

export default BookList