import React from 'react'
import whiteimage from '../../assets/white.jpeg'


const VideoList = ({video}) => {
    const youTubeIdFromLink = (url) => url.match(/(?:https?:\/\/)?(?:www\.|m\.)?youtu(?:be)?\.(?:com|be)(?:\/watch\/?\?v=|\/embed\/|\/)([^\s&\?\/\#]+)/)[1];

    return (
        <div className={"flex flex-col transition transform ease-in-out" +
            "delay-150 motion-reduce:transition-none motion-reduce:transform-none mx-4 my-8"
        }>
            <iframe
                className={'rounded-md h-48 w-full object-cover'}
                src={`https://www.youtube.com/embed/${youTubeIdFromLink(video.link)}`}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="Embedded youtube"
            />
            <div className={"flex py-1 flex-col px-2 pb-4 pt-2"}>
                <span rel="noreferrer" className={"text-black font-semibold text-sm hover:text-blue-600"}>
                   {video.title}
                </span>
                <p className={"text-xs line-clamp-3 pt-2"}>
                    {video.short_info}
                </p>
            </div>
        </div>
    )
}


export default VideoList