import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import avatar from "../assets/avatar.jpg";
import { http } from "../settings";
import Actions from "./sub/article/Actions";
import ArticleList from "./sub/ArticleList";
import { connect } from "react-redux";
import RemoveFromSaved from "./sub/article/RemoveFromSaved";
import * as auth from "../store/actions/Auth";
import * as general from "../store/actions/General";
import { LinkedinShareButton, TwitterShareButton } from "react-share";

const ArticleDetails = (props) => {
  const [showAction1, setShowAction1] = useState(false);
  const [showAction2, setShowAction2] = useState(false);
  const [showComments, setshowComments] = useState(false);
  const [comment_text, setCommentText] = useState("");
  const [comment_reply_text, setCommentReplyText] = useState("");
  const [pop_remove_saved, popupRemoveFromSaved] = useState(false);
  const [remove_article, setRemoveArticle] = useState(null);
  const [article, setArticle] = useState({
    created_by: {},
    likes: [],
    comments: [],
  });
  const [related_articles, setRelatedArticles] = useState([]);
  const url_params = useParams();
  const [comments, setComments] = useState([]);
  const [commenting, setCommenting] = useState(false);
  const [replying, setReplying] = useState(false);
  const [reply_ind, setReply] = useState({ i: 0, show: false });
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
    let id = url_params.id;
    setLoading(true);
    http.get(`/api/articles/article/${id}`).then((res) => {
      setArticle(res.data.data.article);
      setRelatedArticles(res.data.data.related_articles);
      setComments([]);
      loadComments();
      setLoading(false);
    });
  }, [url_params.id]);
  const likeArticle = () => {
    const token_exists = localStorage.getItem("token");

    if (token_exists) {
      let data = {
        article_id: url_params.id,
      };
      http
        .post(`/api/articles/like_article/`, data)
        .then((res) => {
          let liked_a = [...props.articles_liked];
          if (res.data.detail.indexOf("removed") > 0) {
            let c_article = isLiked();
            let ca_ind = liked_a.indexOf(c_article);
            liked_a.splice(ca_ind, 1);
            props.updateLikeArticle(liked_a);
            let this_article = { ...article };

            let article_likes = this_article.likes;
            let user_like = article_likes.find((like) => {
              return like.user.id == props.user.id;
            });
            let like_ind = article_likes.indexOf(user_like);
            article_likes.splice(like_ind, 1);
            this_article.likes = article_likes;
            setArticle(this_article);
          } else {
            liked_a.push({ article_id: parseInt(url_params.id) });
            let this_article = { ...article };

            let article_likes = [...this_article.likes];
            article_likes.push(res.data.like);
            this_article.likes = article_likes;

            setArticle(this_article);
            props.updateLikeArticle(liked_a);
          }
        })
        .catch((err) => {
          props.setAlert({
            show: true,
            error: true,
            message: err.response.data.detail,
          });
        });
    } else {
      props.setAlert({
        show: true,
        error: true,
        message: "Please Login/Signup to like this post!",
      });
    }
  };
  const postComment = (e) => {
    const token_exists = localStorage.getItem("token");
    e.preventDefault();
    if (token_exists) {
      setCommenting(true);
      let data = {
        content: comment_text,
        article_id: url_params.id,
        parent_id: null,
      };
      http
        .post(`/api/articles/comment_article/`, data)
        .then((res) => {
          let comment = res.data.data;
          let up_comments = [...comments];
          up_comments.push(comment);
          setshowComments(!showComments);
          setComments(up_comments);
          setCommenting(false);
          setCommentText("");
          props.setAlert({
            show: true,
            error: !res.data.status,
            message: res.data.message,
          });
        })
        .catch((err) => {
          setCommenting(false);
          setCommentText("");
          props.setAlert({
            show: true,
            error: true,
            message: err.response.data.detail,
          });
        });
    } else {
      props.setAlert({
        show: true,
        error: true,
        message: "Please Login/Signup to comment on this this post!",
      });
    }
  };
  const followAuthor = () => {
    const token_exists = localStorage.getItem("token");
    if (token_exists) {
      http
        .post(`/api/auth/follow?follow_id=${article.created_by.id}`)
        .then((res) => {
          const following = isFollowing();
          if (res.data.status) {
            let my_followings = [...props.authors_following];
            if (following) {
              let this_index = my_followings.indexOf(article.created_by.id);
              my_followings.splice(this_index, 1);
              props.authorsFollowing(my_followings);
            } else {
              my_followings.push(article.created_by.id);
              props.authorsFollowing(my_followings);
            }
          } else {
            props.setAlert({
              show: true,
              error: true,
              message: res.data.message,
            });
          }
        })
        .catch((err) => {
          props.setAlert({
            show: true,
            error: true,
            message: err.response.data.detail,
          });
        });
    } else {
      props.setAlert({
        show: true,
        error: true,
        message: "Please Login/Signup to follow this author!",
      });
    }
  };
  const loadComments = () => {
    let state = showComments;
    setshowComments(!showComments);
    if (!state && !comments.length) {
      http
        .get(`/api/articles/comments/${url_params.id}`)
        .then((res) => {
          setComments(res.data.data.Comments);
        })
        .catch((err) => {
          props.setAlert({
            show: true,
            error: true,
            message: err.response.data.detail,
          });
        });
    }
  };
  const replyComment = (e) => {
    e.preventDefault();
    const token_exists = localStorage.getItem("token");
    if (token_exists) {
      let data = {
        content: comment_reply_text,
        article_id: url_params.id,
        parent_id: e.target.elements.comment.value,
      };
      setReplying(true);
      http
        .post(`/api/articles/comment_article/`, data)
        .then((res) => {
          setCommentReplyText("");
          let comment = res.data.data;
          let parent_comm = comments.filter((cm) => cm.id == data.parent_id);
          parent_comm = { ...parent_comm[0] };

          let ind = comments.indexOf(parent_comm);
          parent_comm.replies.push(comment);
          let coms = [...comments];
          coms[ind] = parent_comm;
          setComments(coms);
          setReplying(false);
        })
        .catch((err) => {
          setReplying(false);
          setCommentReplyText("");
          props.setAlert({
            show: true,
            error: true,
            message: err.response.data.detail,
          });
        });
    } else {
      props.setAlert({
        show: true,
        error: true,
        message: "Please Login/Signup to reply on this comment!",
      });
    }
  };
  const saveArticle = () => {
    const token_exists = localStorage.getItem("token");
    if (token_exists) {
      http
        .post(`/api/articles/saved?article_id=${article.id}`)
        .then((res) => {
          let saved_a = [...props.articles_saved];
          if (res.data.message.indexOf("unsaved") > 0) {
            let c_article = isInSaved();
            let ca_ind = saved_a.indexOf(c_article);
            saved_a.splice(ca_ind, 1);
            props.updateSavedArticles(saved_a);
          } else {
            saved_a.push({ article_id: parseInt(url_params.id) });
            props.updateSavedArticles(saved_a);
          }
        })
        .catch((err) => {
          props.setAlert({
            show: true,
            error: true,
            message: err.response.data.detail,
          });
        });
    } else {
      props.setAlert({
        show: true,
        error: true,
        message: "Please Login/Signup to save this article!",
      });
    }
  };
  const isInSaved = () => {
    return props.articles_saved.find((sa) => {
      return sa.article_id === article.id;
    });
  };
  const isFollowing = () => {
    return props.authors_following.find((uf) => {
      return uf === article.created_by.id;
    });
  };
  const isLiked = () => {
    return props.articles_liked.find((sa) => sa.article_id === article.id);
  };
  const likedComment = (id) => {
    const token_exists = localStorage.getItem("token");
    if (token_exists) {
      http
        .post(`/api/articles/like_comment/`, { comment_id: id })
        .then((res) => {
          let parent_comm = comments.find((cm) => cm.id == id);
          parent_comm = { ...parent_comm };
          let ind = comments.indexOf(parent_comm);
          if (res.data.detail.indexOf("removed") < 0) {
            parent_comm.likes.push({ user_id: props.user.id });
          } else {
            let me = parent_comm.likes.find(
              (user) => user.id === props.user.id
            );
            let me_index = parent_comm.likes.indexOf(me);
            parent_comm.likes.splice(me_index, 1);
          }
          let coms = [...comments];
          coms[ind] = parent_comm;
          setComments(coms);
        })
        .catch((err) => {
          props.setAlert({
            show: true,
            error: true,
            message: err.response.data.detail,
          });
        });
    } else {
      props.setAlert({
        show: true,
        error: true,
        message: "Please Login/Signup to like this comment!",
      });
    }
  };
  const hasLikedComment = (comment) => {
    return comment.likes.filter((like) => {
      return like.user_id == props.user.id;
    });
  };
  const reportArticle = () => {
    let id = url_params.id;
    http
      .post(`/api/articles/report?article_id=${id}`)
      .then((res) => {
        props.setAlert({
          show: true,
          error: !res.data.status,
          message: res.data.message,
        });
        setShowAction1(false);
        setShowAction2(false);
      })
      .catch((err) => {
        setShowAction1(false);
        setShowAction2(false);
        props.setAlert({
          show: true,
          error: true,
          message: err.response.data.detail,
        });
      });
  };
  return (
    <div className="w-full">
      <div className="m-auto lg:max-w-4xl mx-5 lg:mx-auto w-full">
        {loading ? (
          <div className="text-center justify-center flex items-center p-20">
            <svg className="h-16 w-16 animate-spin" viewBox="0 0 24 24">
              <path
                fill="currentColor"
                d="M12 19C13.1 19 14 19.9 14 21S13.1 23 12 23 10 22.1 10 21 10.9 19 12 19M12 1C13.1 1 14 1.9 14 3S13.1 5 12 5 10 4.1 10 3 10.9 1 12 1M6 16C7.1 16 8 16.9 8 18S7.1 20 6 20 4 19.1 4 18 4.9 16 6 16M3 10C4.1 10 5 10.9 5 12S4.1 14 3 14 1 13.1 1 12 1.9 10 3 10M6 4C7.1 4 8 4.9 8 6S7.1 8 6 8 4 7.1 4 6 4.9 4 6 4M18 16C19.1 16 20 16.9 20 18S19.1 20 18 20 16 19.1 16 18 16.9 16 18 16M21 10C22.1 10 23 10.9 23 12S22.1 14 21 14 19 13.1 19 12 19.9 10 21 10M18 4C19.1 4 20 4.9 20 6S19.1 8 18 8 16 7.1 16 6 16.9 4 18 4Z"
              />
            </svg>
          </div>
        ) : (
          <div className="flex justify-between items-center w-full">
            <div className="px-5 py-10 w-full">
              <h1 className="text-3xl font-semibold tracking-wide">
                {article.title}
                <span className="pl-2 text-sm font-normal">
                  {new Date(article.created_at).toDateString()} |{" "}
                  {article.read_time} read
                </span>
              </h1>
              <div className="flex flex-col md:flex-row py-8 md:justify-between md:items-center pr-5">
                <div className="flex justify-between items-center space-x-2 pb-3 md:pb-0">
                  <div className="flex items-center space-x-2">
                    <img
                      src={
                        article.created_by.avatar
                          ? article.created_by.avatar
                          : avatar
                      }
                      className="rounded-full h-10"
                      alt="user"
                    />
                    <div className="flex flex-col">
                      <div className="space-x-3">
                        <Link to={`/writer/${article.created_by.id}`}>
                          {article.created_by.firstname
                            ? article.created_by.firstname
                            : article.created_by.username}{" "}
                          {article.created_by.lastname}
                        </Link>
                        <button
                          onClick={followAuthor}
                          className="bg-info-blue text-white rounded-full px-4"
                        >
                          {isFollowing() ? "Following" : "Follow"}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6 inline pl-2"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M18 9v3m0 0v3m0-3h3m-3 0h-3m-2-5a4 4 0 11-8 0 4 4 0 018 0zM3 20a6 6 0 0112 0v1H3v-1z"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex space-x-2 md:justify-between items-center">
                  <TwitterShareButton
                    title={`Read ${article.title}`}
                    url={window.location.href}
                  >
                    <span className="flex justify-between text-info-blue items-center rounded-full p-2 border border-info-blue">
                      <svg className="h-6 w-6 inline" viewBox="0 0 24 24">
                        <path
                          fill="currentColor"
                          d="M22.46,6C21.69,6.35 20.86,6.58 20,6.69C20.88,6.16 21.56,5.32 21.88,4.31C21.05,4.81 20.13,5.16 19.16,5.36C18.37,4.5 17.26,4 16,4C13.65,4 11.73,5.92 11.73,8.29C11.73,8.63 11.77,8.96 11.84,9.27C8.28,9.09 5.11,7.38 3,4.79C2.63,5.42 2.42,6.16 2.42,6.94C2.42,8.43 3.17,9.75 4.33,10.5C3.62,10.5 2.96,10.3 2.38,10C2.38,10 2.38,10 2.38,10.03C2.38,12.11 3.86,13.85 5.82,14.24C5.46,14.34 5.08,14.39 4.69,14.39C4.42,14.39 4.15,14.36 3.89,14.31C4.43,16 6,17.26 7.89,17.29C6.43,18.45 4.58,19.13 2.56,19.13C2.22,19.13 1.88,19.11 1.54,19.07C3.44,20.29 5.7,21 8.12,21C16,21 20.33,14.46 20.33,8.79C20.33,8.6 20.33,8.42 20.32,8.23C21.16,7.63 21.88,6.87 22.46,6Z"
                        />
                      </svg>
                    </span>
                  </TwitterShareButton>
                  <LinkedinShareButton
                    title={`Read ${article.title}`}
                    url={window.location.href}
                    className="flex justify-between items-center text-info-blue  rounded-full p-2 border border-info-blue"
                  >
                    <span className="flex justify-between text-info-blue items-center rounded-full p-2 border border-info-blue">
                      <svg className="h-6 w-6 inline" viewBox="0 0 24 24">
                        <path
                          fill="currentColor"
                          d="M19 3A2 2 0 0 1 21 5V19A2 2 0 0 1 19 21H5A2 2 0 0 1 3 19V5A2 2 0 0 1 5 3H19M18.5 18.5V13.2A3.26 3.26 0 0 0 15.24 9.94C14.39 9.94 13.4 10.46 12.92 11.24V10.13H10.13V18.5H12.92V13.57C12.92 12.8 13.54 12.17 14.31 12.17A1.4 1.4 0 0 1 15.71 13.57V18.5H18.5M6.88 8.56A1.68 1.68 0 0 0 8.56 6.88C8.56 5.95 7.81 5.19 6.88 5.19A1.69 1.69 0 0 0 5.19 6.88C5.19 7.81 5.95 8.56 6.88 8.56M8.27 18.5V10.13H5.5V18.5H8.27Z"
                        />
                      </svg>
                    </span>
                  </LinkedinShareButton>
                  <button
                    data-tooltip-target="save-tooltip"
                    onClick={saveArticle}
                    className="flex justify-between items-center text-info-blue"
                  >
                    <div
                      id="save-tooltip"
                      role="tooltip"
                      className="inline-block absolute invisible z-10 py-2 px-3 text-sm font-medium text-white bg-gray-900 rounded-lg shadow-sm opacity-0 transition-opacity duration-300 tooltip dark:bg-gray-700"
                    >
                      {isInSaved() ? "unsave" : "save"} article
                      <div className="tooltip-arrow" data-popper-arrow></div>
                    </div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6 inline"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        fill={`${isInSaved() ? "currentColor" : "none"}`}
                        strokeWidth="2"
                        d="M5 5a2 2 0 012-2h10a2 2 0 012 2v16l-7-3.5L5 21V5z"
                      />
                    </svg>
                  </button>
                  <div className="relative">
                    <button
                      onClick={() => setShowAction1(!showAction1)}
                      className="flex justify-between items-center text-info-blue"
                    >
                      <svg className="h-6 w-6 inline" viewBox="0 0 24 24">
                        <path
                          fill="currentColor"
                          d="M16,12A2,2 0 0,1 18,10A2,2 0 0,1 20,12A2,2 0 0,1 18,14A2,2 0 0,1 16,12M10,12A2,2 0 0,1 12,10A2,2 0 0,1 14,12A2,2 0 0,1 12,14A2,2 0 0,1 10,12M4,12A2,2 0 0,1 6,10A2,2 0 0,1 8,12A2,2 0 0,1 6,14A2,2 0 0,1 4,12Z"
                        />
                      </svg>
                    </button>
                    <Actions
                      article={article}
                      reportArticle={reportArticle}
                      showAction={showAction1}
                    />
                  </div>
                </div>
              </div>
              <div className="prose  max-w-full lg:w-11/12 tracking-wide">
                <div dangerouslySetInnerHTML={{ __html: article.content }} />
              </div>
              <div className="flex justify-between items-center py-10">
                <div className="flex space-x-10">
                  <button
                    data-tooltip-target="like-tooltip"
                    onClick={likeArticle}
                    className="flex justify-between items-center"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6 inline text-info-blue"
                      fill={`none`}
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        fill={`${isLiked() ? "currentColor" : ""}`}
                        strokeWidth="2"
                        d="M14 10h4.764a2 2 0 011.789 2.894l-3.5 7A2 2 0 0115.263 21h-4.017c-.163 0-.326-.02-.485-.06L7 20m7-10V5a2 2 0 00-2-2h-.095c-.5 0-.905.405-.905.905 0 .714-.211 1.412-.608 2.006L7 11v9m7-10h-2M7 20H5a2 2 0 01-2-2v-6a2 2 0 012-2h2.5"
                      />
                    </svg>
                    {article.likes ? article.likes.length : 0}
                  </button>
                  <div
                    id="like-tooltip"
                    role="tooltip"
                    className="inline-block absolute invisible z-10 py-2 px-3 text-sm font-medium text-white bg-gray-900 rounded-lg shadow-sm opacity-0 transition-opacity duration-300 tooltip dark:bg-gray-700"
                  >
                    {isLiked() ? "unlike" : "like"} article
                    <div className="tooltip-arrow" data-popper-arrow></div>
                  </div>
                  <button
                    onClick={loadComments}
                    className="flex text-info-blue place-items-start"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6 inline"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z"
                      />
                    </svg>
                    {comments.length}
                  </button>
                </div>
                <div className="flex space-x-2  items-center ">
                  <TwitterShareButton
                    title={`Read ${article.title}`}
                    url={window.location.href}
                  >
                    <span className="flex justify-between text-info-blue items-center rounded-full p-2 border border-info-blue">
                      <svg className="h-6 w-6 inline" viewBox="0 0 24 24">
                        <path
                          fill="currentColor"
                          d="M22.46,6C21.69,6.35 20.86,6.58 20,6.69C20.88,6.16 21.56,5.32 21.88,4.31C21.05,4.81 20.13,5.16 19.16,5.36C18.37,4.5 17.26,4 16,4C13.65,4 11.73,5.92 11.73,8.29C11.73,8.63 11.77,8.96 11.84,9.27C8.28,9.09 5.11,7.38 3,4.79C2.63,5.42 2.42,6.16 2.42,6.94C2.42,8.43 3.17,9.75 4.33,10.5C3.62,10.5 2.96,10.3 2.38,10C2.38,10 2.38,10 2.38,10.03C2.38,12.11 3.86,13.85 5.82,14.24C5.46,14.34 5.08,14.39 4.69,14.39C4.42,14.39 4.15,14.36 3.89,14.31C4.43,16 6,17.26 7.89,17.29C6.43,18.45 4.58,19.13 2.56,19.13C2.22,19.13 1.88,19.11 1.54,19.07C3.44,20.29 5.7,21 8.12,21C16,21 20.33,14.46 20.33,8.79C20.33,8.6 20.33,8.42 20.32,8.23C21.16,7.63 21.88,6.87 22.46,6Z"
                        />
                      </svg>
                    </span>
                  </TwitterShareButton>
                  <LinkedinShareButton
                    title={`Read ${article.title}`}
                    url={window.location.href}
                    className="flex justify-between items-center text-info-blue  rounded-full p-2 border border-info-blue"
                  >
                    <span className="flex justify-between text-info-blue items-center rounded-full p-2 border border-info-blue">
                      <svg className="h-6 w-6 inline" viewBox="0 0 24 24">
                        <path
                          fill="currentColor"
                          d="M19 3A2 2 0 0 1 21 5V19A2 2 0 0 1 19 21H5A2 2 0 0 1 3 19V5A2 2 0 0 1 5 3H19M18.5 18.5V13.2A3.26 3.26 0 0 0 15.24 9.94C14.39 9.94 13.4 10.46 12.92 11.24V10.13H10.13V18.5H12.92V13.57C12.92 12.8 13.54 12.17 14.31 12.17A1.4 1.4 0 0 1 15.71 13.57V18.5H18.5M6.88 8.56A1.68 1.68 0 0 0 8.56 6.88C8.56 5.95 7.81 5.19 6.88 5.19A1.69 1.69 0 0 0 5.19 6.88C5.19 7.81 5.95 8.56 6.88 8.56M8.27 18.5V10.13H5.5V18.5H8.27Z"
                        />
                      </svg>
                    </span>
                  </LinkedinShareButton>

                  <button
                    data-tooltip-target="save-tooltip"
                    onClick={saveArticle}
                    className="flex justify-between items-center text-info-blue"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6 inline"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        fill={`${isInSaved() ? "currentColor" : "none"}`}
                        strokeWidth="2"
                        d="M5 5a2 2 0 012-2h10a2 2 0 012 2v16l-7-3.5L5 21V5z"
                      />
                    </svg>
                  </button>
                  <div className="relative">
                    <button
                      onClick={() => setShowAction2(!showAction2)}
                      className="flex justify-between items-center text-info-blue"
                    >
                      <svg className="h-6 w-6 inline" viewBox="0 0 24 24">
                        <path
                          fill="currentColor"
                          d="M16,12A2,2 0 0,1 18,10A2,2 0 0,1 20,12A2,2 0 0,1 18,14A2,2 0 0,1 16,12M10,12A2,2 0 0,1 12,10A2,2 0 0,1 14,12A2,2 0 0,1 12,14A2,2 0 0,1 10,12M4,12A2,2 0 0,1 6,10A2,2 0 0,1 8,12A2,2 0 0,1 6,14A2,2 0 0,1 4,12Z"
                        />
                      </svg>
                    </button>
                    <Actions
                      article={article}
                      reportArticle={reportArticle}
                      showAction={showAction2}
                    />
                  </div>
                </div>
              </div>
              <div className="flex space-x-10 pr-10 flex-col md:flex-row">
                <div
                  className={`${
                    !showComments
                      ? "hidden"
                      : "relative flex flex-col flex-grow bg-white shadow p-5 border-gray-200 border"
                  }`}
                >
                  <button
                    className={"absolute top-0 right-0 "}
                    onClick={() => setshowComments(!showComments)}
                  >
                    <svg className={"h-4 w-4 inline m-2"} viewBox="0 0 24 24">
                      <path
                        fill="currentColor"
                        d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z"
                      />
                    </svg>
                  </button>
                  {comments.length ? (
                    ""
                  ) : (
                    <p className="text-center">No comments to show</p>
                  )}
                  <div className={"flex flex-col space-y-5"}>
                    {comments
                      ? comments.map((comment, i) => (
                          <div
                            className={` space-x-2 py-2 ${
                              i + 1 < comments.length ? "border-b" : ""
                            }`}
                            key={i}
                          >
                            <div className="flex w-full flex-col md:flex-row md:justify-start md:items-start">
                              <div className="flex md:justify-between items-center space-x-3 overflow-x-hidden">
                                <img
                                  src={
                                    comment.user.avatar
                                      ? comment.user.avatar
                                      : avatar
                                  }
                                  className="rounded-full h-10 "
                                  alt="user"
                                />

                                <b>
                                  {comment.user.firstname
                                    ? comment.user.firstname
                                    : comment.user.username}
                                  {comment.user.lastname}
                                </b>
                              </div>
                              <div className="pt-2 flex flex-grow space-x-2 pl-2  ">
                                <div className="flex flex-col flex-grow">
                                  <div className="flex flex-grow md:justify-between md:items-center flex-col md:flex-row">
                                    <div className="flex flex-col pr-10">
                                      <p className="text-sm flex flex-grow">
                                        {comment.content}
                                      </p>
                                      <p className="text-xs text-gray-500">
                                        {new Date(
                                          comment.created_at
                                        ).toDateString()}
                                      </p>
                                    </div>
                                    {console.log(
                                      hasLikedComment(comment).length
                                    )}
                                    <div className="flex md:justify-between items-center space-x-5">
                                      <button
                                        onClick={() => likedComment(comment.id)}
                                        className="text-info-blue"
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          className="h-6 w-6 inline"
                                          fill="none"
                                          viewBox="0 0 24 24"
                                          stroke="currentColor"
                                        >
                                          <path
                                            strokeLinecap="round"
                                            fill={`${
                                              hasLikedComment(comment).length >
                                              0
                                                ? "currentColor"
                                                : "none"
                                            }`}
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M14 10h4.764a2 2 0 011.789 2.894l-3.5 7A2 2 0 0115.263 21h-4.017c-.163 0-.326-.02-.485-.06L7 20m7-10V5a2 2 0 00-2-2h-.095c-.5 0-.905.405-.905.905 0 .714-.211 1.412-.608 2.006L7 11v9m7-10h-2M7 20H5a2 2 0 01-2-2v-6a2 2 0 012-2h2.5"
                                          />
                                        </svg>
                                        {comment.likes.length}
                                      </button>
                                      <button
                                        onClick={() => {
                                          let initil = reply_ind.show;
                                          setReply({
                                            i: i,
                                            show: !reply_ind.show,
                                          });
                                          if (!initil) {
                                            document
                                              .getElementById(`reply-${i}`)
                                              .scrollIntoView({
                                                behavior: "smooth",
                                              });
                                          }
                                        }}
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          className="h-6 w-6 inline"
                                          fill="none"
                                          viewBox="0 0 24 24"
                                          stroke="currentColor"
                                        >
                                          <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z"
                                          />
                                        </svg>
                                      </button>
                                    </div>
                                  </div>
                                  {comment.replies.map((reply) => (
                                    <div
                                      key={reply.id}
                                      className="flex w-full py-5"
                                    >
                                      <div className="flex flex-col md:flex-row  ">
                                        <div className="pt-2 flex space-x-2 pl-2 items-center overflow-x-hidden">
                                          <img
                                            src={
                                              reply.user.avatar
                                                ? reply.user.avatar
                                                : avatar
                                            }
                                            className="rounded-full h-10 w-10"
                                            alt="user"
                                          />
                                          <b>
                                            {reply.user.firstname
                                              ? reply.user.firstname
                                              : reply.user.username}
                                            {reply.user.lastname}
                                          </b>
                                        </div>
                                        <div className="flex flex-grow justify-between items-center pl-10">
                                          <div className="flex flex-col ">
                                            <p className="text-sm flex flex-grow">
                                              {reply.content}
                                            </p>
                                            <p className="text-xs text-gray-500">
                                              {new Date(
                                                reply.created_at
                                              ).toDateString()}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>
                            <div id={`reply-${i}`}></div>
                            <form
                              onSubmit={replyComment}
                              className={`px-10 py-2 flex md:flex-row flex-col ${
                                i === reply_ind.i && reply_ind.show
                                  ? ""
                                  : "hidden"
                              }`}
                            >
                              <input
                                type="text"
                                name="comment"
                                readOnly={true}
                                value={comment.id}
                                className="hidden"
                              />
                              <textarea
                                required={true}
                                value={comment_reply_text}
                                onChange={(e) => {
                                  setCommentReplyText(e.target.value);
                                }}
                                name="reply"
                                className="w-full border-gray-500 rounded-md flex-grow"
                              ></textarea>
                              <div className="flex justify-end items-end px-2">
                                <button
                                  disabled={replying}
                                  className="rounded-full bg-info-blue px-5 py-1 bottom-0 
                                    text-white place-items-end  mt-2 md:mt-0"
                                >
                                  {replying ? "sending.." : "reply"}
                                </button>
                              </div>
                            </form>
                          </div>
                        ))
                      : ""}
                  </div>
                </div>
                <form
                  method="post"
                  onSubmit={postComment}
                  className={`flex-grow  space-x-2 ${
                    showComments ? "hidden" : "flex md:flex-row flex-col"
                  }`}
                >
                  <textarea
                    required={true}
                    onChange={(e) => setCommentText(e.target.value)}
                    rows="3"
                    className=" border-gray-500 rounded-md flex-grow"
                  ></textarea>
                  <div className="flex justify-end items-end flex-col place-items-end ">
                    <button
                      disabled={commenting}
                      className="rounded-full bg-info-blue mt-2 md:mt-0 px-5 py-1 bottom-0 
                                    text-white place-items-end"
                    >
                      {commenting ? "submitting.." : "submit"}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="m-auto max-w-screen-2xl mx-5 lg:mx-auto">
        {pop_remove_saved ? (
          <RemoveFromSaved
            remove_article={remove_article}
            popupRemoveFromSaved={popupRemoveFromSaved}
          />
        ) : (
          ""
        )}

        <h1 className="text-2xl font-bold text-center py-4">
          Related Articles
        </h1>
        <hr />
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 mx-5 py-5">
          {related_articles.map((article) => (
            <ArticleList
              key={article.id}
              article={article}
              popupRemoveFromSaved={popupRemoveFromSaved}
              setRemoveArticle={setRemoveArticle}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    articles_saved: state.auth.articles_saved,
    articles_liked: state.auth.articles_liked,
    authors_following: state.auth.authors_following,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateLikeArticle: (liked_articles) =>
      dispatch(auth.articlesLiked(liked_articles)),
    updateSavedArticles: (liked_articles) =>
      dispatch(auth.articlesSaved(liked_articles)),
    authorsFollowing: (following) => dispatch(auth.authorsFollowing(following)),
    setAlert: (data) => dispatch(general.setAlert(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ArticleDetails);
