import React from 'react';
import { Link } from "react-router-dom";
import Typing from 'react-typing-animation';
import { connect } from 'react-redux'; // this is correct
import * as auth from "../../store/actions/Auth";


const Banner = (props) => {
    return (
        <div className="grid grid-cols-1 md:grid-cols-5 md:py-10 justify-between items-center m-auto max-w-screen-2xl mx-5 lg:mx-auto">
            <div className={"px-3 lg:px-10 py-16 lg:py-3 md:col-span-3  md:bg-gradient-to-r lg:w-2/3 bg-no-repeat bg-cover"}>
                <h5 className={"text-3xl font-bold"}>
                    <span className="inline text-info-blue">
                        <Typing loop={true} cursor={<span>|</span>}>
                            <span>
                                Learn new things
                            </span>
                            <Typing.Delay ms={2000} />
                            <Typing.Backspace count={"Learn new things ".length + 2} />
                            <span>
                                Read and write articles
                            </span>
                            <Typing.Delay ms={2000} />
                            <Typing.Backspace count={"Read and write articles ".length + 2} />
                            <span>
                                Get internships and jobs
                            </span>
                            <Typing.Delay ms={2000} />
                            <Typing.Backspace count={"Get internships and jobs ".length + 2} />
                            <span>
                                Get latest news
                            </span>
                            <Typing.Delay ms={2000} />
                            <Typing.Backspace count={"Get latest news ".length + 2} />
                            <span>
                                Get latest publications
                            </span>
                            <Typing.Delay ms={2000} />
                            <Typing.Backspace count={"Get latest publications ".length + 2} />
                            <span>
                                Get upcoming events
                            </span>
                            <Typing.Delay ms={2000} />
                            <Typing.Backspace count={"Get upcoming events ".length + 2} />
                            <span>
                                Book reviews
                            </span>
                            <Typing.Delay ms={2000} />
                            <Typing.Backspace count={"Book reviews ".length + 2} />
                        </Typing>
                    </span>
                    about Quantum Computing
                </h5>
                <p className={"text-xl tracking-wide pt-10"}>
                    A global e-learning and content creation
                    platform solely for Quantum Computing
                </p>
                <div className={"py-8 flex justify-between items-center m-auto max-w-screen-2xl mx-auto"}>
                    {
                        props.token ?
                            "" :
                            <Link to={"/auth/login"} className={"bg-info-blue text-white py-2 px-5 rounded-full flex "}>
                        Get Started
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 ml-3 " fill="none" viewBox="0 0 24 24"
                            stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                d="M17 8l4 4m0 0l-4 4m4-4H3" />
                        </svg>
                    </Link>
                    }
                    
                </div>
            </div>
            <div className={"px-5 flex-1 col-span-2 hidden md:flex"}>
                <img src={"/assets/banner.png"} alt={"banner"} className={"w-full object-cover"} />
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        token: state.auth.token,
        user: state.auth.user,
        notifications: state.auth.notifications
    }
};
const mapDispatchToProps = dispatch => {
    return {
        logOut: () => dispatch(auth.logout())
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Banner);