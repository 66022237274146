import React from "react";
import Follower from "./Follower";
import { useNavigate } from "react-router-dom";

const FollowerProfile = (props) => {
  const navigate = useNavigate();
  const redirectToSelectedFollower = (follower) => {
    props.setFollowerListModalOpen(false);
    navigate(`/writer/${follower.id}`);
  };
  return (
    <div
      className="fixed  inset-0 z-1000 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full"
      id="my-modal"
    >
      <div className="relative top-60 z-10 mx-auto p-5 border w-fit shadow-lg rounded-md bg-white">
        <div className="border-b border-gray pt-10 pb-4 space-x-20 md:mx-8 ">
          <h3 className="font-bold text-xl">Followers</h3>
        </div>
        <button
          className={"absolute top-0 right-0 p-5"}
          onClick={(e) => {
            e.preventDefault();
            props.setFollowerListModalOpen(false);
          }}
        >
          <svg className={"h-6 w-6 inline "} viewBox="0 0 24 24">
            <path
              fill="currentColor"
              d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z"
            />
          </svg>
        </button>
        {props.followers.length > 0
          ? props.followers.map((follower) => (
              <Follower
                setFollowerListModalOpen={props.setFollowerListModalOpen}
                redirectToSelectedFollower={redirectToSelectedFollower}
                key={follower.id}
                follower={follower}
                authors_following={props.authors_following}
              />
            ))
          : "There are no followers at the moment..."}
      </div>
    </div>
  );
};

export default FollowerProfile;
