import React from 'react';
import { connect } from 'react-redux';
import * as auth from "../../store/actions/Auth";


export const LogoutConfirm = (props) => {
    return (
        props.logout_pop===true ? <div
            className="fixed  inset-0 z-1000 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full"
            id="my-modal"
        >
            <div className="relative top-80 z-10 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
                <p className="py-4 text-center">Are you sure you want to logout? </p>
                <div className="flex justify-between items-center -auto max-w-screen-2xl mx-5">
                    <button className="rounded-full px-6 border-info-blue border"
                        onClick={() => props.processLogout(false)}>cancel</button>
                    <button className="rounded-full px-10 bg-info-blue text-white"
                        onClick={() => props.processLogout(true)}>yes</button>
                </div>
            </div>
        </div> : "");
};

const mapStateToProps = (state) => {
    return {
        logout_pop: state.auth.logout_pop,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        processLogout: (proceed) => dispatch(auth.processLogout(proceed))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(LogoutConfirm);
