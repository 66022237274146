import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { connect } from 'react-redux'; // this is correct
import { Menu, Transition } from '@headlessui/react';
import large_logo from "../../assets/logo_main.png"
import large_logo_gold from "../../assets/logo_main_gold.png"
import small_logo from "../../assets/small_logo.png"
import * as auth from "../../store/actions/Auth";
import avatar from "../../assets/avatar.jpg"

const Header = (props) => {
    const [logo, changeLogo] = useState(large_logo)

    
    return (
        <header className="shadow-lg bg-white sticky top-0 z-1000 bg-skin-fill ">
            <div className="flex m-auto max-w-screen-2xl mx-5 lg:mx-auto">
                <Link to={"/"} onMouseEnter={() => changeLogo(large_logo_gold)} onMouseLeave={() => changeLogo(large_logo)}>
                    <div className="relative hidden lg:inline-grid items-center h-14 w-[150px]">
                        <img alt="large-logo" className="object-fill h-14 py-0.5" src={logo} />
                    </div>
                    <div className="flex relative lg:hidden items-center w-14 h-14">
                        <img alt="small-logo" className="object-fill py-1 w-14" src={small_logo} />
                    </div>
                </Link>
                <div className={'flex justify-between flex-grow lg:mx-auto '}>
                    <div className="items-center justify-between hidden md:flex
                space-x-4 px-2 xl:px-8">
                        <Menu as='div' className='relative'>
                            {({ open }) =>
                                <>
                                    <Menu.Button className={'bg-light-purple px-4 py-0.5 rounded-full border border-gray-300 whitespace-nowrap'}
                                    >
                                        Explore Content
                                        {!open ?
                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                className={`h-5 w-5 inline ml-2`} fill="none" viewBox="0 0 24 24"
                                                stroke="currentColor">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                                    d="M19 9l-7 7-7-7" />
                                            </svg>
                                            :
                                            <svg xmlns="http://www.w3.org/2000/svg" className={`h-5 w-5 inline ml-2`} fill="none" viewBox="0 0 24 24"
                                                stroke="currentColor">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                                    d="M5 15l7-7 7 7" />
                                            </svg>
                                        }

                                    </Menu.Button>
                                    <Transition show={open}
                                        enter="transform transition duration-100 ease-in"
                                        enterFrom="opacity-0 scale-95"
                                        enterTo="opacity-0 scale-100"
                                        leave="transform transition duration-75 ease-out"
                                        leaveFrom="opacity-100 scale-100"
                                        leaveTo="opacity-0 scale-95"
                                    >
                                        <Menu.Items static className={`w-full bg-dropdown-gray text-white z-10 absolute top-12 flex flex-col`}>
                                            <Menu.Item>
                                                {({ active }) => (
                                                    <Link className={`${active ? 'bg-gray-500' : ''} py-2 px-4`} to={'/news'}>News</Link>
                                                )}
                                            </Menu.Item>
                                            <Menu.Item>
                                                {({ active }) => (
                                                    <Link className={`${active ? 'bg-gray-500' : ''} py-2 px-4`} to={'/books'}>Books</Link>
                                                )}
                                            </Menu.Item>
                                            <Menu.Item>
                                                {({ active }) => (
                                                    <Link className={`${active ? 'bg-gray-500' : ''} py-2 px-4`} to={'/articles'}>Articles</Link>
                                                )}
                                            </Menu.Item>
                                            <Menu.Item>
                                                {({ active }) => (
                                                    <Link className={`${active ? 'bg-gray-500' : ''} py-2 px-4`} to={'/publications'}>Publications</Link>
                                                )}
                                            </Menu.Item>
                                            <Menu.Item>
                                                {({ active }) => (
                                                    <Link className={`${active ? 'bg-gray-500' : ''} py-2 px-4`} to={'/events'}>Events</Link>
                                                )}
                                            </Menu.Item>
                                            <Menu.Item>
                                                {({ active }) => (
                                                    <Link className={`${active ? 'bg-gray-500' : ''} py-2 px-4`} to={'/videos'}>Videos</Link>
                                                )}
                                            </Menu.Item>
                                            <Menu.Item>
                                                {({ active }) => (
                                                    <Link className={`${active ? 'bg-gray-500' : ''} py-2 px-4`} to={'/conferences'}>Conferences</Link>
                                                )}
                                            </Menu.Item>
                                            <Menu.Item>
                                                {({ active }) => (
                                                    <Link className={`${active ? 'bg-gray-500' : ''} py-2 px-4`} to={'/jobs'}>Jobs</Link>
                                                )}
                                            </Menu.Item>
                                           
                                            <Menu.Item disabled><hr /></Menu.Item>
                                            <Menu.Item>
                                                {({ active }) => (
                                                    <Link className={`${active ? 'bg-gray-500' : ''} 
                                                    py-2 px-4`} to={'/contribute'}>Contact us</Link>
                                                )}
                                            </Menu.Item>
                                        </Menu.Items>
                                    </Transition>
                                </>
                            }
                        </Menu>
                        <Link to={"/write"} className="hover:text-blue-600">Write</Link>
                        <Link to={"/contribute"} className="hover:text-blue-600">Contribute</Link>
                        <Link to={"/marketplace"} className="hover:text-blue-600">Marketplace</Link>
                        <Link to={"/women-empowerment"} className="hover:text-blue-600">Women Empowerment</Link>
                        <Link to={"/book-chapter"} className="hover:text-blue-600">Book Chapter</Link>


                    </div>
                    <div className={"flex-grow md:hidden"}>

                    </div>
                    <div className="flex justify-between items-center space-x-2 md:inline-flex pl-5">


                        <button onClick={props.updateSearching}
                            className={'py-2 md:mt-1 lg:mr-6  flex-grow w-full mt-1'}>
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 inline" fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                    d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                            </svg>
                        </button>
                        <Link to={'/auth/login'} className={!props.token ? 'flex whitespace-nowrap text-info-blue pr-2 lg:pr-6' : "hidden"}>Sign In</Link>
                        <Link to={'/auth/register'} className={`bg-info-blue px-2 lg:px-6 py-1 rounded-full text-white whitespace-nowrap
                             ${!props.token ? 'flex' : "hidden"}`}>Get Started</Link>
                        <div className={props.token ? 'flex justify-between items-center pr-4' : "hidden"}>
                            <Link to={'/notifications'} className={'relative flex flex-none mr-5 mt-1.5'}>
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none"
                                    viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                        d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" />
                                </svg>
                                <span className={'text-xs absolute -top-1.5 -right-1.5 border-gray-700 rounded-full border bg-white px-1 '}>
                                    <span className={"animate-pulse"}>
                                        {props.notifications}
                                    </span>
                                </span>
                            </Link>

                            <Menu as='div' className='relative'>
                                {({ open }) =>
                                    <>
                                        <Menu.Button className={'border mt-1 p-0.5 rounded-full flex flex-none w-11'}>
                                            <img src={props.user.avatar ? props.user.avatar : avatar}
                                                className={'h-10 w-10 rounded-full'} alt={"username"} />
                                        </Menu.Button>
                                        <Transition show={open}
                                            enter="transform transition duration-100 ease-in"
                                            enterFrom="opacity-0 scale-95"
                                            enterTo="opacity-0 scale-100"
                                            leave="transform transition duration-75 ease-out"
                                            leaveFrom="opacity-100 scale-100"
                                            leaveTo="opacity-0 scale-95"
                                        >
                                            <Menu.Items static className={`w-60 bg-dropdown-gray text-white z-1000 absolute
                                             top-14 flex flex-col right-0 shadow rounded-b-md text-ellipsis overflow-hidden truncate`}>
                                                <div className="relative py-2 px-4 border-b flex justify-between 
                                                items-center space-x-2 ">
                                                    <img src={props.user.avatar ? props.user.avatar : avatar}
                                                        className="h-12 w-12 rounded-full" alt={props.user.username} />
                                                    <div>
                                                        <p>{props.user.username}</p>
                                                        <p className="text-sm text-ellipsis overflow-hidden truncate">{props.user.email} </p>
                                                    </div>
                                                </div>
                                                <Menu.Item>
                                                    {({ active }) => (
                                                        <Link className={`${active ? 'bg-gray-500' : ''} py-2 px-4`}
                                                            to={'/dashboard'}>Dashboard</Link>
                                                    )}
                                                </Menu.Item>
                                                <Menu.Item>
                                                    {({ active }) => (
                                                        <Link className={`${active ? 'bg-gray-500' : ''} py-2 px-4`}
                                                            to={'/write'}>Write a story</Link>
                                                    )}
                                                </Menu.Item>
                                                <Menu.Item>
                                                    {({ active }) => (
                                                        <Link className={`${active ? 'bg-gray-500' : ''} py-2 px-4`}
                                                            to={'/articles'}>Articles</Link>
                                                    )}
                                                </Menu.Item>
                                                <Menu.Item>
                                                    {({ active }) => (
                                                        <Link className={`${active ? 'bg-gray-500' : ''} py-2 px-4`}
                                                            to={'/following'}>Following</Link>
                                                    )}
                                                </Menu.Item>
                                                <Menu.Item>
                                                    {({ active }) => (
                                                        <Link className={`${active ? 'bg-gray-500' : ''} py-2 px-4`}
                                                            to={'/saved'}>Saved</Link>
                                                    )}
                                                </Menu.Item>
                                                <Menu.Item>
                                                    {({ active }) => (
                                                        <Link className={`${active ? 'bg-gray-500' : ''} py-2 px-4`}
                                                            to={'/dashboard/drafts'}>Drafts</Link>
                                                    )}
                                                </Menu.Item>

                                                <Menu.Item>
                                                    {({ active }) => (
                                                        <Link className={`${active ? 'bg-gray-500' : ''} py-2 px-4`}
                                                            to={'/dashboard/profile'}>Profile</Link>
                                                    )}
                                                </Menu.Item>
                                                <Menu.Item disabled><hr /></Menu.Item>
                                                <Menu.Item>
                                                    {({ active }) => (
                                                        <button onClick={props.logOut} className={`${active ? 'bg-gray-500 rounded-b-md' : ''} py-2 px-4 text-left`}>
                                                            <svg className={"h-6 w-6 inline mr-2"} viewBox="0 0 24 24">
                                                                <path fill="currentColor" d="M16.56,5.44L15.11,6.89C16.84,7.94 18,9.83 18,12A6,6 0 0,1 12,18A6,6 0 0,1 6,12C6,9.83 7.16,7.94 8.88,6.88L7.44,5.44C5.36,6.88 4,9.28 4,12A8,8 0 0,0 12,20A8,8 0 0,0 20,12C20,9.28 18.64,6.88 16.56,5.44M13,3H11V13H13" />
                                                            </svg>
                                                            logout
                                                        </button>
                                                    )}
                                                </Menu.Item>
                                            </Menu.Items>
                                        </Transition>
                                    </>
                                }
                            </Menu>
                        </div>
                        <Menu as='div' className='relative md:hidden'>
                            {({ open }) =>
                                <>
                                    <Menu.Button className="h-6 w-6 inline mt-2" >
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16" />
                                        </svg>

                                    </Menu.Button>
                                    <Transition show={open}
                                        enter="transform transition duration-100 ease-in"
                                        enterFrom="opacity-0 scale-95"
                                        enterTo="opacity-0 scale-100"
                                        leave="transform transition duration-75 ease-out"
                                        leaveFrom="opacity-100 scale-100"
                                        leaveTo="opacity-0 scale-95"
                                    >
                                        <Menu.Items static className={`w-screen bg-white z-1000 absolute
                                             top-12 flex flex-col -right-1 pl-10`}>
                                            <Menu as='div' className='relative'>
                                                {({ open }) =>
                                                    <>
                                                    
                                                        <Menu.Button className={`hover:text-blue-600 py-2 `}
                                                        >
                                                            Explore Content
                                                            {!open ?
                                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                                    className={`h-5 w-5 inline ml-2`} fill="none" viewBox="0 0 24 24"
                                                                    stroke="currentColor">
                                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                                                        d="M19 9l-7 7-7-7" />
                                                                </svg>
                                                                :
                                                                <svg xmlns="http://www.w3.org/2000/svg" className={`h-5 w-5 inline ml-2`} fill="none" viewBox="0 0 24 24"
                                                                    stroke="currentColor">
                                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                                                        d="M5 15l7-7 7 7" />
                                                                </svg>
                                                            }

                                                        </Menu.Button>
                                                        <Transition show={open}
                                                            enter="transform transition duration-100 ease-in"
                                                            enterFrom="opacity-0 scale-95"
                                                            enterTo="opacity-0 scale-100"
                                                            leave="transform transition duration-75 ease-out"
                                                            leaveFrom="opacity-100 scale-100"
                                                            leaveTo="opacity-0 scale-95"
                                                        >
                                                            <Menu.Items static className={`w-full z-10 top-12 flex flex-col`}>
                                                                <Menu.Item>
                                                                    {({ active }) => (
                                                                        <Link className={`${active ? 'text-blue-600' : ''} py-2 px-4 hover:text-blue-600`} to={'/news'}>News</Link>
                                                                    )}
                                                                </Menu.Item>
                                                                <Menu.Item>
                                                                    {({ active }) => (
                                                                        <Link className={`${active ? 'text-blue-600' : ''} py-2 px-4 hover:text-blue-600`} to={'/books'}>Books</Link>
                                                                    )}
                                                                </Menu.Item>
                                                                <Menu.Item>
                                                                    {({ active }) => (
                                                                        <Link className={`${active ? 'text-blue-600' : ''} py-2 px-4 hover:text-blue-600`} to={'/articles'}>Articles</Link>
                                                                    )}
                                                                </Menu.Item>
                                                                <Menu.Item>
                                                                    {({ active }) => (
                                                                        <Link className={`${active ? 'text-blue-600' : ''} py-2 px-4 hover:text-blue-600`} to={'/publications'}>Publications</Link>
                                                                    )}
                                                                </Menu.Item>
                                                                <Menu.Item>
                                                                    {({ active }) => (
                                                                        <Link className={`${active ? 'text-blue-600' : ''} py-2 px-4 hover:text-blue-600`} to={'/events'}>Events</Link>
                                                                    )}
                                                                </Menu.Item>
                                                                <Menu.Item>
                                                                    {({ active }) => (
                                                                        <Link className={`${active ? 'text-blue-600' : ''} py-2 px-4 hover:text-blue-600`} to={'/jobs'}>Jobs</Link>
                                                                    )}
                                                                </Menu.Item>

                                                            </Menu.Items>
                                                        </Transition>
                                                    </>
                                                }
                                            </Menu>
                                            <Menu.Item>
                                                {({ active }) => (
                                                    <Link to={"/write"} className={`hover:text-blue-600 py-2 ${active ? 'text-blue-600' : ''}`}>Write</Link>
                                                )}
                                            </Menu.Item>
                                            <Menu.Item>
                                                {({ active }) => (
                                                    <Link to={"/contribute"} className={`hover:text-blue-600 py-2 ${active ? 'text-blue-600' : ''}`}>Contribute</Link>
                                                )}
                                            </Menu.Item>
                                            <Menu.Item>
                                                {({ active }) => (
                                                    <Link to={"/marketplace"} className={`hover:text-blue-600 py-2 ${active ? 'text-blue-600' : ''}`}>Marketplace</Link>
                                                )}
                                            </Menu.Item>
                                            <Menu.Item>
                                                {({ active }) => (
                                                    <Link to={"/women-empowerment"} className={`hover:text-blue-600 py-2 ${active ? 'text-blue-600' : ''}`}>Women Empowerment</Link>
                                                )}
                                            </Menu.Item>
                                            <Menu.Item>
                                                {({ active }) => (
                                                    <Link to={"/book-chapter"} className={`hover:text-blue-600 py-2 ${active ? 'text-blue-600' : ''}`}>Book Chapter</Link>
                                                )}
                                            </Menu.Item>
                                        </Menu.Items>
                                    </Transition>
                                </>

                            }

                        </Menu>
                    </div>

                </div>
            </div>
        </header>
    );
};

const mapStateToProps = (state) => {
    return {
        token: state.auth.token,
        user: state.auth.user,
        notifications: state.auth.notifications
    }
};
const mapDispatchToProps = dispatch => {
    return {
        logOut: () => dispatch(auth.logout())
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Header)