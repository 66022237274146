import womenrep from "../../assets/womenrep.svg";
import workforce from "../../assets/workforce.svg";
import women_power from "../../assets/womenpower.svg";
import latest1 from "../../assets/women-letest1.svg";
import chat from "../../assets/chat.svg";
import bulb from "../../assets/bulb.svg";
import discord from "../../assets/discord.svg";
import networkconnect from "../../assets/networkconnect.svg";
import quote from "../../assets/quote.svg";
import gayatri from "../../assets/avators/gayatri.svg";
import somya from "../../assets/avators/somya.svg";
import sanjay from "../../assets/avators/sanjay.svg";
import womenbanner from "../../assets/womenBanner.svg";
import Contribute from "./Contribute";
import JoinCommunity from "./joinCommunity";
import "../../index.css";

const WomenPower = (props) => {

  return (
    <div className="m-auto max-w-screen-2xl lg:mx-auto">
      <div className="bg-light-blue-grad curve grid grid-flow-col py-20">

        <div className="mx-5 md:ml-20 py-20 w-full"> 
          <div>
           <span className="text-info-blue font-bold text-3xl"> Women In Quantum</span> 
          </div>

          <div className="border bg-info-blue h-2 w-11 mt-2 rounded-full"></div>

          <div className="mt-10">
            <span className="text-black-blue-tint font-bold text-6xl"> Empowering <span className="text-info-blue">Women </span> <br/> in Quantum Technology.</span>
          </div>

          <button className="text-white bg-info-blue px-10 py-3 rounded-full mt-5">
          Get Started
          </button>
        </div>
        <div className="mb-20 mr-5">
          <img width="500" className="" src={womenbanner} />
        </div>
      </div>
      <div className="mt-20"></div>
      <div className="text-center">
        <span className="text-info-blue"> Why Women? </span>
      </div>

      <div className="text-center">
        <h3 className="text-3xl font-bold text-black-blue-tint"> The world is waiting for  <br/>
        women engagement.</h3>
      </div>

      <div className="mx-auto px-4 mt-5 md:grid md:grid-row-3 md:grid-flow-col md:auto-rows-min gap-4 space-y-5" >
          <div className="p-6 bg-white rounded-lg shadow-md">
            <div className="">
              <img  className="mx-auto" src={womenrep} />
            </div>
            <div className="mt-5 text-info-blue text-center">
              <span className="text-2xl"> Women Representation </span>
            </div>

            <div className="mt-5  text-center">
              <span className=" "> The ratio of women engagement is less. The creators have to represent the users! </span>
            </div>
          </div>

          <div className="p-6 bg-white rounded-lg border-none shadow-md">
            <div className="flex flex-justify-center">
              <img className="mx-auto" src={workforce} />
            </div>
            <div className="mt-5 text-info-blue text-center">
              <span className="text-2xl"> Lack of Workforce </span>
            </div>

            <div className="mt-5  text-center">
              <span className=" "> We do not have the right amount of people needed to solve this huge challenge. </span>
            </div>
          </div>

          <div className="p-6 bg-white rounded-lg border-none shadow-md">
            <div className="flex flex-justify-center">
              <img className="mx-auto" src={women_power} />
            </div>
            <div className="mt-5 text-info-blue text-center">
              <span className="text-2xl"> Women Power </span>
            </div>

            <div className="mt-5  text-center">
              <span className=""> History has proven that women can bring technology breakthroughs! </span>
            </div>
          </div>
      </div>

      <div className="my-10"></div>
      <div className="my-10"></div>

      <div className="md:ml-20 mt-10 md:flex ">
        <div className="my-auto px-4">
          <div className="text-center md:text-left">
            <span className="text-5xl text-black-blue-tint">The Latest <span className="text-info-blue">Event</span> for <br/> Women in Quantum</span>
          </div>
          <div className="mt-5">
            <span className="font-weight-400">Get the Latest Quantum Computing Event information happening  <br/> worldwide! Our first Women in Quantum Initiative led  by a group of Women <br/> at Quantumgrad starts September 10!</span>
          </div>

          <div className="text-center md:text-left">
            <button
            className="mt-5 border-1 text-info-blue border border-info-blue p-4 rounded-full hover:bg-info-blue hover:text-white">
              Latest QuantumGrad Event
            </button>
          </div>
        </div>


        <div className="mt-10" >
          <img src={latest1}/>
        </div>
      </div>

      <JoinCommunity className="mt-10" />
      
      <div className="text-center mt-20">
      <h3 className="text-3xl font-bold text-black-blue-tint"> What our community are saying <br/> about <span className="text-info-blue"> Women in Quantum </span></h3>
      </div>

      {/* quotes  */}
      <div className=" md:mx-20  md:grid md:grid-flow-col gap-4 md:space-x-5 mt-20 space-y-5">
          <div className="p-6 bg-white shadow-md rounded-lg   hover:bg-light-blue-grad ">
            <img src={quote} />

            <div className="my-5">
              <span>Curiously takes you more into deeper! Quantum Computing teaches us a way to create a better world!!</span>
            </div>

            <hr className="mt-12"/>

            <div className="flex mt-5">
              <img className="w-10 h-10 rounded-full" src={gayatri} alt="Rounded avatar" />

              <div className="ml-5">
                <span className="font-bold"> Gayatri Vadaparty </span> <br/>
                <span className="text-gray-500">Content Creator </span>
              </div>
            </div>
          </div>

          <div className="p-6 bg-white shadow-md rounded-lg  bg-light-blue-grad  ">
            <img src={quote} />

            <div className="my-5">
              <span>QuantumGrad has more women core members than men!</span>
            </div>

            <hr className="mt-12"/>

            <div className="flex mt-5">
              <img className="w-10 h-10 rounded-full" src={sanjay} alt="Rounded avatar" />

              <div className="ml-5">
                <span className="font-bold"> Sanjay Vishwakarma </span> <br/>
                <span className="text-gray-500">QuantumGrad founder & Software Engineer at IBM Quantum </span>
              </div>
            </div>
          </div>

          <div className="p-6 bg-white shadow-md rounded-lg hover:bg-light-blue-grad ">
            <img src={quote} />

            <div className="my-5">
              <span>Women are known for multitasking, just like Quantum Computers!</span>
            </div>

            <hr className="mt-12"/>

            <div className="flex mt-5">
              <img className="w-10 h-10 rounded-full" src={somya} alt="Rounded avatar" />

              <div className="ml-5">
                <span className="font-bold"> Somya Rathee </span> <br/>
                <span className="text-gray-500">Quantum Research </span>
              </div>
            </div>
          </div>

      </div>

      <div className="flex mt-5 justify-center space-x-3">
        <button>
          <svg width="72" height="70" viewBox="0 0 72 70" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="0.30039" y="0.30039" width="71.0923" height="68.8894" rx="34.4447" fill="white"/>
          <path d="M34.7452 27.035L27.0352 34.745L34.7452 42.455M27.0352 34.745H44.658" stroke="#00008B" strokeWidth="1.80234" strokeLinecap="round" strokeLinejoin="round"/>
          <rect x="0.30039" y="0.30039" width="71.0923" height="68.8894" rx="34.4447" stroke="#00008B" strokeWidth="0.60078"/>
          </svg>
        </button>
        <button >
          <svg width="71" height="70" viewBox="0 0 71 70" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="-0.30039" y="0.30039" width="70.2912" height="67.8881" rx="33.9441" transform="matrix(-1 0 0 1 70.3992 0.60083)" fill="#00008B"/>
          <path d="M36.2548 27.6359L43.9648 35.3459L36.2548 43.0559M43.9648 35.3459H26.342" stroke="white" strokeWidth="1.80234" strokeLinecap="round" strokeLinejoin="round"/>
          <rect x="-0.30039" y="0.30039" width="70.2912" height="67.8881" rx="33.9441" transform="matrix(-1 0 0 1 70.3992 0.60083)" stroke="#00008B" strokeWidth="0.60078"/>
          </svg>
        </button>
      </div>

      <div className="md:mx-20">
        <Contribute />
      </div>

      <div className="my-10"></div>
    </div>
  )
}

export default WomenPower;
