import React from 'react';
import whiteimage from '../../../assets/white.jpeg'

const SingleJob = ({ job }) => {

    function getParsedDate(dateObject) {
        return new Date(dateObject).toDateString();
    }
    
    function isValidDate(dateObject) {
        const parsedDate = getParsedDate(dateObject);
        if (parsedDate !== 'Invalid Date') {
            return true;
        }
        return false;
    }

    return (
        job ?
            <a href={job.link} target="_blank" rel="noreferrer" className={"my-2 mx-2 md:mx-5  rounded-lg flex flex-col shadow-lg  transition transform ease-in-out delay-150 motion-reduce:transition-none motion-reduce:transform-none"}>
                <img src={job.image?job.image:whiteimage} className={'rounded-md h-48 w-full object-cover hover:bg-blue-700 hover:opacity-30'} alt={""} />
                <div className={"flex py-1 flex-col px-2 pb-4"}>

                    <span className={"text-black font-semibold text-sm hover:text-blue-600"}>
                        {job.title}
                    </span>
                    <span className={"text-gray-400 text-xs pt-2"}>
                        <span className='hover:text-blue-600 uppercase block'>
                            {job.company}
                        </span>
                        <div>
                            <span className={"capitalize"}>{job.city}</span>
                            <span className='font-bold px-1'>&bull;</span>
                            <span className={""}>{job.category &&
                                job.category.split("_").join(" ")}</span>
                            { isValidDate(job.date_posted) &&
                                (
                                    <span>
                                        <span className='font-bold px-1'>&bull;</span>
                                        <span className={"uppercase"}>{ getParsedDate(job.date_posted) }</span>
                                    </span>
                                )
                            }
                        </div>
                    </span>
                </div>
            </a>
            :
            ""
    );
};

export default SingleJob;
