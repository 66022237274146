import * as actionTypes from "../actionTypes";
import { http, HOST_URL, updateHeaders } from "../../settings";

export const userStart = () => {
    return {
        type: actionTypes.USER_START,
    };
}

export const userSuccess = (user) => {
    return {
        type: actionTypes.USER_SUCCESS,
        user: user,
    };
}
export const articlesLiked = (articles_liked) => {
    return {
        type: actionTypes.ARTICLES_LIKED_SUCCESS,
        articles_liked: articles_liked,
    };
}
export const articlesSaved = (articles_saved) => {
    return {
        type: actionTypes.ARTICLES_SAVED_SUCCESS,
        articles_saved: articles_saved,
    };
}
export const authorsFollowing = (authors_following) => {
    return {
        type: actionTypes.AUTHORS_FOLLOWING_SUCCESS,
        authors_following: authors_following,
    };
}


export const logoutClose = () => {
    return {
        type: actionTypes.LOGOUT_SET,
        logout_pop: false,
    };
};

export const logoutDistpatch = () => {
    return {
        type: actionTypes.AUTH_LOGOUT,
    };
};

export const processLogout = (proceed) => {

    return dispatch => {
        if (proceed) {
            localStorage.removeItem("token");
            localStorage.removeItem("refresh_token");
            localStorage.removeItem("refresh_expirationDate");
            localStorage.removeItem("username");
            localStorage.removeItem("expirationDate");
            updateHeaders()
            dispatch(logoutDistpatch())
        } else {
            dispatch(logoutClose())
        }
    }
}
export const authStart = () => {
    return {
        type: actionTypes.AUTH_START
    };
};

export const authSuccess = (token) => {
    return {
        type: actionTypes.AUTH_SUCCESS,
        token: token,
    };
};

export const authFail = error => {
    return {
        type: actionTypes.AUTH_FAIL,
        error: error
    };
};

export const sugnupFail = error => {
    return {
        type: actionTypes.SIGNUP_FAIL,
        error: error
    };
};


export const signupStart = () => {
    return {
        type: actionTypes.SIGNUP_START
    };
};

export const SignUpSuccess = (message) => {
    return {
        type: actionTypes.SIGNUP_SUCCESS,
        message: message
    };
};
export const userNotifications = (notifications) => {
    return {
        type: actionTypes.NOTIFICATIONS_SUCCESS,
        notifications: notifications
    };
};
export const emailSubscribed = (email_subscribed) => {
    return {
        type: actionTypes.UPDATE_EMAIL_SUBSCRIBED,
        email_subscribed: email_subscribed
    };
};

export const getUser = () => {
    return dispatch => {
        dispatch(userStart())
        http.get(`/api/auth/users/me`).then(res => {
            dispatch(userSuccess(res.data.data.user))
            dispatch(articlesLiked(res.data.data.articles_liked))
            dispatch(articlesSaved(res.data.data.articles_saved))
            dispatch(authorsFollowing(res.data.data.authors_following_ids))
            dispatch(userNotifications(res.data.data.notifications))
            dispatch(emailSubscribed(res.data.data.email_subscribed))
        }).catch(err => {
            console.log(err)
        })
    }
}

export const logoutStart = () => {
    return {
        type: actionTypes.LOGOUT_SET,
        logout_pop: true
    };
};

export const logout = () => {
    return dispatch => {
        dispatch(logoutStart())
    }
}

export const finishLogin = (response) => {
    return dispatch => {
        if (response.token) {
            //    login finish
            localStorage.setItem("token", response.token);

            // update header
            updateHeaders()

            localStorage.setItem("refresh_token", response.refresh_token);
            let nowDate = new Date()
            const expirationDate = new Date(nowDate.getTime() + 30 * 60000);
            const refresh_expirationDate = new Date(nowDate.getTime() + 60 * 24 * 7 * 60000);
            localStorage.setItem("expirationDate", expirationDate.toString());
            localStorage.setItem("refresh_expirationDate", refresh_expirationDate.toString());
            dispatch(getUser());
            dispatch(authSuccess(response.token))
            dispatch(
                checkAuthTimeout(
                    (expirationDate.getTime() - new Date().getTime()) / 1000
                )
            );
        } else {
            dispatch(authFail(response.detail))
        }
    }
}

const tryRefreshToken = () => {
    return async dispatch => {
        let refreshExpiry = new Date(localStorage.getItem("refresh_expirationDate"))
        if (refreshExpiry > new Date()) {
            let refresh_token = localStorage.getItem("refresh_token")
            dispatch(authStart());
            let res
            try {
                res = await fetch(`${HOST_URL}/api/auth/refresh_token?refresh_token=${refresh_token}`, {
                    method: 'GET',
                })
                let response = await res.json()
                if (response.detail) {
                    dispatch(processLogout())
                }
                dispatch(finishLogin(response))
            } catch {
                dispatch(processLogout())
            }

        } else {
            dispatch(processLogout())
        }
    }
}

export const checkAuthTimeout = expirationTime => {
    return dispatch => {
        setTimeout(() => {
            // refresh the token
            dispatch(tryRefreshToken())
        }, expirationTime * 1000);
    };
};

export const authLogin = (data, navigate, path) => {
    return async dispatch => {
        dispatch(authStart());
        let formBody = new URLSearchParams(data)
        let res = await fetch(`${HOST_URL}/api/auth/login`, {
            method: 'POST',
            body: formBody
        })
        let response = await res.json()
        response.token = response.access_token
        dispatch(finishLogin(response))
        if (response.token) {
            if (navigate && path) {
                navigate(path)
            }
        }
    };
};

export const authSignup = (data) => {
    return dispatch => {
        dispatch(signupStart());
        http.post(`/api/auth/signup`, data)
            .then(res => {
                dispatch(SignUpSuccess("account created successfully, please verify your email!"))
            })
            .catch(err => {
                dispatch(sugnupFail(err.response.data.detail))
            }).catch(err => {
                dispatch(sugnupFail("server error, try again or contact us for help"))
            });
    };
};

export const authCheckState = () => {
    return dispatch => {
        const token = localStorage.getItem("token");
        const expirationDate = new Date(localStorage.getItem("expirationDate"));
        if (expirationDate <= new Date()) {
            dispatch(tryRefreshToken());
        } else {
            dispatch(getUser());
            dispatch(authSuccess(token));
            dispatch(
                checkAuthTimeout(
                    (expirationDate.getTime() - new Date().getTime()) / 1000
                )
            );
        }
    }
};

