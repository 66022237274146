import React, { useState, useEffect } from "react";
import Banner from "./sub/Banner";
import Articles from './sub/homepage/Articles';
import News from './sub/homepage/News';
import Publications from './sub/homepage/Publications';
import Events from './sub/homepage/Events';
import Jobs from './sub/homepage/Jobs';
import Books from './sub/homepage/Books';
import { Link } from 'react-router-dom';
import about from "../assets/about.png"
import { http } from '../settings'
import Conferences from './sub/homepage/Conferences';
import Videos from './sub/homepage/Videos';

const Home = () => {
    const [articles, setArticles] = useState([])
    const [events, setEvents] = useState([])
    const [news, setNews] = useState([])
    const [jobs, setJobs] = useState([])
    const [publications, setPulications] = useState([])
    const [books, setBooks] = useState([])
    const [conferences, setConferences] = useState([]);
    const [videos, setVideos] = useState([]);

    useEffect(() => {
        http.get("/api/homepage/").then(res => {
            console.log(res);
            setArticles(res.data.data.articles)
            setEvents(res.data.data.events)
            setNews(res.data.data.news)
            setJobs(res.data.data.jobs)
            setPulications(res.data.data.publications)
            setBooks(res.data.data.books)
            setConferences(res.data.data.conferences);
            setVideos(res.data.data.videos);
        })
    }, [])
    
    return (
        <div className="lg:mx-auto lg:px-10 mx-5">
            <Banner />
            <div className="m-auto max-w-screen-2xl mx-5 lg:mx-auto">
                <div>
                    <Articles articles={articles}/>
                </div>
                <div>
                    <News news={news}/>
                </div>
                <div>
                    <Publications publications={publications}/>
                </div>
                <div>
                    <Events events={events}/>
                </div>
                <div>
                    <Jobs jobs={jobs}/>
                </div>
                <div>
                    <Books books={books}/>
                </div>
                <div>
                   {conferences &&  <Conferences conferences={conferences} /> }
                </div>
                <div>
                    { videos && <Videos videos={videos} /> }
                </div>
                <div className="grid grid-cols-1 md:grid-cols-4 justify-between items-center py-10">
                    <div className="md:col-span-2">
                        <h1 className="text-5xl">Who We Are</h1>
                        <p className="md:w-1/2 py-6">
                            Learn more about our us, who work hard to make Quantum
                            computing available to everyone!
                        </p>
                        <Link to={"/about-us"} className="border rounded-full border-info-blue px-4 py-1 bg-info-blue text-white">Learn Our Story</Link>
                    </div>
                    <div className={"px-5 hidden md:flex col-span-2"}>
                        <img src={about} alt={"about"} className={"lg:w-2/3 object-cover "} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Home;
