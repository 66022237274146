import React from 'react';
import { GoogleLogin } from 'react-google-login';
import LinkedIn from "./LinkedIn";
import { http } from "../../settings";
import * as auth from "../../store/actions/Auth";
import { connect } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import queryString from "querystring";
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'

const SocialAuth = (props) => {

    let navigate = useNavigate()
    let { search } = useLocation()
    let params = queryString.parse(search)


    const finishSocialAuth = (data) => {
        let next_ = "/"
        if (params["?next"]) {
            next_ = params["?next"]
        } else if (params["next"]) {
            next_ = params["next"]
        }
        http.post(`/api/auth/social-auth`, data).then(res => {
            res.data.token = res.data.access_token
            props.finishLogin(res.data)
            navigate(next_)
        }).catch(err => {
            console.log(err)
        })
    }

    const googleResponse = (e) => {
        let data = {
            "socialAuthAccessToken": e.tokenObj.id_token,
            "socialAuthProvider": "google"
        }
        finishSocialAuth(data)
    };
    const linkedinResponse = (code) => {
        let data = {
            "socialAuthAccessToken": code,
            "socialAuthProvider": "linkedin"
        }
        finishSocialAuth(data)
    };
    const googleFail = (e) => {
        console.log(e)
    }
    const responseFacebook = (e) => {
        console.log(e)
        if (e.accessToken.length>0) {
            let data = {
                "socialAuthAccessToken": e.accessToken,
                "socialAuthProvider": "facebook"
            }
            finishSocialAuth(data)
        }
    }
    return (
        <div className='flex justify-center lg:space-x-20 md:space-x-12 space-x-3'>
            <FacebookLogin
                appId="338504984890214"
                fields="name,email,picture"
                callback={responseFacebook}
                render={renderProps => (
                    <button onClick={renderProps.onClick}
                        type="button"
                        className={'px-5 py-1 border rounded-2xl border-info-blue'}>
                        <svg className={"w-8 h-8 inline "} viewBox="0 0 24 24">
                            <path
                                fill="blue"
                                d="M12 2.04C6.5 2.04 2 6.53 2 12.06C2 17.06 5.66 21.21 10.44 21.96V14.96H7.9V12.06H10.44V9.85C10.44 7.34 11.93 5.96 14.22 5.96C15.31 5.96 16.45 6.15 16.45 6.15V8.62H15.19C13.95 8.62 13.56 9.39 13.56 10.18V12.06H16.34L15.89 14.96H13.56V21.96A10 10 0 0 0 22 12.06C22 6.53 17.5 2.04 12 2.04Z" />
                        </svg>
                    </button>
                )}
            />
            <GoogleLogin clientId={'847692069110-8qbtqre0sa0pcps5i7fu5s561lj4timh.apps.googleusercontent.com'}
                buttonText={""}
                onSuccess={googleResponse}
                onFailure={googleFail}
                render={renderProps => (
                    <button onClick={renderProps.onClick}
                        className={'px-5 py-1 border rounded-2xl border-info-blue'}>
                        <svg className={"w-8 h-8 inline "} xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 48 48"
                            width="48px" height="48px">
                            <path fill="#fbc02d"
                                d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12	s5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24s8.955,20,20,20	s20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z" />
                            <path fill="#e53935"
                                d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039	l5.657-5.657C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z" />
                            <path fill="#4caf50"
                                d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36	c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z" />
                            <path fill="#1565c0"
                                d="M43.611,20.083L43.595,20L42,20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571	c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z" />
                        </svg>
                    </button>
                )}
                className={"px-5 py-1 border rounded-2xl border-info-blue"}
            />
            <LinkedIn
                clientId="78vfo6k12j224b"
                redirectUri={`${window.location.origin}/linked-in`}
                onSuccess={(code) => {
                    linkedinResponse(code);
                }}
                onError={(error) => {
                    console.log(error);
                }}
            >
                {({ linkedInLogin }) => (
                    <img
                        onClick={linkedInLogin}
                        src={""}
                        alt="Sign in with Linked In"
                        style={{ maxWidth: '180px', cursor: 'pointer' }}
                    />
                )}
            </LinkedIn>

        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        login_loading: state.auth.register_loading,
        login_error: state.auth.login_error,
    }
};
const mapDispatchToProps = dispatch => {
    return {
        finishLogin: (response) => dispatch(auth.finishLogin(response))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(SocialAuth);
