export const AUTH_START = "AUTH_START";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SIGNUP_START = "SIGNUP_START";
export const SIGNUP_FAIL = "SIGNUP_FAIL";
export const AUTH_FAIL = "AUTH_FAIL";
export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const USER_SUCCESS = "USER_SUCCESS";
export const USER_START = "USER_START";
export const LOGOUT_SET = "LOGOUT_SET";
export const ARTICLES_LIKED_SUCCESS = "ARTICLES_LIKED_SUCCESS";
export const ARTICLES_SAVED_SUCCESS = "ARTICLES_SAVED_SUCCESS";
export const AUTHORS_FOLLOWING_SUCCESS = "AUTHORS_FOLLOWING_SUCCESS";
export const ALERT_MESSAGE = "ALERT_MESSAGE";
export const AUTH_LOADING = "AUTH_LOADING";
export const NOTIFICATIONS_SUCCESS = "NOTIFICATIONS_SUCCESS";
export const UPDATE_EMAIL_SUBSCRIBED = "UPDATE_EMAIL_SUBSCRIBED";