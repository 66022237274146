import React from 'react';

const Unfollow = (props) => {
  return (<div
    className="fixed  inset-0 z-1000 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full"
    id="my-modal"
>
    <div className="relative top-80 z-10 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
        <p className="py-4 text-center">Are you sure you want to unfollow this author?</p>
        <div className="flex justify-between items-center -auto max-w-screen-2xl mx-5">
            <button className="rounded-full px-8 border-info-blue border"
                onClick={() => props.setUnfollow(false)}>cancel</button>
            <button className="rounded-full px-10 bg-info-blue text-white"
                onClick={props.unFollow}>yes</button>
        </div>
    </div>
</div>);
};

export default Unfollow;
