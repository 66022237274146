import React, { useEffect, useState } from "react";
import { BLOCK_BUTTONS } from "medium-draft";
import CustomImageSideButton from "./editor/CustomImageSideButton";
import "medium-draft/lib/index.css";
import mediumDraftExporter from "medium-draft/lib/exporter";
import mediumDraftImporter from "medium-draft/lib/importer";
import { convertToRaw } from "draft-js";
import { Link,useNavigate } from "react-router-dom";
import { Editor, createEditorState } from "medium-draft";
import { http } from "../settings";
import EmbedSideButton from "./editor/EmbedSideButton";
import CodeSideButton from "./editor/CodeSideButton";
import { connect } from "react-redux";


const MediumEditor = (props) => {
  let navigate = useNavigate()

  const sideButtons = [
    {
      title: "Image",
      component: CustomImageSideButton,
    },
    // {
    //   title: "Video",
    //   component: EmbedSideButton,
    // },
    {
      title: "Code",
      component: CodeSideButton,
    },
  ];
  useEffect(() => {
    const editorState = createEditorState(
      convertToRaw(mediumDraftImporter(props.article.content ? props.article.content : " "))
    );
    setEditoState(editorState);
    console.log(props.article);
    setTitle(props.article.title ? props.article.title : "");
    setEditTitle(props.article.title ? false : true);
    setContent(props.article.content);
  }, [props.id]);

  const [editorState, setEditoState] = useState(createEditorState(" "));
  const [success_post, setSuccessPost] = useState(false);
  const [success_message, setSuccessMessage] = useState(true);
  const [success_link, setSuccessLink] = useState(true);
  const [title, setTitle] = useState("SOME TITLE WILL COME HERE");
  const [content, setContent] = useState("");
  const [edit_title, setEditTitle] = useState(false);
  const [is_published, setIsPublished] = useState(false);
  const onChangeValue = (newEditorState) => {
    setEditoState(newEditorState);
    let renderedHTML = mediumDraftExporter(editorState.getCurrentContent());
    setContent(renderedHTML);
  };
  const submitArticle = (is_published_) => {
    var elem = document.createElement("div");
    elem.style.display = "none";
    document.body.appendChild(elem);
    elem.innerHTML = content;
    let image = elem.querySelector("img");
    if (image) {
      image = image.src;
    } else {
      image = "";
    }
    let data = {
      title: title,
      source: "",
      short_intro: "",
      image: image,
      read_time: "",
      is_published: is_published_,
      content: content,
    };
    if (props.id) {
      http.put(`/api/articles/article/${props.id}`, data).then((res) => {
        setSuccessPost(true);
        if (is_published_) {
          setSuccessMessage(`Thanks for writing and submitting the article for review!
        Check for the article status
        Happy Writing!`)
          setSuccessLink("/dashboard")

        } else {
          setSuccessMessage("your article has been saved to drafts")
          setSuccessLink("/dashboard/drafts")

        }
      });
    } else {
      http.post(`/api/articles/add-article/`, data).then((res) => {
        setSuccessPost(true);
        if (is_published_) {
          setSuccessMessage(`Thanks for writing and submitting the article for review!
        Check for the article status
        Happy Writing!`)
          setSuccessLink("/dashboard")

        } else {
          setSuccessMessage("yout article has been saved to drafts")
          setSuccessLink("/dashboard/drafts")

        }

      });
    }
  };
  const refsEditor = React.createRef();
  const blockButtons = [].concat(BLOCK_BUTTONS);
  return (
    <div className="w-full flex-col relative">
      <Link to={'/'} className="absolute right-0">
        <svg className={'h-10 w-10 inline '} viewBox="0 0 24 24">
          <path fill="currentColor" d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" />
        </svg>
      </Link>
      {edit_title ? (
        <div
          className="fixed  inset-x-0 overflow-y-auto  w-full h-screen"
          id="my-modal" 
        >
          <div className="flex m-auto  w-full  bg-white">
            <form
              onSubmit={(e) => {
                e.preventDefault();
                setEditTitle(false);
              }}
              className={
                "w-full flex flex-col justify-center m-auto max-w-6xl mx-5 lg:mx-auto "
              }
            >
              <input
                type="text"
                value={title}
                autoFocus={true}
                onChange={(e) => setTitle(e.target.value)}
                onFocus={function (e) {
                  var val = e.target.value;
                  e.target.value = "";
                  e.target.value = val;
                }}
                className={`border-none bg-transparent active:outline-none 
                    p-10 text-3xl md:text-5xl font-bold outline-none focus:outline-none focus:shadow-none
                     w-full focus:ring-0`}
                placeholder={"Article Title"}
              />
            </form>
            <div className="flex absolute top-0 right-0 p-5 space-x-2">
              <button
                className={""}
                onClick={() => setEditTitle(false)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className={"md:h-10 md:w-10 inline h-6 w-6"}
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M5 13l4 4L19 7"
                  />
                </svg>
              </button>
              <Link to={'/'} className="right-0 px-2">
                <svg className={'md:h-10 md:w-10 inline h-6 w-6 '} viewBox="0 0 24 24">
                  <path fill="currentColor" d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" />
                </svg>
              </Link>

            </div>
          </div>
        </div>
      ) : (

        <div className="w-full">
          {
            success_post ? <div
              className="fixed  inset-0 z-1000 bg-gray-600 bg-opacity-90 overflow-y-auto h-full w-full"
              id="my-modal" 
            >
              <div className="relative top-80 z-999 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
                <p className="py-4 text-center">{props.success_message?props.success_message:success_message} </p>
                <div className="flex justify-center items-center m-auto max-w-screen-2xl mx-5">
                  <button className="rounded-full px-10 border-info-blue border items-center bg-info-blue text-white"
                    onClick={() => {
                      setSuccessPost(false)
                      navigate(success_link)
                    }
                    }>Browse</button>

                </div>
              </div>
            </div> : ""
          }
          <h1 className="text-5xl  font-bold block pb-2">
            {title}
            <button className="pl-4" onClick={() => setEditTitle(true)}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                />
              </svg>
            </button>
          </h1>
          <Editor
            ref={refsEditor}
            editorState={editorState}
            onChange={onChangeValue}
            sideButtons={sideButtons}
            blockButtons={blockButtons}
          />
          <div className="flex justify-between items-center">
            <span></span>
            <div className="flex justify-between items-center space-x-4">
              <button
                className="bg-gray-400 px-6 py-1 rounded"
                onClick={() => {
                  setIsPublished(false);
                  submitArticle(false);
                }}
              >
                save as draft
              </button>
              <button
                className="bg-info-blue px-6 py-1 rounded-full text-white"
                onClick={() => {
                  setIsPublished(true);
                  submitArticle(true);
                }}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(MediumEditor);