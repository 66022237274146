import React from 'react'
import { connect } from 'react-redux'
import whiteimage from '../../../assets/white.jpeg'

const SinglePublication = ({ publication }) => {
    return (
        publication ?
            <a href={publication.link} target="_blank" rel="noreferrer" className={"my-2 mx-2 md:mx-5  rounded-lg flex flex-col shadow-lg  transition transform ease-in-out delay-150 motion-reduce:transition-none motion-reduce:transform-none"}>
                <img src={publication.image?publication.image:whiteimage} className={'rounded-md h-48 w-full object-cover hover:bg-blue-700 hover:opacity-30'} alt={""} />
                <div className={"flex py-1 flex-col px-2 pb-4"}>

                    <span className={"text-black font-semibold text-sm hover:text-blue-600"}>
                        {publication.title}
                    </span>
                    <span className={"text-gray-400 text-xs pt-2"}>
                        <span className='hover:text-blue-600 uppercase'>
                            {publication.conference_name}
                        </span>
                        <span className='font-bold px-1'>&bull;</span>
                        <span className={"uppercase"}>
                            {new Date(publication.date_published).toDateString()}
                        </span>
                    </span>
                </div>
            </a>
            :
            ""
    )
}

const mapStateToProps = () => ({

})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(SinglePublication)
