import React, { useState } from 'react';
import LogoutConfirm from '../auth/LogoutConfirm';
import Footer from '../sub/Footer';
import Header from "../sub/Header";
import Search from "../sub/Search";
import { connect } from 'react-redux';
import MessageAlert from './MessageAlert';


const WriteAricle = ({ children }) => {
    const [searching, updateSearching] = useState(false)
    return (

        <>
            <div className={!searching ? 'hidden' : 'relative h-screen'}>
                <Search updateSearching={() => updateSearching(!searching)} />
            </div>
            <div >
                <div className={searching ? 'hidden' : 'relative h-screen'}>
                    <MessageAlert/>
                    <LogoutConfirm />
                    {children}
                </div>
            </div>
        </>
    );
};

const mapStateToProps = (state) => {

};

const mapDispatchToProps = dispatch => {
    
};


export default connect(mapStateToProps, mapDispatchToProps)(WriteAricle);