import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import ArticleList from "./sub/ArticleList";
import { http } from "../settings";
import { Link } from "react-router-dom";
import RemoveFromSaved from "./sub/article/RemoveFromSaved";
import InfiniteScroll from "react-infinite-scroll-component";
import * as auth from "../store/actions/Auth";
import * as general from "../store/actions/General";

const SavedArticles = (props) => {
  const [articles, setArticle] = useState([]);
  const [pop_remove_saved, popupRemoveFromSaved] = useState(false);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(12);
  const [total, setTotal] = useState(1);
  const [remove_article, setRemoveArticle] = useState(null);

  const [fetching, setFetching] = useState(false);
  const [has_more, setHasMore] = useState(false);

  const fetchData = (initial) => {
    if (!fetching) {
      const url = `/api/articles/saved?page=${
        initial ? initial : page + 1
      }&size=${size}`;
      setFetching(true);
      http.get(url).then((res) => {
        setArticle([...articles, ...res.data.items]);
        setPage(res.data.page);
        setSize(res.data.size);
        setTotal(res.data.total);
        setHasMore(res.data.page * res.data.size < res.data.total);
        setFetching(false);
      });
    }
  };
  const isLiked = (id) => {
    return props.articles_liked.find((sa) => sa.article_id === id);
  };
  const saveArticle = (id) => {
    setRemoveArticle(id);
    popupRemoveFromSaved(false);
    http.post(`/api/articles/saved?article_id=${id}`).then((res) => {
      let liked_a = [...props.articles_saved];
      if (res.data.message.indexOf("unsaved") > 0) {
        let c_article = isLiked(id);
        let ca_ind = liked_a.indexOf(c_article);
        liked_a.splice(ca_ind, 1);
        props.updateSavedArticles(liked_a);
        props.setAlert({ show: true, error: false, message: res.data.message });
      } else {
        liked_a.push({ article_id: parseInt(id) });
        props.updateSavedArticles(liked_a);
        props.setAlert({ show: true, error: false, message: res.data.message });
      }
    });
  };
  useEffect(() => {
    fetchData(1);
  }, []);

  return (
    <div>
      <div
        className={
          "bg-articlesBanner py-20 bg-no-repeat bg-center bg-cover flex flex-col justify-center items-center "
        }
      >
        <h3 className={"text-white font-bold text-3xl"}>Saved Articles</h3>
        <p className="text-white space-x-3">
          <Link to="/">Home</Link>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6 inline"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M9 5l7 7-7 7"
            />
          </svg>
          <Link to={""}>Saved</Link>
        </p>
      </div>
      {pop_remove_saved ? (
        <RemoveFromSaved
          remove_article={remove_article}
          saveArticle={saveArticle}
          popupRemoveFromSaved={popupRemoveFromSaved}
        />
      ) : (
        ""
      )}
      <div className="m-auto max-w-screen-2xl mx-5 lg:mx-auto ">
        <InfiniteScroll
          key={has_more}
          className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 mx-5 py-10"
          dataLength={articles.length} //This is important field to render the next data
          next={fetchData}
          hasMore={has_more}
          loader={
            <h4
              className={"lg:col-span-3 col-span-2 md:col-spa-3 xl:col-span-4"}
            >
              Loading...
            </h4>
          }
          endMessage={
            <p
              className={
                "lg:col-span-3 col-span-2 md:col-spa-3 xl:col-span-4 text-center"
              }
            >
              <b>You have seen all</b>
            </p>
          }
          refreshFunction={fetchData}
        >
          {articles.map((article) => (
            <ArticleList
              key={article.id}
              saveArticle={saveArticle}
              article={article.article}
              popupRemoveFromSaved={popupRemoveFromSaved}
              setRemoveArticle={setRemoveArticle}
            />
          ))}
        </InfiniteScroll>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    articles_saved: state.auth.articles_saved,
    articles_liked: state.auth.articles_liked,
    authors_following: state.auth.authors_following,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateSavedArticles: (liked_articles) =>
      dispatch(auth.articlesSaved(liked_articles)),
    setAlert: (data) => dispatch(general.setAlert(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SavedArticles);
